//判断网页是什么打开
const isWeixin =  () => {
    let wx = navigator.userAgent.toLowerCase()
    //this.text = wx.match(/micromessenger/i)
    
    if (wx.match(/micromessenger/i) == "micromessenger") {
    //this.text = wx
    return true
    } else {
    return false
    }
}
export default isWeixin