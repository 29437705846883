import Vue from "vue";

const mutations={
    //更新payType
    updatePayType(state,type){
        state.payType = type;
    },
    //初始化大底或更新过滤后的大底
    //根据条件更新大底
    alertFourfixed(state,value){
        state.Cfourfixed=JSON.parse(JSON.stringify(value))
    },
    alertFourshow(state,value){
        state.Cfourshow=JSON.parse(JSON.stringify(value))
    },
    alertThreefixed(state,value){
        state.Cthreefixed=JSON.parse(JSON.stringify(value))
    },
    alertThreeshow(state,value){
        state.Cthreeshow=JSON.parse(JSON.stringify(value))
    },
    alertTwofixed(state,value){
        state.Ctwofixed=JSON.parse(JSON.stringify(value))
    },
    alertTwoshow(state,value){
        state.Ctwoshow=JSON.parse(JSON.stringify(value))
    },
    //条件列表的更新
    alertcondFourfixed(state,value){
        state.condFourDec=JSON.parse(JSON.stringify(value))
        // state.condFourDec={...value}
    },
    alertcondFourshow(state,value){
        state.condFourShow=JSON.parse(JSON.stringify(value))
    },
    alertcondThreefixed(state,value){
        state.condThreeDec=JSON.parse(JSON.stringify(value))
    },
    alertcondThreeshow(state,value){
        state.condThreeShow=JSON.parse(JSON.stringify(value))
    },
    alertcondTwofixed(state,value){
        state.condTwoDec=JSON.parse(JSON.stringify(value))
    },
    alertcondTwoshow(state,value){
        state.condTwoShow=JSON.parse(JSON.stringify(value))
    },
    alertVersion(state,value){
        if(state.version===undefined){
            state["version"] = value
        }else{
            state.version=value
        }
        
    },
    clearAllData(state){
        delete state.payType
        delete state.fourfixed
        delete state.threefixed
        delete state.twofixed
        delete state.fourshow
        delete state.threeshow
        delete state.twoshow
        delete state.Cfourfixed
        delete state.Cfourshow
        delete state.Cthreefixed
        delete state.Cthreeshow
        delete state.Ctwofixed
        delete state.Ctwoshow
        delete state.menuFourDec
        delete state.menuFourShow
        delete state.menuThreeDec
        delete state.menuThreeShow
        delete state.menuTwoDec
        delete state.menuTwoShow
        delete state.condFourDec
        delete state.condFourShow
        delete state.condThreeDec
        delete state.condThreeShow
        delete state.condTwoDec
        delete state.condTwoShow
        delete state.initcondFourDec
        delete state.initcondFourShow
        delete state.initcondThreeDec
        delete state.initcondThreeShow
        delete state.initcondTwoDec
        delete state.initcondTwoShow
    }
}
export default mutations
//或
// export default{

// }