<template>
  <div id="app" v-if="appload">
    <!-- <router-view /> -->
    <keep-alive >
        <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>

  </div>
</template>
<script>
// import shareWx from 'src/shareWx.js'
// import shareWx from "./shareWx.js";
// import shareWx from "/src/api/shareWx.js";
// import wx from "weixin-js-sdk";
// import API from '@/api';
// 微信公众号的 appId是 wx5b10e76f73874daa
// const APPID = 'wx5b10e76f73874daa'
export default {
  data() {
    return {
      appload: false
    }
  },
  // updated() {
  //  shareWx.getJSSDK();
  // },
  // mounted() {
  //   shareWx.getJSSDK();
  // },
  async created() {
    // console.log('刷新时调用了')
   await this.tokenStatus();

      // let res= await this.$HTTP.get2(this, this.$API['API_USER_TOKEN_STATUS'] , {}, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true);
      // console.log('get2返回的值',res)
    // if (this.$store.state.common.loginInfo.refresh_token) {
    //   await this.$store.dispatch('common/resetToken')
    // }
    this.appload = true;
 
    //刷新回到首页
    // if (this.$route.path !== '/') {
    //   this.$router.replace('/')
    // }    
    //分享
    // this.wxShare();
  },
  methods: {  
    async tokenStatus(){
      let that =this;
      that.$axios({
            url: that.$API['API_USER_TOKEN_STATUS'],
            method: 'get',
            params: '',
            data:'',
            headers: { access_token: that.$store.state.common.loginInfo.access_token }
          }).then(res => {
            // console.log("get请求返回的信息",res)
            // if (res.status === 200 && (res.data.statusCode === 200 || res.data.statusCode === 309)) {
            // console.log('accessToken还有效，无需更新');
            // }
          })
    },
    async reflushToken(){
      const res = await this.$axios({
            url: this.$API.API_TOKEN_REFRESH,
            method: "get",
            params: { refresh_token: this.$store.state.common.loginInfo.refresh_token }
          })
          this.$store.commit('updateState', [
            { name: ['loginInfo', 'access_token'], value: res.data.access_token },
            { name: ['loginInfo', 'refresh_token'], value: res.data.refresh_token }
          ])
    } 
    //分享的方法
    // wxShare () {
    //   var url = encodeURIComponent(window.location.href.split("#")[0]);
    //   this.$http
    //     .post(
    //       API.API_WX_JSAPI_TICKET,
    //       { 
    //         targetUrl: url,
		// 		    wxAppId: APPID
    //       }
    //     )
    //     .then((res) => {
    //       let data = res.data;
    //       wx.config({
    //         debug: false,
    //         appId: data.appId,
    //         timestamp: data.timestamp,
    //         nonceStr: data.nonceStr,
    //         signature: data.signature,
    //         jsApiList: [
    //           "onMenuShareTimeline",
    //           "onMenuShareAppMessage",
    //           "onMenuShareQQ",
    //           "onMenuShareWeibo",
    //           "onMenuShareQZone",
    //         ],
    //       });
    //     });
    //   wx.ready(() => {
    //     var shareData = {
    //       title: "标题...",
    //       desc: "描述...",
    //       link: "分享出去的链接地址（入口）",
    //       imgUrl:
    //         "分享出去的icon图标地址",  
    //       success: () => { },
    //     };
    //     wx.onMenuShareTimeline(shareData);
    //     wx.onMenuShareAppMessage(shareData);
    //     wx.onMenuShareQQ(shareData);
    //     wx.onMenuShareWeibo(shareData);
    //     wx.onMenuShareQZone(shareData);
    //   });
    // },
  },

 


};
</script>
<style>
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
.header_common_box {
  position: sticky;
  top: 0;
  z-index: 99;
}
</style>
