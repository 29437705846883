const mutations = {
    // 更新state
    updateState(state, list) {
        for (let params of list) {
            if (params.name.length == 1) {
                state[params.name[0]] = params.value
            } else if (params.name.length == 2) {
                state[params.name[0]][params.name[1]] = params.value
            } else if (params.name.length == 3) {
                state[params.name[0]][params.name[1]][params.name[2]] = params.value
            }
        }
    },
    //更新forecast的active
    updateForecastActive(state,value){
        state.forecast_active=value
    },
    updaterangIndex_active(state,value){
        state.rangIndex_active=value
    },
    updaterangPeriod(state,value){
        state.rangPeriod=value
    },
   
}

export default mutations