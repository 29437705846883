import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import friendsCircle from './modules/friendsCircle'
import payWay from './modules/payWay'
import createPersistedState from "vuex-persistedstate"
import prize from './modules/prize'

//挂载Vuex
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    common,
    friendsCircle,
    prize,
    payWay
  },
  plugins: [createPersistedState()]
})