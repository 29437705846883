import {map} from "core-js/internals/array-iteration";

const state = () => ({
    //玩法类型，定义在state中避免筛选页面刷新后无参数不显示问题 1.四定 2.四现 3.三定 4.三现 5.二定 6.二现
    payType: 0,
    //四定
    menuFourDec:[
        {
            name: '热门',
            children:[
                {
                    name: '定位置',
                    mes: ['各个位置分别输入号码，然后生成可能的组合'],
                    btn:[
                        {
                            name: '批量选码'
                        },
                        {
                            name: '自由选码'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号，特别约定0与9相邻'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '减式',
                    mes: ['自定义减法等式。例如A+B-C=2'],
                    btn:[
                        {
                            name: '合值'
                        }
                    ]
                },
            ]
        },
        {
            name: '按位置选码',
            children: [
                {
                    name: '定位置',
                    mes: ['各个位置分别输入号码，然后生成可能的组合'],
                    btn: [
                        {
                            name: '批量选码'
                        },
                        {
                            name: '自由选码'
                        }
                    ]
                },
                {
                    name: '头尾有数',
                    mes: ['头尾两个位置中，至少有一个位置出现所选号码'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '中肚有数',
                    mes: ['百十两个位置中，至少有一个位置包含所选号码'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '有数',
                    mes: ['所选定的位置中，其中一个位置出现所选定的号码'],
                    btn: [
                        {
                            name: '2个位置'
                        },
                        {
                            name: '3个位置'
                        },
                        {
                            name: '4个位置'
                        }
                    ]
                },
                {
                    name: '凹凸升降',
                    mes: ['凹下型：中间1个数字均小于头尾','凸起型：中间1个数字均大于头尾','上升型：从左到右依次变大','下降型：从左到右依次变小','平行型：三个数字完全相等'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '合值筛选',
            children: [
                {
                    name: '两数合',
                    mes: ['两个数字相加所得之和的个位称为两数合'],
                    btn: [
                        {
                            name: '定位置'
                        },
                        {
                            name: '不定位置'
                        }
                    ]
                },
                {
                    name: '三数合',
                    mes: ['三个数字相加所得之和的个位称为三数合'],
                    btn: [
                        {
                            name: '定位置'
                        },
                        {
                            name: '不定位置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置',
                        }
                    ]
                }
            ]
        },
        {
            name: '号码分类',
            children: [
                {
                    name: '单双（奇偶）',
                    mes: ['将0-9分为两类：单 双','单：13579，双：02468'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大小',
                    mes: ['将0-9分为两类：大 小','大：56789，小：01234'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '质合',
                    mes: ['将0-9分为两类：质 合','质：12357，合：04689'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大中小',
                    mes: ['将0-9分为三类：大 中 小','大：789，中：3456，小：012'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '012路',
                    mes: ['将0-9分别除以3，根据余数的值，划分为三类：0路，1路，2路','0路：0369，1路：147，2路：258'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '对数',
                    mes: ['将0-9共10个数字围成环状，按照相对位置分为五类，0对5，1对6，2对7，3对8，4对9'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '不定位置选号',
            children: [
                {
                    name: '配号码',
                    mes: ['两组号码相互搭配，配出所有可能的组合'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '必出码（铁率）',
                    mes: ['所选的号码中，至少出现1个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数字多次出现，称为重数'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号。特别约定0与9相邻'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '入数个数',
                    mes: ['不相同数字数量之和。例如1258入数4个，0103入数3个，0006入数2个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '极值',
                    mes: ['最大或者最小的一个数称为极值'],
                    btn: [
                        {
                            name: '最大值'
                        },
                        {
                            name: '最小值'
                        }
                    ]
                },
                {
                    name: '跨度',
                    mes: ['最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '加减乘除运算',
            children: [
                {
                    name: '和值',
                    mes: ['所有数字相加所得之和'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之和的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值+跨度',
                    mes: ['合值与跨度之和','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '减式',
                    mes: ['自定义减法等式。例如A+B-C=2'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '合值-跨度',
                    mes: ['合值与跨度之差','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '两数乘积',
                    mes: ['所选两个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '三数乘积',
                    mes: ['所选三个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '四数乘积',
                    mes: ['所选四个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '位积',
                    mes: ['位置号码与对应位置序号的乘积之和','A*1 + B*2 + C*3 + D*4'],
                    btn: [
                        {
                            name: '和值'
                        },
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '反位积',
                    mes: ['位置号码与对应位置序号的乘积之和','A*4 + B*3 + C*2 + D*1'],
                    btn: [
                        {
                            name: '和值'
                        },
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '余数和',
                    mes: ['ABCD分别除以一个数，取余数之和'],
                    btn: [
                        {
                            name: '除以3'
                        }
                    ]
                }
            ]
        },
        {
            name: '其他',
            children: [
                {
                    name: '开奖历史',
                    mes: ['允许根据开奖历史来筛选号码'],
                    btn: [
                        {
                            name: '七星彩'
                        },
                        {
                            name: '排列五'
                        }
                    ]
                },
                {
                    name: '全转（全倒）',
                    mes: ['输入一串数字，将其转化为字定','可以将字现转化为字定'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: 'AC值',
                    mes: ['即号码复杂度','号码两两相减（大减小），去掉重复的差值，','最后剩余差值得个数，就是AC值'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        }
    ],
    //四现
    menuFourShow:[
        {
            name: '热门',
            children:[
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号，特别约定0与9相邻'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数多次出现，称为重数'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
            ]
        },
        {
            name: '合值筛选',
            children: [
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置',
                        }
                    ]
                }
            ]
        },
        {
            name: '号码分类',
            children: [
                {
                    name: '单双（奇偶）',
                    mes: ['将0-9分为两类：单 双','单：13579，双：02468'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大小',
                    mes: ['将0-9分为两类：大 小','大：56789，小：01234'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '质合',
                    mes: ['将0-9分为两类：质 合','质：12357，合：04689'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大中小',
                    mes: ['将0-9分为三类：大 中 小','大：789，中：3456，小：012'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '012路',
                    mes: ['将0-9分别除以3，根据余数的值，划分为三类：0路，1路，2路','0路：0369，1路：147，2路：258'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '对数',
                    mes: ['将0-9共10个数字围成环状，按照相对位置分为五类，0对5，1对6，2对7，3对8，4对9'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '不定位置选号',
            children: [
                {
                    name: '配号码',
                    mes: ['两组号码相互搭配，配出所有可能的组合'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '必出码（铁率）',
                    mes: ['所选的号码中，至少出现1个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数字多次出现，称为重数'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号。特别约定0与9相邻'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '入数个数',
                    mes: ['不相同数字数量之和。例如1258入数4个，0103入数3个，0006入数2个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '极值',
                    mes: ['最大或者最小的一个数称为极值'],
                    btn: [
                        {
                            name: '最大值'
                        },
                        {
                            name: '最小值'
                        }
                    ]
                },
                {
                    name: '跨度',
                    mes: ['最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '加减乘除运算',
            children: [
                {
                    name: '和值',
                    mes: ['所有数字相加所得之和'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之和的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值+跨度',
                    mes: ['合值与跨度之和','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值-跨度',
                    mes: ['合值与跨度之差','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '四数乘积',
                    mes: ['所选四个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '余数和',
                    mes: ['ABCD分别除以一个数，取余数之和'],
                    btn: [
                        {
                            name: '除以3'
                        }
                    ]
                }
            ]
        },
        {
            name: '其他',
            children: [
                {
                    name: 'AC值',
                    mes: ['即号码复杂度','号码两两相减（大减小），去掉重复的差值，','最后剩余差值得个数，就是AC值'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        }
    ],
    //三定
    menuThreeDec:[
        {
            name: '热门',
            children:[
                {
                    name: '定位置',
                    mes: ['各个位置分别输入号码，然后生成可能的组合'],
                    btn:[
                        {
                            name: '乘号位置'
                        },
                        {
                            name: '批量选码'
                        },
                        {
                            name: '自由选码'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号，特别约定0与9相邻'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
            ]
        },
        {
            name: '按位置选码',
            children: [
                {
                    name: '定位置',
                    mes: ['各个位置分别输入号码，然后生成可能的组合'],
                    btn: [
                        {
                            name: '乘号位置'
                        },
                        {
                            name: '批量选码'
                        },
                        {
                            name: '自由选码'
                        }
                    ]
                },
                {
                    name: '头尾有数',
                    mes: ['头尾两个位置中，至少有一个位置出现所选号码'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '中肚有数',
                    mes: ['百十两个位置中，至少有一个位置包含所选号码'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '有数',
                    mes: ['所选定的位置中，其中一个位置出现所选定的号码'],
                    btn: [
                        {
                            name: '2个位置'
                        },
                        {
                            name: '3个位置'
                        }
                    ]
                },
                {
                    name: '凹凸升降',
                    mes: ['凹下型：中间1个数字均小于头尾','凸起型：中间1个数字均大于头尾','上升型：从左到右依次变大','下降型：从左到右依次变小','平行型：三个数字完全相等'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '合值筛选',
            children: [
                {
                    name: '两数合',
                    mes: ['两个数字相加所得之和的个位称为两数合'],
                    btn: [
                        {
                            name: '定位置'
                        },
                        {
                            name: '不定位置'
                        }
                    ]
                },
                {
                    name: '三数合',
                    mes: ['三个数字相加所得之和的个位称为三数合'],
                    btn: [
                        {
                            name: '定位置'
                        },
                        {
                            name: '不定位置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置',
                        }
                    ]
                }
            ]
        },
        {
            name: '号码分类',
            children: [
                {
                    name: '单双（奇偶）',
                    mes: ['将0-9分为两类：单 双','单：13579，双：02468'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大小',
                    mes: ['将0-9分为两类：大 小','大：56789，小：01234'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '质合',
                    mes: ['将0-9分为两类：质 合','质：12357，合：04689'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大中小',
                    mes: ['将0-9分为三类：大 中 小','大：789，中：3456，小：012'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '012路',
                    mes: ['将0-9分别除以3，根据余数的值，划分为三类：0路，1路，2路','0路：0369，1路：147，2路：258'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '对数',
                    mes: ['将0-9共10个数字围成环状，按照相对位置分为五类，0对5，1对6，2对7，3对8，4对9'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '不定位置选号',
            children: [
                {
                    name: '配号码',
                    mes: ['三组号码相互搭配，配出所有可能的组合'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '必出码（铁率）',
                    mes: ['所选的号码中，至少出现1个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数字多次出现，称为重数'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号。特别约定0与9相邻'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '入数个数',
                    mes: ['不相同数字数量之和。例如1258入数4个，0103入数3个，0006入数2个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '极值',
                    mes: ['最大或者最小的一个数称为极值'],
                    btn: [
                        {
                            name: '最大值'
                        },
                        {
                            name: '最小值'
                        }
                    ]
                },
                {
                    name: '跨度',
                    mes: ['最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '加减乘除运算',
            children: [
                {
                    name: '和值',
                    mes: ['所有数字相加所得之和'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之和的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值+跨度',
                    mes: ['合值与跨度之和','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值-跨度',
                    mes: ['合值与跨度之差','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '两数乘积',
                    mes: ['所选两个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '三数乘积',
                    mes: ['所选三个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '位积',
                    mes: ['位置号码与对应位置序号的乘积之和','A*1 + B*2 + C*3 + D*4'],
                    btn: [
                        {
                            name: '和值'
                        },
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '反位积',
                    mes: ['位置号码与对应位置序号的乘积之和','A*4 + B*3 + C*2 + D*1'],
                    btn: [
                        {
                            name: '和值'
                        },
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '余数和',
                    mes: ['ABCD分别除以一个数，取余数之和'],
                    btn: [
                        {
                            name: '除以3'
                        }
                    ]
                }
            ]
        },
        {
            name: '其他',
            children: [
                {
                    name: '开奖历史',
                    mes: ['允许根据开奖历史来筛选号码'],
                    btn: [
                        {
                            name: '七星彩'
                        },
                        {
                            name: '排列五'
                        }
                    ]
                },
                {
                    name: '全转（全倒）',
                    mes: ['输入一串数字，将其转化为字定','可以将字现转化为字定'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: 'AC值',
                    mes: ['即号码复杂度','号码两两相减（大减小），去掉重复的差值，','最后剩余差值得个数，就是AC值'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        }
    ],
    //三现
    menuThreeShow:[
        {
            name: '热门',
            children:[
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号，特别约定0与9相邻'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数多次出现，称为重数'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
            ]
        },
        {
            name: '合值筛选',
            children: [
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置',
                        }
                    ]
                }
            ]
        },
        {
            name: '号码分类',
            children: [
                {
                    name: '单双（奇偶）',
                    mes: ['将0-9分为两类：单 双','单：13579，双：02468'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大小',
                    mes: ['将0-9分为两类：大 小','大：56789，小：01234'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '质合',
                    mes: ['将0-9分为两类：质 合','质：12357，合：04689'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大中小',
                    mes: ['将0-9分为三类：大 中 小','大：789，中：3456，小：012'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '012路',
                    mes: ['将0-9分别除以3，根据余数的值，划分为三类：0路，1路，2路','0路：0369，1路：147，2路：258'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '对数',
                    mes: ['将0-9共10个数字围成环状，按照相对位置分为五类，0对5，1对6，2对7，3对8，4对9'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '不定位置选号',
            children: [
                {
                    name: '配号码',
                    mes: ['两组号码相互搭配，配出所有可能的组合'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '必出码（铁率）',
                    mes: ['所选的号码中，至少出现1个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数字多次出现，称为重数'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号。特别约定0与9相邻'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '入数个数',
                    mes: ['不相同数字数量之和。例如1258入数4个，0103入数3个，0006入数2个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '极值',
                    mes: ['最大或者最小的一个数称为极值'],
                    btn: [
                        {
                            name: '最大值'
                        },
                        {
                            name: '最小值'
                        }
                    ]
                },
                {
                    name: '跨度',
                    mes: ['最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '加减乘除运算',
            children: [
                {
                    name: '和值',
                    mes: ['所有数字相加所得之和'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之和的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值+跨度',
                    mes: ['合值与跨度之和','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值-跨度',
                    mes: ['合值与跨度之差','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '三数乘积',
                    mes: ['所选三个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '余数和',
                    mes: ['ABCD分别除以一个数，取余数之和'],
                    btn: [
                        {
                            name: '除以3'
                        }
                    ]
                }
            ]
        },
        {
            name: '其他',
            children: [
                {
                    name: 'AC值',
                    mes: ['即号码复杂度','号码两两相减（大减小），去掉重复的差值，','最后剩余差值得个数，就是AC值'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        }
    ],
    //二定
    menuTwoDec:[
        {
            name: '热门',
            children:[
                {
                    name: '定位置',
                    mes: ['各个位置分别输入号码，然后生成可能的组合'],
                    btn:[
                        {
                            name: '乘号位置'
                        },
                        {
                            name: '批量选码'
                        },
                        {
                            name: '自由选码'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号，特别约定0与9相邻'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
            ]
        },
        {
            name: '按位置选码',
            children: [
                {
                    name: '定位置',
                    mes: ['各个位置分别输入号码，然后生成可能的组合'],
                    btn: [
                        {
                            name: '乘号位置'
                        },
                        {
                            name: '批量选码'
                        },
                        {
                            name: '自由选码'
                        }
                    ]
                },
                {
                    name: '头尾有数',
                    mes: ['头尾两个位置中，至少有一个位置出现所选号码'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '中肚有数',
                    mes: ['百十两个位置中，至少有一个位置包含所选号码'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '有数',
                    mes: ['所选定的位置中，其中一个位置出现所选定的号码'],
                    btn: [
                        {
                            name: '2个位置'
                        }
                    ]
                },
            ]
        },
        {
            name: '合值筛选',
            children: [
                {
                    name: '两数合',
                    mes: ['两个数字相加所得之和的个位称为两数合'],
                    btn: [
                        {
                            name: '定位置'
                        },
                        {
                            name: '不定位置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置',
                        }
                    ]
                }
            ]
        },
        {
            name: '号码分类',
            children: [
                {
                    name: '单双（奇偶）',
                    mes: ['将0-9分为两类：单 双','单：13579，双：02468'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大小',
                    mes: ['将0-9分为两类：大 小','大：56789，小：01234'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '质合',
                    mes: ['将0-9分为两类：质 合','质：12357，合：04689'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大中小',
                    mes: ['将0-9分为三类：大 中 小','大：789，中：3456，小：012'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                    ]
                },
                {
                    name: '012路',
                    mes: ['将0-9分别除以3，根据余数的值，划分为三类：0路，1路，2路','0路：0369，1路：147，2路：258'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                    ]
                },
                {
                    name: '对数',
                    mes: ['将0-9共10个数字围成环状，按照相对位置分为五类，0对5，1对6，2对7，3对8，4对9'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '不定位置选号',
            children: [
                {
                    name: '配号码',
                    mes: ['两组号码相互搭配，配出所有可能的组合'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '必出码（铁率）',
                    mes: ['所选的号码中，至少出现1个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数字多次出现，称为重数'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号。特别约定0与9相邻'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '入数个数',
                    mes: ['不相同数字数量之和。例如1258入数4个，0103入数3个，0006入数2个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '极值',
                    mes: ['最大或者最小的一个数称为极值'],
                    btn: [
                        {
                            name: '最大值'
                        },
                        {
                            name: '最小值'
                        }
                    ]
                },
                {
                    name: '跨度',
                    mes: ['最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '加减乘除运算',
            children: [
                {
                    name: '和值',
                    mes: ['所有数字相加所得之和'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之和的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值+跨度',
                    mes: ['合值与跨度之和','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值-跨度',
                    mes: ['合值与跨度之差','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '两数乘积',
                    mes: ['所选两个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '位积',
                    mes: ['位置号码与对应位置序号的乘积之和','A*1 + B*2 + C*3 + D*4'],
                    btn: [
                        {
                            name: '和值'
                        },
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '反位积',
                    mes: ['位置号码与对应位置序号的乘积之和','A*4 + B*3 + C*2 + D*1'],
                    btn: [
                        {
                            name: '和值'
                        },
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '余数和',
                    mes: ['ABCD分别除以一个数，取余数之和'],
                    btn: [
                        {
                            name: '除以3'
                        }
                    ]
                }
            ]
        },
        {
            name: '其他',
            children: [
                {
                    name: '开奖历史',
                    mes: ['允许根据开奖历史来筛选号码'],
                    btn: [
                        {
                            name: '七星彩'
                        },
                        {
                            name: '排列五'
                        }
                    ]
                },
                {
                    name: '全转（全倒）',
                    mes: ['输入一串数字，将其转化为字定','可以将字现转化为字定'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        }
    ],
    //二现
    menuTwoShow:[
        {
            name: '热门',
            children:[
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号，特别约定0与9相邻'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数多次出现，称为重数'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
            ]
        },
        {
            name: '合值筛选',
            children: [
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置',
                        }
                    ]
                }
            ]
        },
        {
            name: '号码分类',
            children: [
                {
                    name: '单双（奇偶）',
                    mes: ['将0-9分为两类：单 双','单：13579，双：02468'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大小',
                    mes: ['将0-9分为两类：大 小','大：56789，小：01234'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '质合',
                    mes: ['将0-9分为两类：质 合','质：12357，合：04689'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大中小',
                    mes: ['将0-9分为三类：大 中 小','大：789，中：3456，小：012'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                    ]
                },
                {
                    name: '012路',
                    mes: ['将0-9分别除以3，根据余数的值，划分为三类：0路，1路，2路','0路：0369，1路：147，2路：258'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                    ]
                },
                {
                    name: '对数',
                    mes: ['将0-9共10个数字围成环状，按照相对位置分为五类，0对5，1对6，2对7，3对8，4对9'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '不定位置选号',
            children: [
                {
                    name: '配号码',
                    mes: ['两组号码相互搭配，配出所有可能的组合'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '必出码（铁率）',
                    mes: ['所选的号码中，至少出现1个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数字多次出现，称为重数'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号。特别约定0与9相邻'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '入数个数',
                    mes: ['不相同数字数量之和。例如1258入数4个，0103入数3个，0006入数2个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '极值',
                    mes: ['最大或者最小的一个数称为极值'],
                    btn: [
                        {
                            name: '最大值'
                        },
                        {
                            name: '最小值'
                        }
                    ]
                },
                {
                    name: '跨度',
                    mes: ['最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '加减乘除运算',
            children: [
                {
                    name: '和值',
                    mes: ['所有数字相加所得之和'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之和的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值+跨度',
                    mes: ['合值与跨度之和','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值-跨度',
                    mes: ['合值与跨度之差','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '两数乘积',
                    mes: ['所选两个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '余数和',
                    mes: ['ABCD分别除以一个数，取余数之和'],
                    btn: [
                        {
                            name: '除以3'
                        }
                    ]
                }
            ]
        },
    ],

    //四定大底
    fourDecNum:[],
    //四现大底
    fourShowNum:[],
    //三定大底
    threeDecNum:[],
    //三现大底
    threeShowNum:[],
    //二定大底
    twoDecNum:[],
    //二现大底
    twoShowNum:[],


    //可改
    //四定大底
    CfourDecNum:[],
    //四现大底
    CfourShowNum:[],
    //三定大底
    CthreeDecNum:[],
    //三现大底
    CthreeShowNum:[],
    //二定大底
    CtwoDecNum:[],
    //二现大底
    CtwoShowNum:[],

    //四定大底(计算)
    fourDecComputed:[],
    //四现大底(计算)
    fourShowComputed:[],
    //三定大底(计算)
    threeDecComputed:[],
    //三现大底(计算)
    threeShowComputed:[],
    //二定大底(计算)
    twoDecComputed:[],
    //二现大底(计算)
    twoShowComputed:[],

    //条件选择集合
    // 字段含义
    // isOverlap判断交并集 1.交集 2.并集
    // isTrue是否选中 1.选中 0.没选 
    // isSave是否保留 1.保留 0.排除
    // val 所选条件数据

    //二现
    condTwoShow:[
        {
            isOverlap:1
        },
        {
            name:'合值（合分）',
            isTrue: 0,
            btn:[
                {
                    name:'设置',
                    isTrue:0,
                    isSave: 1,
                    val:[false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name:'单双（奇偶）',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    group:[
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false]
                    ],
                    mes: '',
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val:[], //二连号
                    mes: '',
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false],
                    mes: '',
                },
            ]
        },
    ],
    conditionTwoShow: [
        {
            isOverlap:1
        },
    ],

    //三现
    condThreeShow:[
        {
            isOverlap:1
        },
        {
            name:'合值（合分）',
            isTrue: 0,
            btn:[
                {
                    name:'设置',
                    isTrue:0,
                    isSave: 1,
                    val:[false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name:'单双（奇偶）',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    group:[
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false]
                    ],
                    mes: '',
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '三数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false],
                    mes: '',
                },
            ]
        }
    ],
    conditionThreeShow: [
        {
            isOverlap:1
        },
    ],

    //四现
    condFourShow:[
        {
            isOverlap:1
        },
        {
            name:'合值（合分）',
            isTrue: 0,
            btn:[
                {
                    name:'设置',
                    isTrue:0,
                    isSave: 1,
                    val:[false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name:'单双（奇偶）',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    group:[
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false]
                    ],
                    mes: '',
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '四数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false],
                    mes: '',
                },
            ]
        }
    ],
    conditionFourShow: [
        {
            isOverlap:1
        },
    ],

    //二定
    condTwoDec:[
        {
            isOverlap:1
        },
        {
            name: '定位置',
            isTrue: 0,
            btn:[
                {
                    name: '乘号位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '批量选码',
                    isSave: 1,
                    isTrue: 0,
                    thousandRadio: [0,0,0],
                    hundredRadio: [0,0,0],
                    tenRadio: [0,0,0],
                    pieceRadio: [0,0,0],
                    mes: '',
                },
                {
                    name: '自由选码',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    val1: [false,false,false,false,false,false,false,false,false,false],
                    val2: [false,false,false,false,false,false,false,false,false,false],
                    val3: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '头尾有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '中肚有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '有数',
            isTrue: 0,
            btn: [
                {
                    name: '2个位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    haveNumber: [],
                    mes: '',
                }
            ]
        },
        {
            name: '两数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    numberSum: [],
                    mes: '',
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '单双（奇偶）',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    group:[
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false]
                    ],
                    mes: '',
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    multiplyWay: [],
                    mes: '',
                }
            ]
        },
        {
            name: '位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '反位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                         false,false,false,false,false,false,false,
                         false,false,false,false,false,false,false,
                         false,false,false,false,false,false,false,
                         false,false,false,false,false,false,false,
                         false,false,false,false,false,false,false,
                         false,false,false,false,false,false,false,
                         false,false,false,false,false,false,false,
                         false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '开奖历史',
            isTrue: 0,
            btn: [
                {
                    name: '七星彩',
                    isSave: 1,
                    isTrue: 0,
                    val: '',
                    listNum: [],
                    mes: '',
                },
                {
                    name: '排列五',
                    isSave: 1,
                    isTrue: 0,
                    val: '',
                    listNum: [],
                    mes: '',
                },
            ]
        },
        {
            name: '全转（全倒）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
    ],
    conditionTwoDec: [
        {
            isOverlap:1
        },
    ],

    //三定
    condThreeDec:[
        {
            isOverlap:1
        },
        {
            name: '定位置',
            isTrue: 0,
            btn:[
                {
                    name: '乘号位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '批量选码',
                    isSave: 1,
                    isTrue: 0,
                    thousandRadio: [0,0,0],
                    hundredRadio: [0,0,0],
                    tenRadio: [0,0,0],
                    pieceRadio: [0,0,0],
                    mes: '',
                },
                {
                    name: '自由选码',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    val1: [false,false,false,false,false,false,false,false,false,false],
                    val2: [false,false,false,false,false,false,false,false,false,false],
                    val3: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '头尾有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '中肚有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '有数',
            isTrue: 0,
            btn: [
                {
                    name: '3个位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    haveNumber: [],
                    mes: '',
                },
                {
                    name: '2个位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    haveNumber: [],
                    mes: '',
                }
            ]
        },
        {
            name: '凹凸升降',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '两数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    numberSum: [],
                    mes: '',
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '三数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    numberSum: [],
                    mes: '',
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '单双（奇偶）',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    group:[
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false]
                    ],
                    mes: '',
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    multiplyWay: [],
                    mes: '',
                }
            ]
        },
        {
            name: '三数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    multiplyWay: [],
                    mes: '',
                },
            ]
        },
        {
            name: '位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '反位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '开奖历史',
            isTrue: 0,
            btn: [
                {
                    name: '七星彩',
                    isSave: 1,
                    isTrue: 0,
                    val: '',
                    listNum: [],
                    mes: '',
                },
                {
                    name: '排列五',
                    isSave: 1,
                    isTrue: 0,
                    val: '',
                    listNum: [],
                    mes: '',
                },
            ]
        },
        {
            name: '全转（全倒）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false],
                    mes: '',
                },
            ]
        },
    ],
    conditionThreeDec: [
        {
            isOverlap:1
        },
    ],

    //四定
    condFourDec:[
        {
            isOverlap:1
        },
        {
            name: '定位置',
            isTrue: 0,
            btn:[
                {
                    name: '批量选码',
                    isSave: 1,
                    isTrue: 0,
                    thousandRadio: [0,0,0],
                    hundredRadio: [0,0,0],
                    tenRadio: [0,0,0],
                    pieceRadio: [0,0,0],
                    mes: '',
                },
                {
                    name: '自由选码',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    val1: [false,false,false,false,false,false,false,false,false,false],
                    val2: [false,false,false,false,false,false,false,false,false,false],
                    val3: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '头尾有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '中肚有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '有数',
            isTrue: 0,
            btn: [
                {
                    name: '4个位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '3个位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    haveNumber: [],
                    mes: '',
                },
                {
                    name: '2个位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    haveNumber: [],
                    mes: '',
                }
            ]
        },
        {
            name: '凹凸升降',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '两数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    numberSum: [],
                    mes: '',
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '三数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    numberSum: [],
                    mes: '',
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '单双（奇偶）',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    group:[
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false]
                    ],
                    mes: '',
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '减式',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    add: [],
                    dec: [],
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    multiplyWay: [],
                    mes: '',
                }
            ]
        },
        {
            name: '三数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    multiplyWay: [],
                    mes: '',
                },
            ]
        },
        {
            name: '四数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '反位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '开奖历史',
            isTrue: 0,
            btn: [
                {
                    name: '七星彩',
                    isSave: 1,
                    isTrue: 0,
                    val: '',
                    listNum: [],
                    mes: '',
                },
                {
                    name: '排列五',
                    isSave: 1,
                    isTrue: 0,
                    val: '',
                    listNum: [],
                    mes: '',
                },
            ]
        },
        {
            name: '全转（全倒）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false],
                    mes: '',
                },
            ]
        },
    ],
    conditionFourDec: [
        {
            isOverlap:1
        },
    ],

    //二现初始化
    condTwoShowInit:[
        {
            isOverlap:1
        },
        {
            name:'合值（合分）',
            isTrue: 0,
            btn:[
                {
                    name:'设置',
                    isTrue:0,
                    isSave: 1,
                    val:[false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name:'单双（奇偶）',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    group:[
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false]
                    ],
                    mes: '',
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false],
                    mes: '',
                },
            ]
        },
    ],
    //三现初始化
    condThreeShowInit:[
        {
            isOverlap:1
        },
        {
            name:'合值（合分）',
            isTrue: 0,
            btn:[
                {
                    name:'设置',
                    isTrue:0,
                    isSave: 1,
                    val:[false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name:'单双（奇偶）',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    group:[
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false]
                    ],
                    mes: '',
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '三数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false],
                    mes: '',
                },
            ]
        }
    ],
    //四现初始化
    condFourShowInit:[
        {
            isOverlap:1
        },
        {
            name:'合值（合分）',
            isTrue: 0,
            btn:[
                {
                    name:'设置',
                    isTrue:0,
                    isSave: 1,
                    val:[false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name:'单双（奇偶）',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    group:[
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false]
                    ],
                    mes: '',
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '四数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false],
                    mes: '',
                },
            ]
        }
    ],
    //二定初始化
    condTwoDecInit:[
        {
            isOverlap:1
        },
        {
            name: '定位置',
            isTrue: 0,
            btn:[
                {
                    name: '乘号位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '批量选码',
                    isSave: 1,
                    isTrue: 0,
                    thousandRadio: [0,0,0],
                    hundredRadio: [0,0,0],
                    tenRadio: [0,0,0],
                    pieceRadio: [0,0,0],
                    mes: '',
                },
                {
                    name: '自由选码',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    val1: [false,false,false,false,false,false,false,false,false,false],
                    val2: [false,false,false,false,false,false,false,false,false,false],
                    val3: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '头尾有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '中肚有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '有数',
            isTrue: 0,
            btn: [
                {
                    name: '2个位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    haveNumber: [],
                    mes: '',
                }
            ]
        },
        {
            name: '两数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    numberSum: [],
                    mes: '',
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '单双（奇偶）',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    group:[
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false]
                    ],
                    mes: '',
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    multiplyWay: [],
                    mes: '',
                }
            ]
        },
        {
            name: '位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '反位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '开奖历史',
            isTrue: 0,
            btn: [
                {
                    name: '七星彩',
                    isSave: 1,
                    isTrue: 0,
                    val: '',
                    listNum: [],
                    mes: '',
                },
                {
                    name: '排列五',
                    isSave: 1,
                    isTrue: 0,
                    val: '',
                    listNum: [],
                    mes: '',
                },
            ]
        },
        {
            name: '全转（全倒）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
    ],
    //三定初始化
    condThreeDecInit:[
        {
            isOverlap:1
        },
        {
            name: '定位置',
            isTrue: 0,
            btn:[
                {
                    name: '乘号位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '批量选码',
                    isSave: 1,
                    isTrue: 0,
                    thousandRadio: [0,0,0],
                    hundredRadio: [0,0,0],
                    tenRadio: [0,0,0],
                    pieceRadio: [0,0,0],
                    mes: '',
                },
                {
                    name: '自由选码',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    val1: [false,false,false,false,false,false,false,false,false,false],
                    val2: [false,false,false,false,false,false,false,false,false,false],
                    val3: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '头尾有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '中肚有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '有数',
            isTrue: 0,
            btn: [
                {
                    name: '3个位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    haveNumber: [],
                    mes: '',
                },
                {
                    name: '2个位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    haveNumber: [],
                    mes: '',
                }
            ]
        },
        {
            name: '凹凸升降',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '两数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    numberSum: [],
                    mes: '',
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '三数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    numberSum: [],
                    mes: '',
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '单双（奇偶）',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    group:[
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false]
                    ],
                    mes: '',
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    multiplyWay: [],
                    mes: '',
                }
            ]
        },
        {
            name: '三数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    multiplyWay: [],
                    mes: '',
                },
            ]
        },
        {
            name: '位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '反位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '开奖历史',
            isTrue: 0,
            btn: [
                {
                    name: '七星彩',
                    isSave: 1,
                    isTrue: 0,
                    val: '',
                    listNum: [],
                    mes: '',
                },
                {
                    name: '排列五',
                    isSave: 1,
                    isTrue: 0,
                    val: '',
                    listNum: [],
                    mes: '',
                },
            ]
        },
        {
            name: '全转（全倒）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false],
                    mes: '',
                },
            ]
        },
    ],
    //四定初始化
    condFourDecInit:[
        {
            isOverlap:1
        },
        {
            name: '定位置',
            isTrue: 0,
            btn:[
                {
                    name: '批量选码',
                    isSave: 1,
                    isTrue: 0,
                    thousandRadio: [0,0,0],
                    hundredRadio: [0,0,0],
                    tenRadio: [0,0,0],
                    pieceRadio: [0,0,0],
                    mes: '',
                },
                {
                    name: '自由选码',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    val1: [false,false,false,false,false,false,false,false,false,false],
                    val2: [false,false,false,false,false,false,false,false,false,false],
                    val3: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '头尾有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '中肚有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '有数',
            isTrue: 0,
            btn: [
                {
                    name: '4个位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '3个位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    haveNumber: [],
                    mes: '',
                },
                {
                    name: '2个位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    haveNumber: [],
                    mes: '',
                }
            ]
        },
        {
            name: '凹凸升降',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '两数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    numberSum: [],
                    mes: '',
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '三数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    numberSum: [],
                    mes: '',
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '单双（奇偶）',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    val: [0,0,0,0],
                    mes: '',
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    group:[
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false],
                        [false,false,false,false,false,false,false,false,false,false]
                    ],
                    mes: '',
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '减式',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    add: [],
                    dec: [],
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    multiplyWay: [],
                    mes: '',
                }
            ]
        },
        {
            name: '三数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    multiplyWay: [],
                    mes: '',
                },
            ]
        },
        {
            name: '四数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '反位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false],
                    mes: '',
                }
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
        {
            name: '开奖历史',
            isTrue: 0,
            btn: [
                {
                    name: '七星彩',
                    isSave: 1,
                    isTrue: 0,
                    val: '',
                    listNum: [],
                    mes: '',
                },
                {
                    name: '排列五',
                    isSave: 1,
                    isTrue: 0,
                    val: '',
                    listNum: [],
                    mes: '',
                },
            ]
        },
        {
            name: '全转（全倒）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [],
                    mes: '',
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    val: [false,false,false,false,false,false],
                    mes: '',
                },
            ]
        },
    ],




})

export default state
