const mutations = {
    //更新payType
    updatePayType(state,type){
        state["payType"] = type;
    },
    //更新四定大底数据
    updateFourDec(state){
        for (var i=0;i<10000;i++){
            var num = i.toString();
            switch (num.toString().length){
                case 1:
                    num = '000' + num;
                    state["fourDecNum"].push(num);
                    break;
                case 2:
                    num = '00' + num;
                    state["fourDecNum"].push(num);
                    break;
                case 3:
                    num = '0' + num;
                    state["fourDecNum"].push(num);
                    break;
                case 4:
                    state["fourDecNum"].push(num);
                    break;
            }
        }
        state.CfourDecNum=state.fourDecNum
    },
    //更新四现大底数据
    updateFourShow(state){
        for (var i=0;i<10000;i++){
            var num = i.toString();
            switch (num.toString().length) {
                case 1:
                    num = '000' + num;
                    state["fourShowNum"].push(num);
                    break;
                case 2:
                    if (num[0] > num[1]){
                        break;
                    }
                    num = '00' + num;
                    state["fourShowNum"].push(num);
                    break;
                case 3:
                    if (num[0] > num[1])
                    {
                        break;
                    }
                    else if (num[1] > num[2])
                    {
                        break;
                    }
                    num = '0' + num;
                    state["fourShowNum"].push(num);
                    break;
                case 4:
                    if (num[0] > num[1])
                    {
                        break;
                    }
                    else if (num[1] > num[2])
                    {
                        break;
                    }
                    else if (num[2] > num[3])
                    {
                        break;
                    }
                    state["fourShowNum"].push(num);
                    break;
            }
        }
        state.CfourShowNum=state.fourShowNum
    },
    //更新三定大底数据
    updateThreeDec(state){
        //X在个位
        for (let i=0;i<1000;i++){
            let numStr = i.toString();
            switch (numStr.length) {
                case 1:
                    state["threeDecNum"].push('00'+numStr+'X');
                    break;
                case 2:
                    state["threeDecNum"].push('0'+numStr+'X');
                    break;
                case 3:
                    state["threeDecNum"].push(numStr+'X');
                    break;
            }
        }
        //X在十位
        for (let j=0;j<1000;j++){
            let numStr1 = j.toString();
            switch (numStr1.length) {
                case 1:
                    state["threeDecNum"].push('00'+'X'+numStr1);
                    break;
                case 2:
                    state["threeDecNum"].push('0'+numStr1[0]+'X'+numStr1[1]);
                    break;
                case 3:
                    state["threeDecNum"].push(numStr1[0]+numStr1[1]+'X'+numStr1[2]);
                    break;
            }
        }
        //X在百位
        for (let k=0;k<1000;k++){
            let numStr2 = k.toString();
            switch (numStr2.length) {
                case 1:
                    state["threeDecNum"].push('0'+'X'+'0'+numStr2);
                    break;
                case 2:
                    state["threeDecNum"].push('0'+'X'+numStr2);
                    break;
                case 3:
                    state["threeDecNum"].push(numStr2[0]+'X'+numStr2[1]+numStr2[2]);
                    break;
            }
        }
        //X在千位
        for (let l=0;l<1000;l++){
            let numStr3 = l.toString();
            switch (numStr3.length) {
                case 1:
                    state["threeDecNum"].push('X'+'00'+numStr3);
                    break;
                case 2:
                    state["threeDecNum"].push('X'+'0'+numStr3);
                    break;
                case 3:
                    state["threeDecNum"].push('X'+numStr3);
                    break;
            }
        }
        state.CthreeDecNum=state.threeDecNum
    },
    //更新三现大底数据
    updateThreeShow(state){
        for (var i=0;i<10000;i++){
            var num = i.toString();
            switch (num.toString().length) {
                case 1:
                    num = '00' + num;
                    state["threeShowNum"].push(num);
                    break;
                case 2:
                    if (num[0] > num[1]){
                        break;
                    }
                    num = '0' + num;
                    state["threeShowNum"].push(num);
                    break;
                case 3:
                    if (num[0] > num[1])
                    {
                        break;
                    }
                    else if (num[1] > num[2])
                    {
                        break;
                    }
                    state["threeShowNum"].push(num);
                    break;
            }
        }
        state.CthreeShowNum=state.threeShowNum
    },
    //更新二定大底数据
    updateTwoDec(state){
        //AXXB
        for (let i=0;i<100;i++){
            let numStr = i.toString();
            switch (numStr.length) {
                case 1:
                    state["twoDecNum"].push('0'+'XX'+numStr);
                    break;
                case 2:
                    state["twoDecNum"].push(numStr[0]+'XX'+numStr[1]);
                    break;
            }
        }
        //XABX
        for (let j=0;j<100;j++){
            let numStr1 = j.toString();
            switch (numStr1.length) {
                case 1:
                    state["twoDecNum"].push('X'+'0'+numStr1+'X');
                    break;
                case 2:
                    state["twoDecNum"].push('X'+numStr1+'X');
                    break;
            }
        }
        //ABXX
        for (let k=0;k<100;k++){
            let numStr2 = k.toString();
            switch (numStr2.length) {
                case 1:
                    state["twoDecNum"].push('0'+numStr2+'XX');
                    break;
                case 2:
                    state["twoDecNum"].push(numStr2+'XX');
                    break;
            }
        }
        //AXBX
        for (let l=0;l<100;l++){
            let numStr3 = l.toString();
            switch (numStr3.length) {
                case 1:
                    state["twoDecNum"].push('0'+'X'+numStr3+'X');
                    break;
                case 2:
                    state["twoDecNum"].push(numStr3[0]+'X'+numStr3[1]+'X');
                    break;
            }
        }
        //XAXB
        for (let m=0;m<100;m++){
            let numStr5 = m.toString();
            switch (numStr5.length) {
                case 1:
                    state["twoDecNum"].push('X'+'0'+'X'+numStr5);
                    break;
                case 2:
                    state["twoDecNum"].push('X'+numStr5[0]+'X'+numStr5[1]);
                    break;
            }
        }
        //XXAB
        for (let n=0;n<100;n++){
            let numStr4 = n.toString();
            switch (numStr4.length) {
                case 1:
                    state["twoDecNum"].push('XX'+'0'+numStr4);
                    break;
                case 2:
                    state["twoDecNum"].push('XX'+numStr4);
                    break;
            }
        }
        state.CtwoDecNum=state.twoDecNum
    },
    //更新二现大底数据
    updateTwoShow(state){
        for (var i=0;i<100;i++){
            var num = i.toString();
            switch (num.toString().length) {
                case 1:
                    num = '0' + num;
                    state["twoShowNum"].push(num);
                    break;
                case 2:
                    if (num[0] > num[1]){
                        break;
                    }
                    state["twoShowNum"].push(num);
                    break;
            }
        }
        state.CtwoShowNum=state.twoShowNum
    },
    //更新条件数据
    updateCondition(state,condList){
        switch (state["payType"]) {
            case 1:
                state["condFourDec"] = condList;
                break;
            case 2:
                state["condFourShow"] = condList;
                break;
            case 3:
                state["condThreeDec"] = condList;
                break;
            case 4:
                state["condThreeShow"] = condList;
                break;
            case 5:
                state["condTwoDec"] = condList;
                break;
            case 6:
                state["condTwoShow"] = condList;
                break;
        }
    },
    //更新条件数据2(局部更新)
    updateCondition2(state,payload){
        switch (state["payType"]) {
            case 1:
                state["condFourDec"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1,payload.obj);
                state["condFourDec"].find(item =>(item.name === payload.title)).isTrue = 1;
                break;
            case 2:
                state["condFourShow"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1,payload.obj);
                state["condFourShow"].find(item =>(item.name === payload.title)).isTrue = 1;
                break;
            case 3:
                state["condThreeDec"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1,payload.obj);
                state["condThreeDec"].find(item =>(item.name === payload.title)).isTrue = 1;
                break;
            case 4:
                state["condThreeShow"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1,payload.obj);
                state["condThreeShow"].find(item =>(item.name === payload.title)).isTrue = 1;
                break;
            case 5:
                state["condTwoDec"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1,payload.obj);
                state["condTwoDec"].find(item =>(item.name === payload.title)).isTrue = 1;
                break;
            case 6:
                state["condTwoShow"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1,payload.obj);
                state["condTwoShow"].find(item =>(item.name === payload.title)).isTrue = 1;
                break;
        }
    },
    //增加条件数据
    addCondition(state,payload){
        switch (state["payType"]) {
            case 1:
                if (state["conditionFourDec"].find(item =>(item.name === payload.title)) !== undefined){
                    state["conditionFourDec"].find(item =>(item.name === payload.title)).btn.push(payload.obj.btn[0]);
                }else {
                    state["conditionFourDec"].push(payload.obj);
                }
                break;
            case 2:
                if (state["conditionFourShow"].find(item =>(item.name === payload.title)) !== undefined){
                    state["conditionFourShow"].find(item =>(item.name === payload.title)).btn.push(payload.obj.btn[0]);
                }else {
                    state["conditionFourShow"].push(payload.obj);
                }
                break;
            case 3:
                if (state["conditionThreeDec"].find(item =>(item.name === payload.title)) !== undefined){
                    state["conditionThreeDec"].find(item =>(item.name === payload.title)).btn.push(payload.obj.btn[0]);
                }else {
                    state["conditionThreeDec"].push(payload.obj);
                }
                break;
            case 4:
                if (state["conditionThreeShow"].find(item =>(item.name === payload.title)) !== undefined){
                    state["conditionThreeShow"].find(item =>(item.name === payload.title)).btn.push(payload.obj.btn[0]);
                }else {
                    state["conditionThreeShow"].push(payload.obj);
                }
                break;
            case 5:
                if (state["conditionTwoDec"].find(item =>(item.name === payload.title)) !== undefined){
                    state["conditionTwoDec"].find(item =>(item.name === payload.title)).btn.push(payload.obj.btn[0]);
                }else {
                    state["conditionTwoDec"].push(payload.obj);
                }
                break;
            case 6:
                if (state["conditionTwoShow"].find(item =>(item.name === payload.title)) !== undefined){
                    state["conditionTwoShow"].find(item =>(item.name === payload.title)).btn.push(payload.obj.btn[0]);
                }else {
                    state["conditionTwoShow"].push(payload.obj);
                }
                break;
        }
    },
    //删除条件
    deletedCondition(state,payload){
        switch (state["payType"]) {
            case 1:
                state["conditionFourDec"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1);
                break;
            case 2:
                state["conditionFourShow"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1);
                break;
            case 3:
                state["conditionThreeDec"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1);
                break;
            case 4:
                state["conditionThreeShow"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1);
                break;
            case 5:
                state["conditionTwoDec"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1);
                break;
            case 6:
                state["conditionTwoShow"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1);
                break;
        }
    },
    //清除所有条件
    clearCondition(state){
        switch (state["payType"]) {
            case 1:
                state["conditionFourDec"] = [
                    {
                        isOverlap:1
                    },
                ];
                break;
            case 2:
                state["conditionFourShow"] = [
                    {
                        isOverlap:1
                    },
                ];
                break;
            case 3:
                state["conditionThreeDec"] = [
                    {
                        isOverlap:1
                    },
                ];
                break;
            case 4:
                state["conditionThreeShow"] = [
                    {
                        isOverlap:1
                    },
                ];
                break;
            case 5:
                state["conditionTwoDec"] = [
                    {
                        isOverlap:1
                    },
                ];
                break;
            case 6:
                state["conditionTwoShow"] = [
                    {
                        isOverlap:1
                    },
                ];
                break;
        }
    },
    //修改条件数据
    updateCondition3(state,payload){
        switch (state["payType"]) {
            case 1:
                state["conditionFourDec"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1,payload.obj);
                break;
            case 2:
                state["conditionFourShow"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1,payload.obj);
                break;
            case 3:
                state["conditionThreeDec"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1,payload.obj);
                break;
            case 4:
                state["conditionThreeShow"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1,payload.obj);
                break;
            case 5:
                state["conditionTwoDec"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1,payload.obj);
                break;
            case 6:
                state["conditionTwoShow"].find(item =>(item.name === payload.title)).btn.splice(payload.index,1,payload.obj);
                break;
        }
    },
    //大底计算
    numComputed(state,list){
        switch (state["payType"]) {
            case 1:
                state["fourDecComputed"] = list;
                break;
            case 2:
                state["fourShowComputed"] = list;
                break;
            case 3:
                state["threeDecComputed"] = list;
                break;
            case 4:
                state["threeShowComputed"] = list;
                break;
            case 5:
                state["twoDecComputed"] = list;
                break;
            case 6:
                state["twoShowComputed"] = list;
                break;
        }
    },
    //交集并集
    updateOverLap(state,value){
        switch (state["payType"]) {
            case 1:
                state["conditionFourDec"][0].isOverlap = value;
                break;
            case 2:
                state["conditionFourShow"][0].isOverlap = value;
                break;
            case 3:
                state["conditionThreeDec"][0].isOverlap = value;
                break;
            case 4:
                state["conditionThreeShow"][0].isOverlap = value;
                break;
            case 5:
                state["conditionTwoDec"][0].isOverlap = value;
                break;
            case 6:
                state["conditionTwoShow"][0].isOverlap = value;
                break;
        }
    },

    //根据条件更新大底
    alertFourDec(state,value){
        state.CfourDecNum=value
    },
    alertFourShow(state,value){
        state.CfourShowNum=value
    },
    alertThreeDec(state,value){
        state.CthreeDecNum=value
    },
    alertThreeShow(state,value){
        state.CthreeShowNum=value
    },
    alertTwoDec(state,value){
        state.CtwoDecNum=value
    },
    alertTwoShow(state,value){
        state.CtwoShowNum=value
    },
    clearData(state){
        delete state.payType;
        delete state.version;
        delete state.menuFourDec;
        delete state.menuFourShow;
        delete state.menuThreeDec;
        delete state.menuThreeShow;
        delete state.menuTwoDec;
        delete state.menuTwoShow;

        delete state.fourDecNum;
        delete state.fourShowNum;
        delete state.threeDecNum;
        delete state.threeShowNum;
        delete state.twoDecNum;
        delete state.twoShowNum;

        delete state.CfourDecNum;
        delete state.CfourShowNum;
        delete state.CthreeDecNum;
        delete state.CthreeShowNum;
        delete state.CtwoDecNum;
        delete state.CtwoShowNum;

        delete state.fourDecComputed;
        delete state.fourShowComputed;
        delete state.threeDecComputed;
        delete state.threeShowComputed;
        delete state.twoDecComputed;
        delete state.twoShowComputed;

        delete state.condTwoShow;
        delete state.conditionTwoShow;
        delete state.condThreeShow;
        delete state.conditionThreeShow;

        delete state.condFourShow;
        delete state.conditionFourShow;
        delete state.condTwoDec;
        delete state.conditionTwoDec;

        delete state.condThreeDec;
        delete state.conditionThreeDec;
        delete state.condFourDec;
        delete state.conditionFourDec;

        delete state.condTwoShowInit;
        delete state.condThreeShowInit;
        delete state.condFourShowInit;
        delete state.condTwoDecInit;

        delete state.condThreeDecInit;
        delete state.condFourDecInit;
    },
    addVersion(state,value){
        state["version"] = value;
    }

}

export default mutations
