import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import isWeixin from './utils/utils';
import versionTood from '@/api/versionUpdate';


Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/home'
  },
  
  {
    name: 'payWay',
    component: () => import('./view/home/payWay'),
    meta: {
      title: '添加筛选条件'
    }
  },
  {
    name: 'payWayIndex',
    component: () => import('./view/home/payWayIndex'),
    meta: {
      title: '包奖王'
    }
  },
  {
    name: 'showNumIndex',
    component: () => import('./view/home/showNumIndex'),
    meta: {
      title: '查看大底'
    }
  },
  {
    name: 'home',
    component: () => import('./view/home'),
    meta: {
      title: '奖表【好劲抽】'
    }
    // children:[{
    //   path:'/home/swipe',
    //   name:'swipe',
    //   component:()=>import('./view/home/swipe')
    // }]
  },
  {
    name: 'dream',
    component: () => import('./view/dream'),
    meta: {
      title: '经典解梦【好劲抽】'
    }
  },
  {
    name: 'onlive',
    component: () => import('./view/onlive'),
    meta: {
      title: '开奖直播【好劲抽】'
    }
  },
  {
    name: 'me',
    component: () => import('./view/me'),
    meta: {
      title: '会员中心'
    }
  },
  {
    name: 'userInfo',
    component: () => import('./view/me/userInfo'),
    meta: {
      title: '会员中心'
    }
  },
  {
    name: 'setUp',
    component: () => import('./view/me/setUp'),
    meta: {
      title: '设置'
    }
  },
  {
    name: 'login',
    component: () => import('./view/login'),
    meta: {
      title: '登录'
    }
  },
  {
    name: 'register',
    component: () => import('./view/login/register'),
    meta: {
      title: '注册'
    }
  },
  {
    name: 'forecast',
    component: () => import('./view/home/forecast'),
    meta: {
      title: '特邀码师',
      keepAlive: true,//此组件需要被缓存
    }
  },
  {
    name: 'contentDetail',
    component: () => import('./view/home/contentDetail'),
    meta: {
      title: '好劲抽 内容详情'
    }
  },
  {
    name: 'master_content_history',
    component: () => import('./view/home/masterContentHistory'),
    meta: {
      title: '大师简介'
    }
  },
  {
    name: 'awardBook',
    component: () => import('./view/home/awardBook'),
    meta: {
      title: '奖册'
    }
  },
  {
    name: 'awardBookDetail',
    component: () => import('./view/home/awardBookDetail'),
    meta: {
      title: '好劲抽'
    }
  },
  // 奖表
  {
    name: 'awardTable',
    component: () => import('./view/home/awardTable'),
    meta: {
      title: '好劲抽'
    }
  },
   // 奖表图片
   {
    name: 'awardImg',
    component: () => import('./view/home/awardImg'),
    meta: {
      title: '好劲抽'
    }
  },
  //画规
  {
    name: 'paint',
    component: () => import('./view/paint'),
    // component: () => import('./view/DrawingRules'),
    meta: {
      title: '画规'
    }
  },
  //画规排列五
  {
    name: 'drawingRules',
    component: () => import('./view/DrawingRules'),
    meta: {
      title: '画规'
    }
  },
  //画规七星彩
  {
    name: 'drawingRulesSeven',
    component: () => import('./view/DrawingRules/indexSeven'),
    meta: {
      title: '画规'
    }
  },
  //包奖王
  {
    name: 'prize',
    component: () => import('./view/prize'),
    meta: {
      title: '包奖王'
    }
  },
  {
    name: 'prizeDetail',
    component: () => import('./view/prize/prizeDetail'),
    meta: {
      title: '包奖王'
    }
  },
  {
    name: 'prizeNumbers',
    component: () => import('./view/prize/prizeNumbers'),
    meta: {
      title: '查看大底'
    }
  },
   //名人榜
  {
    name: 'ranking',
    component: () => import('./view/ranking'),
    meta: {
      title: '名人榜'
    }
  },
  {
    name: 'ranking/explain',
    component: () => import('./view/ranking/rankExplain'),
    meta: {
      title: '名人榜说明'
    }
  },
  {
    name: 'ranking/award',
    component: () => import('./view/ranking/weekmoonthaward'),
    meta: {
      title: '周月榜奖励'
    }
  },
  {
    name: 'ranking/masterstandard',
    component: () => import('./view/ranking/masterStandard'),
    meta: {
      title: '大师标准'
    }
  },
   //用户的详细信息
   {
    name: 'userDetail',
    component: () => import('./view/ranking/userDetail'),
    
  },
   //指定方案的前二十名
   {
    name: 'ranking/scheme',
    component: () => import('./view/ranking/scheme'),
    
  },
   //个人的指定方案的近十期
   {
    name: 'ranking/personscheme',
    component: () => import('./view/ranking/personscheme'),
    
  },
    //个人首页
    {
    name: 'ranking/myinfo',
    component: () => import('./view/ranking/myinfo'),
      
    },
      //个人的指定方案的帖子
    {
      name: 'ranking/mypost',
      component: () => import('./view/ranking/mypost'),
      meta: {
        title: '评论'
      }
      
    },
  // 彩友圈
  {
    name: 'friendsCircle',
    component: () => import('./view/friendsCircle'),
    meta: {
      title: '彩友圈'
    }
  },
  {
    name: 'friendsCircle/explain',
    component: () => import('./view/friendsCircle/explain'),
    meta: {
      title: '彩友圈说明'
    }
  },
  {
    name: 'friendsCircle/publish',
    component: () => import('./view/friendsCircle/publish'),
    meta: {
      title: '发表'
    }
  },
  {
    name: 'friendsCircle/authorinfo',
    component: () => import('./view/friendsCircle/authorinfo'),
    meta: {
      title: 'TA的主页'
    }
  },
  {
    name: 'friendsCircle/scheme',
    component: () => import('./view/friendsCircle/scheme'),
    meta: {
      title: '设置方案'
    }
  },
  {
    name: 'friendsCircle/imageText',
    component: () => import('./view/friendsCircle/imageText'),
    meta: {
      title: '发表'
    }
  },
  //会员页路由
  {
    name: 'feedback',
    component: () => import('./view/system/feedback'),
    meta: {
      title: '建议反馈'
    }
  },
  {
    name: 'feedbackSubmit',
    component: () => import('./view/system/feedbackSubmit'),
    meta: {
      title: '反馈提交'
    }
  },
  {
    name: 'feedbackHistory',
    component: () => import('./view/system/feedbackHistory'),
    meta: {
      title: '反馈历史'
    }
  },
  {
    name: 'feedbackItem',
    component: () => import('./view/system/feedbackItem'),
    meta: {
      title: '热门问题'
    }
  },
  {
    name: 'contact',
    component: () => import('./view/system/contact'),
    meta: {
      title: '联系方式'
    }
  },
  {
    name: 'member',
    component: () => import('./view/system/member'),
    meta: {
      title: '会员特权'
    }
  },
  //我的
  {
    name: 'mastertop',
    component: () => import('./view/my/master'),
    meta: {
      title: '大师包月'
    }
  },
  {
    name: 'master',
    component: () => import('./view/me/moonMaster'),
    meta: {
      title: '大师包月'
    }
  },
  {
    name: 'masterPay',
    component: () => import('./view/my/masterpay'),
    meta: {
      title: '大师包月'
    }
  },
  {
    name: 'property',
    component: () => import('./view/my/property'),
    meta: {
      title: '我的资产'
    }
  },
  {
    name: 'earn',
    component: () => import('./view/my/earn'),
    meta: {
      title: '我的收益'
    }
  },
  {
    name: 'record',
    component: () => import('./view/my/record'),
    meta: {
      title: '兑换记录'
    }
  },
  {
    name: 'identify',
    component: () => import('./view/my/identify'),
    meta: {
      title: '实名认证'
    }
  },
  // 认证
  {
    name: 'id',
    component: () => import('./view/my/id'),
    meta: {
      title: '身份证号'
    }
  },
  {
    name: 'idcard',
    component: () => import('./view/my/idcard'),
    meta: {
      title: '身份证照片'
    }
  },
  {
    name: 'alipay',
    component: () => import('./view/my/alipay'),
    meta: {
      title: '支付宝账号'
    }
  },
  {
    name: 'weixin',
    component: () => import('./view/my/weixin'),
    meta: {
      title: '微信号'
    }
  },
  {
    name: 'myegg',
    component: () => import('./view/my/myegg'),
    meta: {
      title: '我的太阳'
    }
  },
  {
    name: 'eggdetail',
    component: () => import('./view/my/eggdetail'),
    meta: {
      title: '查看明细'
    }
  },
  {
    name: 'eggexchange',
    component: () => import('./view/my/eggexchange'),
    meta: {
      title: '兑换太阳'
    }
  },
  {
    name: 'desc',
    component: () => import('./view/my/desc'),
    meta: {
      title: '太阳说明'
    }
  },
  {
    name: 'payegg',
    component: () => import('./view/my/payegg'),
    meta: {
      title: '太阳充值'
    }
  },
  {
    name: 'collect',
    component: () => import('./view/my/collect'),
    meta: {
      title: '我的收藏'
    }
  },
  {
    name: 'article',
    component: () => import('./view/my/article'),
    meta: {
      title: '收藏的帖子'
    }
  },
  {
    name: 'huagui',
    component: () => import('./view/my/huagui'),
    meta: {
      title: '收藏的智能规'
    }
  },
  {
    name: 'defriend',
    component: () => import('./view/my/defriend'),
    meta: {
      title: '我的黑名单'
    }
  },
  {
    name: 'defrienddesc',
    component: () => import('./view/my/defrienddesc'),
    meta: {
      title: '拉黑说明'
    }
  },
  {
    name: 'rank',
    component: () => import('./view/my/rank'),
    meta: {
      title: '我的等级'
    }
  },
  {
    name: 'integrate',
    component: () => import('./view/my/integrate'),
    meta: {
      title: '查看明细'
    }
  },
  //修改密码
  {
    name: 'editPassword',
    component: () => import('./view/me/editPassword'),
    meta: {
      title: '修改密码'
    }
  },
  {
    name: 'exchangeProxy',
    component: () => import('./view/proxy/exchangeProxy'),
    meta: {
      title: '《兑换服务协议》'
    }
  },
  {
    name: 'manage',
    component: () => import('./view/proxy/manage'),
    meta: {
      title: '《彩友圈管理规范》'
    }
  },
  {
    name: 'payProxy',
    component: () => import('./view/proxy/pay'),
    meta: {
      title: '《充值服务协议》'
    }
  },
  {
    name: 'appreciate',
    component: () => import('./view/proxy/appreciate'),
    meta: {
      title: '《充值服务协议》'
    }
  },
  {
    name: 'privacy',
    component: () => import('./view/proxy/privacy'),
    meta: {
      title: '《隐私协议》'
    }
  },
  {
    name: 'right',
    component: () => import('./view/proxy/right'),
    meta: {
      title: '《大师权利与义务》'
    }
  },
  //支付完成之后跳转
  {
    name: 'payComplete',
    component: () => import('./view/my/payComplete'),
    meta: {
      title: '支付反馈'
    }
  },
  //备用隐私协议
  {
    name: 'clientProxy',
    component: () => import('./view/proxy/clientProxy'),
    meta: {
      title: '用户服务协议'
    }
  },
  {
    name: 'appProxy',
    component: () => import('./view/proxy/appProxy'),
    meta: {
      title: '好劲抽隐私协议'
    }
  },
  {
    name: 'h5Proxy',
    component: () => import('./view/proxy/h5Proxy'),
    meta: {
      title: '好劲抽隐私协议'
    }
  },
  {
    name: 'help',
    component: () => import('./view/my/help'),
    meta: {
      title: '帮助'
    }
  },
  {
    name: 'payment',
    component: () => import('./view/payment'),
    meta: {
      title: '支付'
    }
  },
  {
    name: 'paymenth5',
    component: () => import('./view/payment/paymentH5'),
    meta: {
      title: '支付'
    }
  },
  {
    name: 'commonvip',
    component: () => import('./view/payment/vipPayment'),
    meta: {
      title: '普通vip'
    }
  },
  {
    name: 'vippayment',
    component: () => import('./view/payment/svipPayment'),
    meta: {
      title: 'Svip'
    }
  },
  {
    name: 'wannianLi',
    component: () => import('./view/home/wanNianLi'),
    meta: {
      title: '万年历'
    }
  },
  {
    name: 'attendance',
    component: () => import('./view/home/attendance'),
    meta: {
      title: '签到'
    }
  },
  {
    name: 'integral',
    component: () => import('./view/me/integral'),
    meta: {
      title: '积分情况'
    }
  },
  {
    name: 'qrcode',
    component: () => import('./view/me/qrCode'),
    meta: {
      title: '二维码'
    }
  },
  {
    name: 'famousranking',
    component: () => import('./view/publicAccount/famousRanking'),
    meta: {
      title: '红人榜'
    }
  },
  {
    name: 'famousdetail',
    component: () => import('./view/publicAccount/famousDetail'),
    meta: {
      title: '详情'
    }
  },
  {
    name: 'masterranking',
    component: () => import('./view/publicAccount/masterRanking'),
    meta: {
      title: '大师榜资料'
    }
  },
  {
    name: 'masterrankinglist',
    component: () => import('./view/publicAccount/masterRankingList'),
    meta: {
      title: '大师榜列表'
    }
  },
  {
    name: 'masterdetail',
    component: () => import('./view/publicAccount/masterDetail'),
    meta: {
      title: '详情'
    }
  },
  {
    name: 'masterrule',
    component: () => import('./view/publicAccount/masterRule'),
    meta: {
      title: '大师规律'
    }
  },
  {
    name: 'masterrulelist',
    component: () => import('./view/publicAccount/masterRuleList'),
    meta: {
      title: '大师规律列表'
    }
  },
  {
    name: 'hallOfFame',
    component: () => import('./view/fame'),
    meta: {
      title: '名人堂'
    }
  },
  {
    name: 'masterruledetail',
    component: () => import('./view/publicAccount/masterRuleDetail'),
    meta: {
      title: '详情'
    }
  },
  {
    name: 'userAgreement',
    component: () => import('./view/home/userAgreement'),
    meta: {
      title: '用户协议'
    }
  },
  {
    name: 'privacyAgreement',
    component: () => import('./view/home/privacyAgreement'),
    meta: {
      title: '隐私协议'
    }
  },


];

// add route path
routes.forEach(route => {
  // let children = route.children
  // if(children){
  //   children.forEach(child=>{
  //     child.path = route.path || '/' +route.name +  '/' +(child.name || '');
  //   })
  // }else{
  route.path = route.path || '/' + (route.name || '');
  // }
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  // const title = to.meta && to.meta.title;
  // if (title) {
  //   document.title = title;
  // }
  
  next();
  store.commit('common/updateState', [{ name: ['footerActive'], value: to.name === 'home' ? 0 : to.name === 'dream' ? 1 : to.name === 'onlive' ? 2 : to.name === 'me' ? 3 : 0 }])
  //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  versionTood.isNewVersion();
});

export {
  router
}