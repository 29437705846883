import API from '@/api';
import axios from 'axios';

const actions = {
  async resetToken({commit, state}) {
    const res = await axios({
      url: API.API_TOKEN_REFRESH,
      method: "get",
      params: { refresh_token: state.loginInfo.refresh_token }
    })
    commit('updateState', [
      { name: ['loginInfo', 'access_token'], value: res.data.access_token },
      { name: ['loginInfo', 'refresh_token'], value: res.data.refresh_token }
    ])
  }
}

export default actions
