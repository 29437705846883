const VUE_APP_API = process.env.VUE_APP_API
console.log(VUE_APP_API);
console.log('process.env.VUE_APP_API1', VUE_APP_API);

export default {
  API_LOTTERY_LATEST_ONE: VUE_APP_API + "/one/lottery-anon/latest-one", // 彩票 最新一期 内容 详细信息
  API_LOTTERY_LATEST_ONE_ALLIN: VUE_APP_API + "/one/lottery-anon/latest-one-allin", // 彩票 最新一期 内容 详细信息（全部）
  API_DREAM_CONTENT_ANON_LISTS: VUE_APP_API + "/one/dream-content-anon/lists", // 解梦查询 分页
  API_ARTICLE_LISTS: VUE_APP_API + "/one/article-anon/lists", // 文章列表 分页
  API_PROFILES_MASTER: VUE_APP_API + "/one/profiles/one-alias", // 预测资料 大师的信息
  API_PRIZE_BOOK_LISTS: VUE_APP_API + "/one/prize-book-anon/lists", // 奖册（标题） 分页
  API_PRIZE_BOOK_ONE: VUE_APP_API + "/one/prize-book-anon/one", // 奖册 内容 详细信息
  API_LOTTERY_LISTS_SPACE: VUE_APP_API + "/one/lottery-anon/lists-space", // 彩票 分页 追加期数
  API_LOTTERY_HEAD_IMAGE: VUE_APP_API + "/one/lottery-anon/head-image", // 长条的头部图片API
  API_LOTTERY_TAIL_IMAGE: VUE_APP_API + "/one/lottery-anon/tail-image",  // 长条的尾部图片API
  API_LOTTERY_LATEST_LIST: VUE_APP_API + "/one/lottery-files-anon/latest-lists",  // 长条的图片list
  API_COMPLAIN_TEXT: VUE_APP_API + "/one/complain-mes/one", //投诉信息


  API_ARTICLE_DETAILS: VUE_APP_API + "/one/article-anon/one", // 文章详细
  API_PRODUCT_DETAILS: VUE_APP_API + "/one/product/one", // 产品详细
  API_PAYMENT_PRODUCT: VUE_APP_API + "/pay/payment-anon/h5",//付款阅读品
  API_PAY_POINT: VUE_APP_API + "/pay/article-pay/pay-point",//通过积分打赏
  API_PAY_FORUM_POINT: VUE_APP_API + "/pay/forum-pay/pay-point",//通过积分打赏(论坛资料)
  API_FANS_BALANCE: VUE_APP_API + "/pay/fans-balance/account-status",//用户积分情况
  API_USERCENTER_USER_ANON_LOGIN: VUE_APP_API + "/usercenter/user-anon/login", // 登录
  API_USERCENTER_USER_ANON_LOGIN_REGISTER: VUE_APP_API + "/usercenter/user-anon/phone-login-register", // 登录和注册
  API_USERCENTER_USER_ANON_REGISTER: VUE_APP_API + "/usercenter/user-anon/register", // 用户名注册
  API_USERCENTER_LOGIN_SIGNUP_CODE: VUE_APP_API + "/notification/validation-code-anon/sms/login-signup-code", // 发送 登录-注册 短信

  API_TOKEN_REFRESH: VUE_APP_API + "/usercenter/user-anon/token-refresh", // 令牌刷新 token refresh #11
  API_USER_TOKEN_STATUS:VUE_APP_API+"/usercenter/user/token-status" ,// 查看当前token的状态
  
  API_USER_ANON_DETAIL: VUE_APP_API + "/usercenter/user-anon/detail", // 用户详细
  API_USER_ANON_PROFILE: VUE_APP_API + "/usercenter/user/profile", // 修改个人的profile 比如： 昵称 头像 男女
  API_WX_JSAPI_TICKET: VUE_APP_API + "/usercenter/wx-mp-anon/jsapi-ticket", // 微信授权

  API_FILESYSTEM_FILEINFO_FILE: VUE_APP_API + "/filesystem/fileinfo/file", // 上传文件 

  API_USERCENTER_FORUM_ANON_LISTS: VUE_APP_API + "/one/forum-anon/lists", // 论坛 分页
  API_ONE_FORUM_ANON_FOLLOW_SITUATION: VUE_APP_API + "/one/forum-anon/follow-situation", // 大师的粉丝和关注情况
  API_ONE_FORUM_FANS_FOLLOW: VUE_APP_API + "/one/forum/fans-follow", // 关注
  API_FORUM_THUMBS_LISTS: VUE_APP_API + "/one/forum/thumbs-lists", // 查询 用户 点赞或点踩 某些指定帖子 的列表
  API_USERCENTER_FORUM_CATEGORY: VUE_APP_API + "/one/forum/category", // 论坛 分类
  API_USERCENTER_FORUM_ONE: VUE_APP_API + "/one/forum/one", // 论坛 发帖
  API_FORUM_LATEST_PER10: VUE_APP_API + "/one/forum-anon/latest-per10",  // 论坛的近10期加未来一期
  API_FORUM_FAV_STATUS: VUE_APP_API + "/one/forum/fav-status",  // 收藏帖子 status（1）收藏（0）取消收藏
  API_FORUM_THUMBS_STS: VUE_APP_API + "/one/forum/thumbs-sts",  // status 点赞（1）点踩（2） 或 取消（3）

  API_FORUM_MANUAL_ANON_LISTS: VUE_APP_API + "/one/forum-manual-anon/lists",  // 论坛 说明手册 分页
  API_USER_EDIT_PASSWORD:VUE_APP_API+"/usercenter/user/password", // 修改密码
  API_USER_CHG_PASSWORD:VUE_APP_API+"/usercenter/user/chg-password", //修改密码(短信方式)
  API_USER_LOGOUT:VUE_APP_API+"/usercenter/user/logout" ,// 登出
  API_SLIDESHOW_ONE:VUE_APP_API+"/one/slideshow/one", // 获取轮播图列表
  API_SLIDESHOW_ANON_ONE:VUE_APP_API+"/one/slideshow-anon/one",// 获取轮播图列表 不需要token

  API_MONTHRANK_ONE:VUE_APP_API+"/one/monthRank/one",// 获取月榜 需要token
  API_MONTHPERSON_ONE:VUE_APP_API+"/one/monthUserRank/one",// 获取月的个人信息 需要token
  API_WEEKRANK_ONE:VUE_APP_API+"/one/weekRank/one",// 获取周榜 需要token
  API_WEEKPERSON_ONE:VUE_APP_API+"/one/weekUserRank/one",// 获取周的个人信息 需要token
  API_WEEK_MONTHRANKLIST_ONE:VUE_APP_API+"/one/pastRank/one",// 获取周榜、月榜  需要token
  API_CUSTOMERRANK_ONE:VUE_APP_API+"/one/weekCustomerRank/one",// 获取自己周榜信息 需要token
  API_CUSTOMERRANKMONTH_ONE:VUE_APP_API+"/one/monthCustomerRank/one",// 获取自己月榜信息 需要token
  API_MASTERINFO_ONE:VUE_APP_API+"/one/grand_master_get/one",// 获取获取所有大师所有方案的信息
  API_MASTERINFO_TEN_ONE:VUE_APP_API+"/one/get_period_detail/one",// 获取获取大师的某个方案近时期的信息

  API_WEEKLY_LIST:VUE_APP_API+"/one/rank-forum/weekly-list",// 一次性获取周榜信息，以及个人的信息。
  API_MONTHLY_LIST:VUE_APP_API+"/one/rank-forum/monthly-list",// 一次性获取月榜信息，以及个人的信息。
  API_MONTHLY_TITLE_LIST:VUE_APP_API+"/one/rank-forum/monthly-titles-list",// 月榜期号列表。
  API_MONTHLY_AUTHOR_LIST:VUE_APP_API+"/one/forum-anon/monthly-author-list",// 作者月帖子的列表。
  API_WEEKLY_TITLE_LIST:VUE_APP_API+"/one/rank-forum/weekly-titles-list",// 周榜期号列表。
  API_WEEKLY_AUTHOR_LIST:VUE_APP_API+"/one/forum-anon/weekly-author-list",// 作者周榜帖子的列表。


  API_RECHARGE_CONFIG:VUE_APP_API+"/pay/recharge-config-anon/items",// 获取充值配置的金额
  API_SVIP_INFO:VUE_APP_API+"/one/member-anon/owner-lists",// 获取SVIP的信息列表
  API_MEMBERANON_FANS:VUE_APP_API+"/one/member-anon/fans-vip",// 获取用户是否订阅某一个大师的vip（详细）
  
  API_WEATHER_ONE:VUE_APP_API+"/one/weather-anon/one",// 获取当地天气  需要token

  API_WNL_ONE:VUE_APP_API+"/one/get_wnl-anon/one",//获取万年历日期数据

  API_ATTENDANCE_RECORD:VUE_APP_API+"/one/get_attendance_day-anon/add",//获取用户签到记录

  API_ATTENDANCE_ONE:VUE_APP_API+"/one/attendance-anon/add",//签到
  API_FAMOUS_LIST:VUE_APP_API+"/one/hongren-wx-anon/lists",//获取红人榜列表
  API_FAMOUS_DETAIL:VUE_APP_API+"/one/hongren-wx-anon/one",//获取红人详细信息
  API_MASTER_LIST:VUE_APP_API+"/one/dashi-wx-relation-anon/lists",//获取大师榜列表
  API_MASTER_DETAIL:VUE_APP_API+"/one/dashi-wx-relation-anon/one",//获取大师榜详细信息

  API_MOONMASTER_LIST:VUE_APP_API+"/one/profiles-product-anon/lists",//获取大师包月价格信息列表

  API_USER_AGREEMENT_ONE:VUE_APP_API+"/one/agreement-anon/one", //用户协议

  API_NOTE_ADD_ONE:VUE_APP_API+"/one/note/one",//笔记保存
  API_NOTE_GET_ONE:VUE_APP_API+"/one/note/list",//笔记查询
  API_NOTE_UPDATE_ONE:VUE_APP_API+"/one/note/note-update",//笔记更新
  API_NOTE_DELETED_ONE:VUE_APP_API+"/one/note/note-deleted",//笔记删除

  API_RANK_FORUM_MASTER_LIST:VUE_APP_API+"/one/rank-forum-anon/master-list",//名人堂
}
