import 'amfe-flexible';
import Vue from 'vue';
import App from './App';
import { router } from './router';
import axios from 'axios';
import store from './store'
import API from './api';
import HTTP from './api/config';
import JSONbig from 'json-bigint'
import utils from './assets/js/util/utils';
//import '@/assets/js/util/VConsole'

//引入weixin sdk

//引入复制板块
import VueClipBoard from 'vue-clipboard2';

// 按需引入 vant
import { Button, NavBar, Tabbar, TabbarItem, Cell, CellGroup, Field, Icon, Toast, DatetimePicker, Popup, Picker, Loading, Dialog, Swipe, SwipeItem, Lazyload, Row, Col, Tab, Tabs, Grid, GridItem, Checkbox, CheckboxGroup, Uploader, Panel, Divider, Search, Image, Sidebar, SidebarItem, Rate, Sku, Stepper, AddressList, GoodsAction, GoodsActionIcon, GoodsActionButton, AddressEdit, List, Sticky, Skeleton, Area, Tag, Step, Steps, SwitchCell, Card, SubmitBar, ActionSheet, Switch, Form, DropdownMenu, DropdownItem, Radio, RadioGroup, Collapse, CollapseItem, Overlay, IndexBar, IndexAnchor, PullRefresh, Popover, NoticeBar, Empty ,Badge,ImagePreview} from 'vant';
Vue.use(Button).use(NavBar).use(Tabbar).use(TabbarItem).use(Cell).use(CellGroup).use(Field).use(Icon).use(Toast).use(DatetimePicker).use(Popup).use(Picker).use(Loading).use(Dialog).use(Swipe).use(SwipeItem).use(Lazyload).use(Row).use(Col).use(Tab).use(Tabs).use(Grid).use(GridItem).use(Checkbox).use(CheckboxGroup).use(Uploader).use(Panel).use(Divider).use(Search).use(Image).use(Sidebar).use(SidebarItem).use(Rate).use(Sku).use(Stepper).use(AddressList).use(GoodsAction).use(GoodsActionIcon).use(GoodsActionButton).use(AddressEdit).use(List).use(Sticky).use(Skeleton).use(Area).use(Tag).use(Step).use(Steps).use(SwitchCell).use(Card).use(SubmitBar).use(ActionSheet).use(Switch).use(Form).use(DropdownMenu).use(DropdownItem).use(Radio).use(RadioGroup).use(Collapse).use(CollapseItem).use(Overlay).use(IndexBar).use(IndexAnchor).use(PullRefresh).use(Popover).use(NoticeBar).use(Empty).use(Badge).use(VueClipBoard).use(VueClipBoard);

Vue.prototype.$API = API;
Vue.prototype.$HTTP = HTTP;
Vue.prototype.$utils = utils;
Vue.prototype.$axios = axios;
Vue.prototype.$dialog = Dialog;
Vue.prototype.$toast = Toast;
Vue.prototype.goPage = function (page) {
  router.push({ path: '/' + page })
}
Vue.prototype.goBack = function () {
  router.go(-1);
}

axios.defaults.transformResponse = [function (data) {
  try {
    // 作用1：把json字符串转为js对象
    // 作用2：把里面的大数字做安全处理
    return JSONbig.parse(data)
  } catch (e) {
    return data
  }
}]
// axios.interceptors.response.use(res=>{
//   if(res.data.res_code=== 401){
//       router.replace('/login');
//       localStorage.removeItem('token')
//   }
//   return res
// })
//跟新token axios响应拦截器
axios.interceptors.response.use(response => {
  // console.log('response1',response);
  return response
}, error => {
  if (error.response && error.response.data.statusCode === 403 && store.state.common.loginInfo.refresh_token) {
    // token失败刷新更新token
    // location.reload()
    // console.log('error:',error.response);
    store.dispatch('common/resetToken')
  }
  return error
})

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
});
