const state={
   
    // 六种玩法的大底 不修改这
    fourfixed:[
        '0000','0001','0002','0003','0004','0005','0006','0007','0008','0009','0010','0011','0012','0013','0014','0015','0016','0017','0018','0019','0020','0021','0022','0023','0024','0025','0026','0027','0028','0029','0030','0031','0032','0033','0034','0035','0036','0037','0038','0039','0040','0041','0042','0043','0044','0045','0046','0047','0048','0049','0050','0051','0052','0053','0054','0055','0056','0057','0058','0059','0060','0061','0062','0063','0064','0065','0066','0067','0068','0069','0070','0071','0072','0073','0074','0075','0076','0077','0078','0079','0080','0081','0082','0083','0084','0085','0086','0087','0088','0089','0090','0091','0092','0093','0094','0095','0096','0097','0098','0099',
        '0100','0101','0102','0103','0104','0105','0106','0107','0108','0109','0110','0111','0112','0113','0114','0115','0116','0117','0118','0119','0120','0121','0122','0123','0124','0125','0126','0127','0128','0129','0130','0131','0132','0133','0134','0135','0136','0137','0138','0139','0140','0141','0142','0143','0144','0145','0146','0147','0148','0149','0150','0151','0152','0153','0154','0155','0156','0157','0158','0159','0160','0161','0162','0163','0164','0165','0166','0167','0168','0169','0170','0171','0172','0173','0174','0175','0176','0177','0178','0179','0180','0181','0182','0183','0184','0185','0186','0187','0188','0189','0190','0191','0192','0193','0194','0195','0196','0197','0198','0199',
        '0200','0201','0202','0203','0204','0205','0206','0207','0208','0209','0210','0211','0212','0213','0214','0215','0216','0217','0218','0219','0220','0221','0222','0223','0224','0225','0226','0227','0228','0229','0230','0231','0232','0233','0234','0235','0236','0237','0238','0239','0240','0241','0242','0243','0244','0245','0246','0247','0248','0249','0250','0251','0252','0253','0254','0255','0256','0257','0258','0259','0260','0261','0262','0263','0264','0265','0266','0267','0268','0269','0270','0271','0272','0273','0274','0275','0276','0277','0278','0279','0280','0281','0282','0283','0284','0285','0286','0287','0288','0289','0290','0291','0292','0293','0294','0295','0296','0297','0298','0299',
        '0300','0301','0302','0303','0304','0305','0306','0307','0308','0309','0310','0311','0312','0313','0314','0315','0316','0317','0318','0319','0320','0321','0322','0323','0324','0325','0326','0327','0328','0329','0330','0331','0332','0333','0334','0335','0336','0337','0338','0339','0340','0341','0342','0343','0344','0345','0346','0347','0348','0349','0350','0351','0352','0353','0354','0355','0356','0357','0358','0359','0360','0361','0362','0363','0364','0365','0366','0367','0368','0369','0370','0371','0372','0373','0374','0375','0376','0377','0378','0379','0380','0381','0382','0383','0384','0385','0386','0387','0388','0389','0390','0391','0392','0393','0394','0395','0396','0397','0398','0399',
        '0400','0401','0402','0403','0404','0405','0406','0407','0408','0409','0410','0411','0412','0413','0414','0415','0416','0417','0418','0419','0420','0421','0422','0423','0424','0425','0426','0427','0428','0429','0430','0431','0432','0433','0434','0435','0436','0437','0438','0439','0440','0441','0442','0443','0444','0445','0446','0447','0448','0449','0450','0451','0452','0453','0454','0455','0456','0457','0458','0459','0460','0461','0462','0463','0464','0465','0466','0467','0468','0469','0470','0471','0472','0473','0474','0475','0476','0477','0478','0479','0480','0481','0482','0483','0484','0485','0486','0487','0488','0489','0490','0491','0492','0493','0494','0495','0496','0497','0498','0499',
        '0500','0501','0502','0503','0504','0505','0506','0507','0508','0509','0510','0511','0512','0513','0514','0515','0516','0517','0518','0519','0520','0521','0522','0523','0524','0525','0526','0527','0528','0529','0530','0531','0532','0533','0534','0535','0536','0537','0538','0539','0540','0541','0542','0543','0544','0545','0546','0547','0548','0549','0550','0551','0552','0553','0554','0555','0556','0557','0558','0559','0560','0561','0562','0563','0564','0565','0566','0567','0568','0569','0570','0571','0572','0573','0574','0575','0576','0577','0578','0579','0580','0581','0582','0583','0584','0585','0586','0587','0588','0589','0590','0591','0592','0593','0594','0595','0596','0597','0598','0599',
        '0600','0601','0602','0603','0604','0605','0606','0607','0608','0609','0610','0611','0612','0613','0614','0615','0616','0617','0618','0619','0620','0621','0622','0623','0624','0625','0626','0627','0628','0629','0630','0631','0632','0633','0634','0635','0636','0637','0638','0639','0640','0641','0642','0643','0644','0645','0646','0647','0648','0649','0650','0651','0652','0653','0654','0655','0656','0657','0658','0659','0660','0661','0662','0663','0664','0665','0666','0667','0668','0669','0670','0671','0672','0673','0674','0675','0676','0677','0678','0679','0680','0681','0682','0683','0684','0685','0686','0687','0688','0689','0690','0691','0692','0693','0694','0695','0696','0697','0698','0699',
        '0700','0701','0702','0703','0704','0705','0706','0707','0708','0709','0710','0711','0712','0713','0714','0715','0716','0717','0718','0719','0720','0721','0722','0723','0724','0725','0726','0727','0728','0729','0730','0731','0732','0733','0734','0735','0736','0737','0738','0739','0740','0741','0742','0743','0744','0745','0746','0747','0748','0749','0750','0751','0752','0753','0754','0755','0756','0757','0758','0759','0760','0761','0762','0763','0764','0765','0766','0767','0768','0769','0770','0771','0772','0773','0774','0775','0776','0777','0778','0779','0780','0781','0782','0783','0784','0785','0786','0787','0788','0789','0790','0791','0792','0793','0794','0795','0796','0797','0798','0799',
        '0800','0801','0802','0803','0804','0805','0806','0807','0808','0809','0810','0811','0812','0813','0814','0815','0816','0817','0818','0819','0820','0821','0822','0823','0824','0825','0826','0827','0828','0829','0830','0831','0832','0833','0834','0835','0836','0837','0838','0839','0840','0841','0842','0843','0844','0845','0846','0847','0848','0849','0850','0851','0852','0853','0854','0855','0856','0857','0858','0859','0860','0861','0862','0863','0864','0865','0866','0867','0868','0869','0870','0871','0872','0873','0874','0875','0876','0877','0878','0879','0880','0881','0882','0883','0884','0885','0886','0887','0888','0889','0890','0891','0892','0893','0894','0895','0896','0897','0898','0899',
        '0900','0901','0902','0903','0904','0905','0906','0907','0908','0909','0910','0911','0912','0913','0914','0915','0916','0917','0918','0919','0920','0921','0922','0923','0924','0925','0926','0927','0928','0929','0930','0931','0932','0933','0934','0935','0936','0937','0938','0939','0940','0941','0942','0943','0944','0945','0946','0947','0948','0949','0950','0951','0952','0953','0954','0955','0956','0957','0958','0959','0960','0961','0962','0963','0964','0965','0966','0967','0968','0969','0970','0971','0972','0973','0974','0975','0976','0977','0978','0979','0980','0981','0982','0983','0984','0985','0986','0987','0988','0989','0990','0991','0992','0993','0994','0995','0996','0997','0998','0999',
        '1000','1001','1002','1003','1004','1005','1006','1007','1008','1009','1010','1011','1012','1013','1014','1015','1016','1017','1018','1019','1020','1021','1022','1023','1024','1025','1026','1027','1028','1029','1030','1031','1032','1033','1034','1035','1036','1037','1038','1039','1040','1041','1042','1043','1044','1045','1046','1047','1048','1049','1050','1051','1052','1053','1054','1055','1056','1057','1058','1059','1060','1061','1062','1063','1064','1065','1066','1067','1068','1069','1070','1071','1072','1073','1074','1075','1076','1077','1078','1079','1080','1081','1082','1083','1084','1085','1086','1087','1088','1089','1090','1091','1092','1093','1094','1095','1096','1097','1098','1099',
        '1100','1101','1102','1103','1104','1105','1106','1107','1108','1109','1110','1111','1112','1113','1114','1115','1116','1117','1118','1119','1120','1121','1122','1123','1124','1125','1126','1127','1128','1129','1130','1131','1132','1133','1134','1135','1136','1137','1138','1139','1140','1141','1142','1143','1144','1145','1146','1147','1148','1149','1150','1151','1152','1153','1154','1155','1156','1157','1158','1159','1160','1161','1162','1163','1164','1165','1166','1167','1168','1169','1170','1171','1172','1173','1174','1175','1176','1177','1178','1179','1180','1181','1182','1183','1184','1185','1186','1187','1188','1189','1190','1191','1192','1193','1194','1195','1196','1197','1198','1199',
        '1200','1201','1202','1203','1204','1205','1206','1207','1208','1209','1210','1211','1212','1213','1214','1215','1216','1217','1218','1219','1220','1221','1222','1223','1224','1225','1226','1227','1228','1229','1230','1231','1232','1233','1234','1235','1236','1237','1238','1239','1240','1241','1242','1243','1244','1245','1246','1247','1248','1249','1250','1251','1252','1253','1254','1255','1256','1257','1258','1259','1260','1261','1262','1263','1264','1265','1266','1267','1268','1269','1270','1271','1272','1273','1274','1275','1276','1277','1278','1279','1280','1281','1282','1283','1284','1285','1286','1287','1288','1289','1290','1291','1292','1293','1294','1295','1296','1297','1298','1299',
        '1300','1301','1302','1303','1304','1305','1306','1307','1308','1309','1310','1311','1312','1313','1314','1315','1316','1317','1318','1319','1320','1321','1322','1323','1324','1325','1326','1327','1328','1329','1330','1331','1332','1333','1334','1335','1336','1337','1338','1339','1340','1341','1342','1343','1344','1345','1346','1347','1348','1349','1350','1351','1352','1353','1354','1355','1356','1357','1358','1359','1360','1361','1362','1363','1364','1365','1366','1367','1368','1369','1370','1371','1372','1373','1374','1375','1376','1377','1378','1379','1380','1381','1382','1383','1384','1385','1386','1387','1388','1389','1390','1391','1392','1393','1394','1395','1396','1397','1398','1399',
        '1400','1401','1402','1403','1404','1405','1406','1407','1408','1409','1410','1411','1412','1413','1414','1415','1416','1417','1418','1419','1420','1421','1422','1423','1424','1425','1426','1427','1428','1429','1430','1431','1432','1433','1434','1435','1436','1437','1438','1439','1440','1441','1442','1443','1444','1445','1446','1447','1448','1449','1450','1451','1452','1453','1454','1455','1456','1457','1458','1459','1460','1461','1462','1463','1464','1465','1466','1467','1468','1469','1470','1471','1472','1473','1474','1475','1476','1477','1478','1479','1480','1481','1482','1483','1484','1485','1486','1487','1488','1489','1490','1491','1492','1493','1494','1495','1496','1497','1498','1499',
        '1500','1501','1502','1503','1504','1505','1506','1507','1508','1509','1510','1511','1512','1513','1514','1515','1516','1517','1518','1519','1520','1521','1522','1523','1524','1525','1526','1527','1528','1529','1530','1531','1532','1533','1534','1535','1536','1537','1538','1539','1540','1541','1542','1543','1544','1545','1546','1547','1548','1549','1550','1551','1552','1553','1554','1555','1556','1557','1558','1559','1560','1561','1562','1563','1564','1565','1566','1567','1568','1569','1570','1571','1572','1573','1574','1575','1576','1577','1578','1579','1580','1581','1582','1583','1584','1585','1586','1587','1588','1589','1590','1591','1592','1593','1594','1595','1596','1597','1598','1599',
        '1600','1601','1602','1603','1604','1605','1606','1607','1608','1609','1610','1611','1612','1613','1614','1615','1616','1617','1618','1619','1620','1621','1622','1623','1624','1625','1626','1627','1628','1629','1630','1631','1632','1633','1634','1635','1636','1637','1638','1639','1640','1641','1642','1643','1644','1645','1646','1647','1648','1649','1650','1651','1652','1653','1654','1655','1656','1657','1658','1659','1660','1661','1662','1663','1664','1665','1666','1667','1668','1669','1670','1671','1672','1673','1674','1675','1676','1677','1678','1679','1680','1681','1682','1683','1684','1685','1686','1687','1688','1689','1690','1691','1692','1693','1694','1695','1696','1697','1698','1699',
        '1700','1701','1702','1703','1704','1705','1706','1707','1708','1709','1710','1711','1712','1713','1714','1715','1716','1717','1718','1719','1720','1721','1722','1723','1724','1725','1726','1727','1728','1729','1730','1731','1732','1733','1734','1735','1736','1737','1738','1739','1740','1741','1742','1743','1744','1745','1746','1747','1748','1749','1750','1751','1752','1753','1754','1755','1756','1757','1758','1759','1760','1761','1762','1763','1764','1765','1766','1767','1768','1769','1770','1771','1772','1773','1774','1775','1776','1777','1778','1779','1780','1781','1782','1783','1784','1785','1786','1787','1788','1789','1790','1791','1792','1793','1794','1795','1796','1797','1798','1799',
        '1800','1801','1802','1803','1804','1805','1806','1807','1808','1809','1810','1811','1812','1813','1814','1815','1816','1817','1818','1819','1820','1821','1822','1823','1824','1825','1826','1827','1828','1829','1830','1831','1832','1833','1834','1835','1836','1837','1838','1839','1840','1841','1842','1843','1844','1845','1846','1847','1848','1849','1850','1851','1852','1853','1854','1855','1856','1857','1858','1859','1860','1861','1862','1863','1864','1865','1866','1867','1868','1869','1870','1871','1872','1873','1874','1875','1876','1877','1878','1879','1880','1881','1882','1883','1884','1885','1886','1887','1888','1889','1890','1891','1892','1893','1894','1895','1896','1897','1898','1899',
        '1900','1901','1902','1903','1904','1905','1906','1907','1908','1909','1910','1911','1912','1913','1914','1915','1916','1917','1918','1919','1920','1921','1922','1923','1924','1925','1926','1927','1928','1929','1930','1931','1932','1933','1934','1935','1936','1937','1938','1939','1940','1941','1942','1943','1944','1945','1946','1947','1948','1949','1950','1951','1952','1953','1954','1955','1956','1957','1958','1959','1960','1961','1962','1963','1964','1965','1966','1967','1968','1969','1970','1971','1972','1973','1974','1975','1976','1977','1978','1979','1980','1981','1982','1983','1984','1985','1986','1987','1988','1989','1990','1991','1992','1993','1994','1995','1996','1997','1998','1999',
        '2000','2001','2002','2003','2004','2005','2006','2007','2008','2009','2010','2011','2012','2013','2014','2015','2016','2017','2018','2019','2020','2021','2022','2023','2024','2025','2026','2027','2028','2029','2030','2031','2032','2033','2034','2035','2036','2037','2038','2039','2040','2041','2042','2043','2044','2045','2046','2047','2048','2049','2050','2051','2052','2053','2054','2055','2056','2057','2058','2059','2060','2061','2062','2063','2064','2065','2066','2067','2068','2069','2070','2071','2072','2073','2074','2075','2076','2077','2078','2079','2080','2081','2082','2083','2084','2085','2086','2087','2088','2089','2090','2091','2092','2093','2094','2095','2096','2097','2098','2099',
        '2100','2101','2102','2103','2104','2105','2106','2107','2108','2109','2110','2111','2112','2113','2114','2115','2116','2117','2118','2119','2120','2121','2122','2123','2124','2125','2126','2127','2128','2129','2130','2131','2132','2133','2134','2135','2136','2137','2138','2139','2140','2141','2142','2143','2144','2145','2146','2147','2148','2149','2150','2151','2152','2153','2154','2155','2156','2157','2158','2159','2160','2161','2162','2163','2164','2165','2166','2167','2168','2169','2170','2171','2172','2173','2174','2175','2176','2177','2178','2179','2180','2181','2182','2183','2184','2185','2186','2187','2188','2189','2190','2191','2192','2193','2194','2195','2196','2197','2198','2199',
        '2200','2201','2202','2203','2204','2205','2206','2207','2208','2209','2210','2211','2212','2213','2214','2215','2216','2217','2218','2219','2220','2221','2222','2223','2224','2225','2226','2227','2228','2229','2230','2231','2232','2233','2234','2235','2236','2237','2238','2239','2240','2241','2242','2243','2244','2245','2246','2247','2248','2249','2250','2251','2252','2253','2254','2255','2256','2257','2258','2259','2260','2261','2262','2263','2264','2265','2266','2267','2268','2269','2270','2271','2272','2273','2274','2275','2276','2277','2278','2279','2280','2281','2282','2283','2284','2285','2286','2287','2288','2289','2290','2291','2292','2293','2294','2295','2296','2297','2298','2299',
        '2300','2301','2302','2303','2304','2305','2306','2307','2308','2309','2310','2311','2312','2313','2314','2315','2316','2317','2318','2319','2320','2321','2322','2323','2324','2325','2326','2327','2328','2329','2330','2331','2332','2333','2334','2335','2336','2337','2338','2339','2340','2341','2342','2343','2344','2345','2346','2347','2348','2349','2350','2351','2352','2353','2354','2355','2356','2357','2358','2359','2360','2361','2362','2363','2364','2365','2366','2367','2368','2369','2370','2371','2372','2373','2374','2375','2376','2377','2378','2379','2380','2381','2382','2383','2384','2385','2386','2387','2388','2389','2390','2391','2392','2393','2394','2395','2396','2397','2398','2399',
        '2400','2401','2402','2403','2404','2405','2406','2407','2408','2409','2410','2411','2412','2413','2414','2415','2416','2417','2418','2419','2420','2421','2422','2423','2424','2425','2426','2427','2428','2429','2430','2431','2432','2433','2434','2435','2436','2437','2438','2439','2440','2441','2442','2443','2444','2445','2446','2447','2448','2449','2450','2451','2452','2453','2454','2455','2456','2457','2458','2459','2460','2461','2462','2463','2464','2465','2466','2467','2468','2469','2470','2471','2472','2473','2474','2475','2476','2477','2478','2479','2480','2481','2482','2483','2484','2485','2486','2487','2488','2489','2490','2491','2492','2493','2494','2495','2496','2497','2498','2499',
        '2500','2501','2502','2503','2504','2505','2506','2507','2508','2509','2510','2511','2512','2513','2514','2515','2516','2517','2518','2519','2520','2521','2522','2523','2524','2525','2526','2527','2528','2529','2530','2531','2532','2533','2534','2535','2536','2537','2538','2539','2540','2541','2542','2543','2544','2545','2546','2547','2548','2549','2550','2551','2552','2553','2554','2555','2556','2557','2558','2559','2560','2561','2562','2563','2564','2565','2566','2567','2568','2569','2570','2571','2572','2573','2574','2575','2576','2577','2578','2579','2580','2581','2582','2583','2584','2585','2586','2587','2588','2589','2590','2591','2592','2593','2594','2595','2596','2597','2598','2599',
        '2600','2601','2602','2603','2604','2605','2606','2607','2608','2609','2610','2611','2612','2613','2614','2615','2616','2617','2618','2619','2620','2621','2622','2623','2624','2625','2626','2627','2628','2629','2630','2631','2632','2633','2634','2635','2636','2637','2638','2639','2640','2641','2642','2643','2644','2645','2646','2647','2648','2649','2650','2651','2652','2653','2654','2655','2656','2657','2658','2659','2660','2661','2662','2663','2664','2665','2666','2667','2668','2669','2670','2671','2672','2673','2674','2675','2676','2677','2678','2679','2680','2681','2682','2683','2684','2685','2686','2687','2688','2689','2690','2691','2692','2693','2694','2695','2696','2697','2698','2699',
        '2700','2701','2702','2703','2704','2705','2706','2707','2708','2709','2710','2711','2712','2713','2714','2715','2716','2717','2718','2719','2720','2721','2722','2723','2724','2725','2726','2727','2728','2729','2730','2731','2732','2733','2734','2735','2736','2737','2738','2739','2740','2741','2742','2743','2744','2745','2746','2747','2748','2749','2750','2751','2752','2753','2754','2755','2756','2757','2758','2759','2760','2761','2762','2763','2764','2765','2766','2767','2768','2769','2770','2771','2772','2773','2774','2775','2776','2777','2778','2779','2780','2781','2782','2783','2784','2785','2786','2787','2788','2789','2790','2791','2792','2793','2794','2795','2796','2797','2798','2799',
        '2800','2801','2802','2803','2804','2805','2806','2807','2808','2809','2810','2811','2812','2813','2814','2815','2816','2817','2818','2819','2820','2821','2822','2823','2824','2825','2826','2827','2828','2829','2830','2831','2832','2833','2834','2835','2836','2837','2838','2839','2840','2841','2842','2843','2844','2845','2846','2847','2848','2849','2850','2851','2852','2853','2854','2855','2856','2857','2858','2859','2860','2861','2862','2863','2864','2865','2866','2867','2868','2869','2870','2871','2872','2873','2874','2875','2876','2877','2878','2879','2880','2881','2882','2883','2884','2885','2886','2887','2888','2889','2890','2891','2892','2893','2894','2895','2896','2897','2898','2899',
        '2900','2901','2902','2903','2904','2905','2906','2907','2908','2909','2910','2911','2912','2913','2914','2915','2916','2917','2918','2919','2920','2921','2922','2923','2924','2925','2926','2927','2928','2929','2930','2931','2932','2933','2934','2935','2936','2937','2938','2939','2940','2941','2942','2943','2944','2945','2946','2947','2948','2949','2950','2951','2952','2953','2954','2955','2956','2957','2958','2959','2960','2961','2962','2963','2964','2965','2966','2967','2968','2969','2970','2971','2972','2973','2974','2975','2976','2977','2978','2979','2980','2981','2982','2983','2984','2985','2986','2987','2988','2989','2990','2991','2992','2993','2994','2995','2996','2997','2998','2999',
        '3000','3001','3002','3003','3004','3005','3006','3007','3008','3009','3010','3011','3012','3013','3014','3015','3016','3017','3018','3019','3020','3021','3022','3023','3024','3025','3026','3027','3028','3029','3030','3031','3032','3033','3034','3035','3036','3037','3038','3039','3040','3041','3042','3043','3044','3045','3046','3047','3048','3049','3050','3051','3052','3053','3054','3055','3056','3057','3058','3059','3060','3061','3062','3063','3064','3065','3066','3067','3068','3069','3070','3071','3072','3073','3074','3075','3076','3077','3078','3079','3080','3081','3082','3083','3084','3085','3086','3087','3088','3089','3090','3091','3092','3093','3094','3095','3096','3097','3098','3099',
        '3100','3101','3102','3103','3104','3105','3106','3107','3108','3109','3110','3111','3112','3113','3114','3115','3116','3117','3118','3119','3120','3121','3122','3123','3124','3125','3126','3127','3128','3129','3130','3131','3132','3133','3134','3135','3136','3137','3138','3139','3140','3141','3142','3143','3144','3145','3146','3147','3148','3149','3150','3151','3152','3153','3154','3155','3156','3157','3158','3159','3160','3161','3162','3163','3164','3165','3166','3167','3168','3169','3170','3171','3172','3173','3174','3175','3176','3177','3178','3179','3180','3181','3182','3183','3184','3185','3186','3187','3188','3189','3190','3191','3192','3193','3194','3195','3196','3197','3198','3199',
        '3200','3201','3202','3203','3204','3205','3206','3207','3208','3209','3210','3211','3212','3213','3214','3215','3216','3217','3218','3219','3220','3221','3222','3223','3224','3225','3226','3227','3228','3229','3230','3231','3232','3233','3234','3235','3236','3237','3238','3239','3240','3241','3242','3243','3244','3245','3246','3247','3248','3249','3250','3251','3252','3253','3254','3255','3256','3257','3258','3259','3260','3261','3262','3263','3264','3265','3266','3267','3268','3269','3270','3271','3272','3273','3274','3275','3276','3277','3278','3279','3280','3281','3282','3283','3284','3285','3286','3287','3288','3289','3290','3291','3292','3293','3294','3295','3296','3297','3298','3299',
        '3300','3301','3302','3303','3304','3305','3306','3307','3308','3309','3310','3311','3312','3313','3314','3315','3316','3317','3318','3319','3320','3321','3322','3323','3324','3325','3326','3327','3328','3329','3330','3331','3332','3333','3334','3335','3336','3337','3338','3339','3340','3341','3342','3343','3344','3345','3346','3347','3348','3349','3350','3351','3352','3353','3354','3355','3356','3357','3358','3359','3360','3361','3362','3363','3364','3365','3366','3367','3368','3369','3370','3371','3372','3373','3374','3375','3376','3377','3378','3379','3380','3381','3382','3383','3384','3385','3386','3387','3388','3389','3390','3391','3392','3393','3394','3395','3396','3397','3398','3399',
        '3400','3401','3402','3403','3404','3405','3406','3407','3408','3409','3410','3411','3412','3413','3414','3415','3416','3417','3418','3419','3420','3421','3422','3423','3424','3425','3426','3427','3428','3429','3430','3431','3432','3433','3434','3435','3436','3437','3438','3439','3440','3441','3442','3443','3444','3445','3446','3447','3448','3449','3450','3451','3452','3453','3454','3455','3456','3457','3458','3459','3460','3461','3462','3463','3464','3465','3466','3467','3468','3469','3470','3471','3472','3473','3474','3475','3476','3477','3478','3479','3480','3481','3482','3483','3484','3485','3486','3487','3488','3489','3490','3491','3492','3493','3494','3495','3496','3497','3498','3499',
        '3500','3501','3502','3503','3504','3505','3506','3507','3508','3509','3510','3511','3512','3513','3514','3515','3516','3517','3518','3519','3520','3521','3522','3523','3524','3525','3526','3527','3528','3529','3530','3531','3532','3533','3534','3535','3536','3537','3538','3539','3540','3541','3542','3543','3544','3545','3546','3547','3548','3549','3550','3551','3552','3553','3554','3555','3556','3557','3558','3559','3560','3561','3562','3563','3564','3565','3566','3567','3568','3569','3570','3571','3572','3573','3574','3575','3576','3577','3578','3579','3580','3581','3582','3583','3584','3585','3586','3587','3588','3589','3590','3591','3592','3593','3594','3595','3596','3597','3598','3599',
        '3600','3601','3602','3603','3604','3605','3606','3607','3608','3609','3610','3611','3612','3613','3614','3615','3616','3617','3618','3619','3620','3621','3622','3623','3624','3625','3626','3627','3628','3629','3630','3631','3632','3633','3634','3635','3636','3637','3638','3639','3640','3641','3642','3643','3644','3645','3646','3647','3648','3649','3650','3651','3652','3653','3654','3655','3656','3657','3658','3659','3660','3661','3662','3663','3664','3665','3666','3667','3668','3669','3670','3671','3672','3673','3674','3675','3676','3677','3678','3679','3680','3681','3682','3683','3684','3685','3686','3687','3688','3689','3690','3691','3692','3693','3694','3695','3696','3697','3698','3699',
        '3700','3701','3702','3703','3704','3705','3706','3707','3708','3709','3710','3711','3712','3713','3714','3715','3716','3717','3718','3719','3720','3721','3722','3723','3724','3725','3726','3727','3728','3729','3730','3731','3732','3733','3734','3735','3736','3737','3738','3739','3740','3741','3742','3743','3744','3745','3746','3747','3748','3749','3750','3751','3752','3753','3754','3755','3756','3757','3758','3759','3760','3761','3762','3763','3764','3765','3766','3767','3768','3769','3770','3771','3772','3773','3774','3775','3776','3777','3778','3779','3780','3781','3782','3783','3784','3785','3786','3787','3788','3789','3790','3791','3792','3793','3794','3795','3796','3797','3798','3799',
        '3800','3801','3802','3803','3804','3805','3806','3807','3808','3809','3810','3811','3812','3813','3814','3815','3816','3817','3818','3819','3820','3821','3822','3823','3824','3825','3826','3827','3828','3829','3830','3831','3832','3833','3834','3835','3836','3837','3838','3839','3840','3841','3842','3843','3844','3845','3846','3847','3848','3849','3850','3851','3852','3853','3854','3855','3856','3857','3858','3859','3860','3861','3862','3863','3864','3865','3866','3867','3868','3869','3870','3871','3872','3873','3874','3875','3876','3877','3878','3879','3880','3881','3882','3883','3884','3885','3886','3887','3888','3889','3890','3891','3892','3893','3894','3895','3896','3897','3898','3899',
        '3900','3901','3902','3903','3904','3905','3906','3907','3908','3909','3910','3911','3912','3913','3914','3915','3916','3917','3918','3919','3920','3921','3922','3923','3924','3925','3926','3927','3928','3929','3930','3931','3932','3933','3934','3935','3936','3937','3938','3939','3940','3941','3942','3943','3944','3945','3946','3947','3948','3949','3950','3951','3952','3953','3954','3955','3956','3957','3958','3959','3960','3961','3962','3963','3964','3965','3966','3967','3968','3969','3970','3971','3972','3973','3974','3975','3976','3977','3978','3979','3980','3981','3982','3983','3984','3985','3986','3987','3988','3989','3990','3991','3992','3993','3994','3995','3996','3997','3998','3999',
        '4000','4001','4002','4003','4004','4005','4006','4007','4008','4009','4010','4011','4012','4013','4014','4015','4016','4017','4018','4019','4020','4021','4022','4023','4024','4025','4026','4027','4028','4029','4030','4031','4032','4033','4034','4035','4036','4037','4038','4039','4040','4041','4042','4043','4044','4045','4046','4047','4048','4049','4050','4051','4052','4053','4054','4055','4056','4057','4058','4059','4060','4061','4062','4063','4064','4065','4066','4067','4068','4069','4070','4071','4072','4073','4074','4075','4076','4077','4078','4079','4080','4081','4082','4083','4084','4085','4086','4087','4088','4089','4090','4091','4092','4093','4094','4095','4096','4097','4098','4099',
        '4100','4101','4102','4103','4104','4105','4106','4107','4108','4109','4110','4111','4112','4113','4114','4115','4116','4117','4118','4119','4120','4121','4122','4123','4124','4125','4126','4127','4128','4129','4130','4131','4132','4133','4134','4135','4136','4137','4138','4139','4140','4141','4142','4143','4144','4145','4146','4147','4148','4149','4150','4151','4152','4153','4154','4155','4156','4157','4158','4159','4160','4161','4162','4163','4164','4165','4166','4167','4168','4169','4170','4171','4172','4173','4174','4175','4176','4177','4178','4179','4180','4181','4182','4183','4184','4185','4186','4187','4188','4189','4190','4191','4192','4193','4194','4195','4196','4197','4198','4199',
        '4200','4201','4202','4203','4204','4205','4206','4207','4208','4209','4210','4211','4212','4213','4214','4215','4216','4217','4218','4219','4220','4221','4222','4223','4224','4225','4226','4227','4228','4229','4230','4231','4232','4233','4234','4235','4236','4237','4238','4239','4240','4241','4242','4243','4244','4245','4246','4247','4248','4249','4250','4251','4252','4253','4254','4255','4256','4257','4258','4259','4260','4261','4262','4263','4264','4265','4266','4267','4268','4269','4270','4271','4272','4273','4274','4275','4276','4277','4278','4279','4280','4281','4282','4283','4284','4285','4286','4287','4288','4289','4290','4291','4292','4293','4294','4295','4296','4297','4298','4299',
        '4300','4301','4302','4303','4304','4305','4306','4307','4308','4309','4310','4311','4312','4313','4314','4315','4316','4317','4318','4319','4320','4321','4322','4323','4324','4325','4326','4327','4328','4329','4330','4331','4332','4333','4334','4335','4336','4337','4338','4339','4340','4341','4342','4343','4344','4345','4346','4347','4348','4349','4350','4351','4352','4353','4354','4355','4356','4357','4358','4359','4360','4361','4362','4363','4364','4365','4366','4367','4368','4369','4370','4371','4372','4373','4374','4375','4376','4377','4378','4379','4380','4381','4382','4383','4384','4385','4386','4387','4388','4389','4390','4391','4392','4393','4394','4395','4396','4397','4398','4399',
        '4400','4401','4402','4403','4404','4405','4406','4407','4408','4409','4410','4411','4412','4413','4414','4415','4416','4417','4418','4419','4420','4421','4422','4423','4424','4425','4426','4427','4428','4429','4430','4431','4432','4433','4434','4435','4436','4437','4438','4439','4440','4441','4442','4443','4444','4445','4446','4447','4448','4449','4450','4451','4452','4453','4454','4455','4456','4457','4458','4459','4460','4461','4462','4463','4464','4465','4466','4467','4468','4469','4470','4471','4472','4473','4474','4475','4476','4477','4478','4479','4480','4481','4482','4483','4484','4485','4486','4487','4488','4489','4490','4491','4492','4493','4494','4495','4496','4497','4498','4499',
        '4500','4501','4502','4503','4504','4505','4506','4507','4508','4509','4510','4511','4512','4513','4514','4515','4516','4517','4518','4519','4520','4521','4522','4523','4524','4525','4526','4527','4528','4529','4530','4531','4532','4533','4534','4535','4536','4537','4538','4539','4540','4541','4542','4543','4544','4545','4546','4547','4548','4549','4550','4551','4552','4553','4554','4555','4556','4557','4558','4559','4560','4561','4562','4563','4564','4565','4566','4567','4568','4569','4570','4571','4572','4573','4574','4575','4576','4577','4578','4579','4580','4581','4582','4583','4584','4585','4586','4587','4588','4589','4590','4591','4592','4593','4594','4595','4596','4597','4598','4599',
        '4600','4601','4602','4603','4604','4605','4606','4607','4608','4609','4610','4611','4612','4613','4614','4615','4616','4617','4618','4619','4620','4621','4622','4623','4624','4625','4626','4627','4628','4629','4630','4631','4632','4633','4634','4635','4636','4637','4638','4639','4640','4641','4642','4643','4644','4645','4646','4647','4648','4649','4650','4651','4652','4653','4654','4655','4656','4657','4658','4659','4660','4661','4662','4663','4664','4665','4666','4667','4668','4669','4670','4671','4672','4673','4674','4675','4676','4677','4678','4679','4680','4681','4682','4683','4684','4685','4686','4687','4688','4689','4690','4691','4692','4693','4694','4695','4696','4697','4698','4699',
        '4700','4701','4702','4703','4704','4705','4706','4707','4708','4709','4710','4711','4712','4713','4714','4715','4716','4717','4718','4719','4720','4721','4722','4723','4724','4725','4726','4727','4728','4729','4730','4731','4732','4733','4734','4735','4736','4737','4738','4739','4740','4741','4742','4743','4744','4745','4746','4747','4748','4749','4750','4751','4752','4753','4754','4755','4756','4757','4758','4759','4760','4761','4762','4763','4764','4765','4766','4767','4768','4769','4770','4771','4772','4773','4774','4775','4776','4777','4778','4779','4780','4781','4782','4783','4784','4785','4786','4787','4788','4789','4790','4791','4792','4793','4794','4795','4796','4797','4798','4799',
        '4800','4801','4802','4803','4804','4805','4806','4807','4808','4809','4810','4811','4812','4813','4814','4815','4816','4817','4818','4819','4820','4821','4822','4823','4824','4825','4826','4827','4828','4829','4830','4831','4832','4833','4834','4835','4836','4837','4838','4839','4840','4841','4842','4843','4844','4845','4846','4847','4848','4849','4850','4851','4852','4853','4854','4855','4856','4857','4858','4859','4860','4861','4862','4863','4864','4865','4866','4867','4868','4869','4870','4871','4872','4873','4874','4875','4876','4877','4878','4879','4880','4881','4882','4883','4884','4885','4886','4887','4888','4889','4890','4891','4892','4893','4894','4895','4896','4897','4898','4899',
        '4900','4901','4902','4903','4904','4905','4906','4907','4908','4909','4910','4911','4912','4913','4914','4915','4916','4917','4918','4919','4920','4921','4922','4923','4924','4925','4926','4927','4928','4929','4930','4931','4932','4933','4934','4935','4936','4937','4938','4939','4940','4941','4942','4943','4944','4945','4946','4947','4948','4949','4950','4951','4952','4953','4954','4955','4956','4957','4958','4959','4960','4961','4962','4963','4964','4965','4966','4967','4968','4969','4970','4971','4972','4973','4974','4975','4976','4977','4978','4979','4980','4981','4982','4983','4984','4985','4986','4987','4988','4989','4990','4991','4992','4993','4994','4995','4996','4997','4998','4999',
        '5000','5001','5002','5003','5004','5005','5006','5007','5008','5009','5010','5011','5012','5013','5014','5015','5016','5017','5018','5019','5020','5021','5022','5023','5024','5025','5026','5027','5028','5029','5030','5031','5032','5033','5034','5035','5036','5037','5038','5039','5040','5041','5042','5043','5044','5045','5046','5047','5048','5049','5050','5051','5052','5053','5054','5055','5056','5057','5058','5059','5060','5061','5062','5063','5064','5065','5066','5067','5068','5069','5070','5071','5072','5073','5074','5075','5076','5077','5078','5079','5080','5081','5082','5083','5084','5085','5086','5087','5088','5089','5090','5091','5092','5093','5094','5095','5096','5097','5098','5099',
        '5100','5101','5102','5103','5104','5105','5106','5107','5108','5109','5110','5111','5112','5113','5114','5115','5116','5117','5118','5119','5120','5121','5122','5123','5124','5125','5126','5127','5128','5129','5130','5131','5132','5133','5134','5135','5136','5137','5138','5139','5140','5141','5142','5143','5144','5145','5146','5147','5148','5149','5150','5151','5152','5153','5154','5155','5156','5157','5158','5159','5160','5161','5162','5163','5164','5165','5166','5167','5168','5169','5170','5171','5172','5173','5174','5175','5176','5177','5178','5179','5180','5181','5182','5183','5184','5185','5186','5187','5188','5189','5190','5191','5192','5193','5194','5195','5196','5197','5198','5199',
        '5200','5201','5202','5203','5204','5205','5206','5207','5208','5209','5210','5211','5212','5213','5214','5215','5216','5217','5218','5219','5220','5221','5222','5223','5224','5225','5226','5227','5228','5229','5230','5231','5232','5233','5234','5235','5236','5237','5238','5239','5240','5241','5242','5243','5244','5245','5246','5247','5248','5249','5250','5251','5252','5253','5254','5255','5256','5257','5258','5259','5260','5261','5262','5263','5264','5265','5266','5267','5268','5269','5270','5271','5272','5273','5274','5275','5276','5277','5278','5279','5280','5281','5282','5283','5284','5285','5286','5287','5288','5289','5290','5291','5292','5293','5294','5295','5296','5297','5298','5299',
        '5300','5301','5302','5303','5304','5305','5306','5307','5308','5309','5310','5311','5312','5313','5314','5315','5316','5317','5318','5319','5320','5321','5322','5323','5324','5325','5326','5327','5328','5329','5330','5331','5332','5333','5334','5335','5336','5337','5338','5339','5340','5341','5342','5343','5344','5345','5346','5347','5348','5349','5350','5351','5352','5353','5354','5355','5356','5357','5358','5359','5360','5361','5362','5363','5364','5365','5366','5367','5368','5369','5370','5371','5372','5373','5374','5375','5376','5377','5378','5379','5380','5381','5382','5383','5384','5385','5386','5387','5388','5389','5390','5391','5392','5393','5394','5395','5396','5397','5398','5399',
        '5400','5401','5402','5403','5404','5405','5406','5407','5408','5409','5410','5411','5412','5413','5414','5415','5416','5417','5418','5419','5420','5421','5422','5423','5424','5425','5426','5427','5428','5429','5430','5431','5432','5433','5434','5435','5436','5437','5438','5439','5440','5441','5442','5443','5444','5445','5446','5447','5448','5449','5450','5451','5452','5453','5454','5455','5456','5457','5458','5459','5460','5461','5462','5463','5464','5465','5466','5467','5468','5469','5470','5471','5472','5473','5474','5475','5476','5477','5478','5479','5480','5481','5482','5483','5484','5485','5486','5487','5488','5489','5490','5491','5492','5493','5494','5495','5496','5497','5498','5499',
        '5500','5501','5502','5503','5504','5505','5506','5507','5508','5509','5510','5511','5512','5513','5514','5515','5516','5517','5518','5519','5520','5521','5522','5523','5524','5525','5526','5527','5528','5529','5530','5531','5532','5533','5534','5535','5536','5537','5538','5539','5540','5541','5542','5543','5544','5545','5546','5547','5548','5549','5550','5551','5552','5553','5554','5555','5556','5557','5558','5559','5560','5561','5562','5563','5564','5565','5566','5567','5568','5569','5570','5571','5572','5573','5574','5575','5576','5577','5578','5579','5580','5581','5582','5583','5584','5585','5586','5587','5588','5589','5590','5591','5592','5593','5594','5595','5596','5597','5598','5599',
        '5600','5601','5602','5603','5604','5605','5606','5607','5608','5609','5610','5611','5612','5613','5614','5615','5616','5617','5618','5619','5620','5621','5622','5623','5624','5625','5626','5627','5628','5629','5630','5631','5632','5633','5634','5635','5636','5637','5638','5639','5640','5641','5642','5643','5644','5645','5646','5647','5648','5649','5650','5651','5652','5653','5654','5655','5656','5657','5658','5659','5660','5661','5662','5663','5664','5665','5666','5667','5668','5669','5670','5671','5672','5673','5674','5675','5676','5677','5678','5679','5680','5681','5682','5683','5684','5685','5686','5687','5688','5689','5690','5691','5692','5693','5694','5695','5696','5697','5698','5699',
        '5700','5701','5702','5703','5704','5705','5706','5707','5708','5709','5710','5711','5712','5713','5714','5715','5716','5717','5718','5719','5720','5721','5722','5723','5724','5725','5726','5727','5728','5729','5730','5731','5732','5733','5734','5735','5736','5737','5738','5739','5740','5741','5742','5743','5744','5745','5746','5747','5748','5749','5750','5751','5752','5753','5754','5755','5756','5757','5758','5759','5760','5761','5762','5763','5764','5765','5766','5767','5768','5769','5770','5771','5772','5773','5774','5775','5776','5777','5778','5779','5780','5781','5782','5783','5784','5785','5786','5787','5788','5789','5790','5791','5792','5793','5794','5795','5796','5797','5798','5799',
        '5800','5801','5802','5803','5804','5805','5806','5807','5808','5809','5810','5811','5812','5813','5814','5815','5816','5817','5818','5819','5820','5821','5822','5823','5824','5825','5826','5827','5828','5829','5830','5831','5832','5833','5834','5835','5836','5837','5838','5839','5840','5841','5842','5843','5844','5845','5846','5847','5848','5849','5850','5851','5852','5853','5854','5855','5856','5857','5858','5859','5860','5861','5862','5863','5864','5865','5866','5867','5868','5869','5870','5871','5872','5873','5874','5875','5876','5877','5878','5879','5880','5881','5882','5883','5884','5885','5886','5887','5888','5889','5890','5891','5892','5893','5894','5895','5896','5897','5898','5899',
        '5900','5901','5902','5903','5904','5905','5906','5907','5908','5909','5910','5911','5912','5913','5914','5915','5916','5917','5918','5919','5920','5921','5922','5923','5924','5925','5926','5927','5928','5929','5930','5931','5932','5933','5934','5935','5936','5937','5938','5939','5940','5941','5942','5943','5944','5945','5946','5947','5948','5949','5950','5951','5952','5953','5954','5955','5956','5957','5958','5959','5960','5961','5962','5963','5964','5965','5966','5967','5968','5969','5970','5971','5972','5973','5974','5975','5976','5977','5978','5979','5980','5981','5982','5983','5984','5985','5986','5987','5988','5989','5990','5991','5992','5993','5994','5995','5996','5997','5998','5999',
        '6000','6001','6002','6003','6004','6005','6006','6007','6008','6009','6010','6011','6012','6013','6014','6015','6016','6017','6018','6019','6020','6021','6022','6023','6024','6025','6026','6027','6028','6029','6030','6031','6032','6033','6034','6035','6036','6037','6038','6039','6040','6041','6042','6043','6044','6045','6046','6047','6048','6049','6050','6051','6052','6053','6054','6055','6056','6057','6058','6059','6060','6061','6062','6063','6064','6065','6066','6067','6068','6069','6070','6071','6072','6073','6074','6075','6076','6077','6078','6079','6080','6081','6082','6083','6084','6085','6086','6087','6088','6089','6090','6091','6092','6093','6094','6095','6096','6097','6098','6099',
        '6100','6101','6102','6103','6104','6105','6106','6107','6108','6109','6110','6111','6112','6113','6114','6115','6116','6117','6118','6119','6120','6121','6122','6123','6124','6125','6126','6127','6128','6129','6130','6131','6132','6133','6134','6135','6136','6137','6138','6139','6140','6141','6142','6143','6144','6145','6146','6147','6148','6149','6150','6151','6152','6153','6154','6155','6156','6157','6158','6159','6160','6161','6162','6163','6164','6165','6166','6167','6168','6169','6170','6171','6172','6173','6174','6175','6176','6177','6178','6179','6180','6181','6182','6183','6184','6185','6186','6187','6188','6189','6190','6191','6192','6193','6194','6195','6196','6197','6198','6199',
        '6200','6201','6202','6203','6204','6205','6206','6207','6208','6209','6210','6211','6212','6213','6214','6215','6216','6217','6218','6219','6220','6221','6222','6223','6224','6225','6226','6227','6228','6229','6230','6231','6232','6233','6234','6235','6236','6237','6238','6239','6240','6241','6242','6243','6244','6245','6246','6247','6248','6249','6250','6251','6252','6253','6254','6255','6256','6257','6258','6259','6260','6261','6262','6263','6264','6265','6266','6267','6268','6269','6270','6271','6272','6273','6274','6275','6276','6277','6278','6279','6280','6281','6282','6283','6284','6285','6286','6287','6288','6289','6290','6291','6292','6293','6294','6295','6296','6297','6298','6299',
        '6300','6301','6302','6303','6304','6305','6306','6307','6308','6309','6310','6311','6312','6313','6314','6315','6316','6317','6318','6319','6320','6321','6322','6323','6324','6325','6326','6327','6328','6329','6330','6331','6332','6333','6334','6335','6336','6337','6338','6339','6340','6341','6342','6343','6344','6345','6346','6347','6348','6349','6350','6351','6352','6353','6354','6355','6356','6357','6358','6359','6360','6361','6362','6363','6364','6365','6366','6367','6368','6369','6370','6371','6372','6373','6374','6375','6376','6377','6378','6379','6380','6381','6382','6383','6384','6385','6386','6387','6388','6389','6390','6391','6392','6393','6394','6395','6396','6397','6398','6399',
        '6400','6401','6402','6403','6404','6405','6406','6407','6408','6409','6410','6411','6412','6413','6414','6415','6416','6417','6418','6419','6420','6421','6422','6423','6424','6425','6426','6427','6428','6429','6430','6431','6432','6433','6434','6435','6436','6437','6438','6439','6440','6441','6442','6443','6444','6445','6446','6447','6448','6449','6450','6451','6452','6453','6454','6455','6456','6457','6458','6459','6460','6461','6462','6463','6464','6465','6466','6467','6468','6469','6470','6471','6472','6473','6474','6475','6476','6477','6478','6479','6480','6481','6482','6483','6484','6485','6486','6487','6488','6489','6490','6491','6492','6493','6494','6495','6496','6497','6498','6499',
        '6500','6501','6502','6503','6504','6505','6506','6507','6508','6509','6510','6511','6512','6513','6514','6515','6516','6517','6518','6519','6520','6521','6522','6523','6524','6525','6526','6527','6528','6529','6530','6531','6532','6533','6534','6535','6536','6537','6538','6539','6540','6541','6542','6543','6544','6545','6546','6547','6548','6549','6550','6551','6552','6553','6554','6555','6556','6557','6558','6559','6560','6561','6562','6563','6564','6565','6566','6567','6568','6569','6570','6571','6572','6573','6574','6575','6576','6577','6578','6579','6580','6581','6582','6583','6584','6585','6586','6587','6588','6589','6590','6591','6592','6593','6594','6595','6596','6597','6598','6599',
        '6600','6601','6602','6603','6604','6605','6606','6607','6608','6609','6610','6611','6612','6613','6614','6615','6616','6617','6618','6619','6620','6621','6622','6623','6624','6625','6626','6627','6628','6629','6630','6631','6632','6633','6634','6635','6636','6637','6638','6639','6640','6641','6642','6643','6644','6645','6646','6647','6648','6649','6650','6651','6652','6653','6654','6655','6656','6657','6658','6659','6660','6661','6662','6663','6664','6665','6666','6667','6668','6669','6670','6671','6672','6673','6674','6675','6676','6677','6678','6679','6680','6681','6682','6683','6684','6685','6686','6687','6688','6689','6690','6691','6692','6693','6694','6695','6696','6697','6698','6699',
        '6700','6701','6702','6703','6704','6705','6706','6707','6708','6709','6710','6711','6712','6713','6714','6715','6716','6717','6718','6719','6720','6721','6722','6723','6724','6725','6726','6727','6728','6729','6730','6731','6732','6733','6734','6735','6736','6737','6738','6739','6740','6741','6742','6743','6744','6745','6746','6747','6748','6749','6750','6751','6752','6753','6754','6755','6756','6757','6758','6759','6760','6761','6762','6763','6764','6765','6766','6767','6768','6769','6770','6771','6772','6773','6774','6775','6776','6777','6778','6779','6780','6781','6782','6783','6784','6785','6786','6787','6788','6789','6790','6791','6792','6793','6794','6795','6796','6797','6798','6799',
        '6800','6801','6802','6803','6804','6805','6806','6807','6808','6809','6810','6811','6812','6813','6814','6815','6816','6817','6818','6819','6820','6821','6822','6823','6824','6825','6826','6827','6828','6829','6830','6831','6832','6833','6834','6835','6836','6837','6838','6839','6840','6841','6842','6843','6844','6845','6846','6847','6848','6849','6850','6851','6852','6853','6854','6855','6856','6857','6858','6859','6860','6861','6862','6863','6864','6865','6866','6867','6868','6869','6870','6871','6872','6873','6874','6875','6876','6877','6878','6879','6880','6881','6882','6883','6884','6885','6886','6887','6888','6889','6890','6891','6892','6893','6894','6895','6896','6897','6898','6899',
        '6900','6901','6902','6903','6904','6905','6906','6907','6908','6909','6910','6911','6912','6913','6914','6915','6916','6917','6918','6919','6920','6921','6922','6923','6924','6925','6926','6927','6928','6929','6930','6931','6932','6933','6934','6935','6936','6937','6938','6939','6940','6941','6942','6943','6944','6945','6946','6947','6948','6949','6950','6951','6952','6953','6954','6955','6956','6957','6958','6959','6960','6961','6962','6963','6964','6965','6966','6967','6968','6969','6970','6971','6972','6973','6974','6975','6976','6977','6978','6979','6980','6981','6982','6983','6984','6985','6986','6987','6988','6989','6990','6991','6992','6993','6994','6995','6996','6997','6998','6999',
        '7000','7001','7002','7003','7004','7005','7006','7007','7008','7009','7010','7011','7012','7013','7014','7015','7016','7017','7018','7019','7020','7021','7022','7023','7024','7025','7026','7027','7028','7029','7030','7031','7032','7033','7034','7035','7036','7037','7038','7039','7040','7041','7042','7043','7044','7045','7046','7047','7048','7049','7050','7051','7052','7053','7054','7055','7056','7057','7058','7059','7060','7061','7062','7063','7064','7065','7066','7067','7068','7069','7070','7071','7072','7073','7074','7075','7076','7077','7078','7079','7080','7081','7082','7083','7084','7085','7086','7087','7088','7089','7090','7091','7092','7093','7094','7095','7096','7097','7098','7099',
        '7100','7101','7102','7103','7104','7105','7106','7107','7108','7109','7110','7111','7112','7113','7114','7115','7116','7117','7118','7119','7120','7121','7122','7123','7124','7125','7126','7127','7128','7129','7130','7131','7132','7133','7134','7135','7136','7137','7138','7139','7140','7141','7142','7143','7144','7145','7146','7147','7148','7149','7150','7151','7152','7153','7154','7155','7156','7157','7158','7159','7160','7161','7162','7163','7164','7165','7166','7167','7168','7169','7170','7171','7172','7173','7174','7175','7176','7177','7178','7179','7180','7181','7182','7183','7184','7185','7186','7187','7188','7189','7190','7191','7192','7193','7194','7195','7196','7197','7198','7199',
        '7200','7201','7202','7203','7204','7205','7206','7207','7208','7209','7210','7211','7212','7213','7214','7215','7216','7217','7218','7219','7220','7221','7222','7223','7224','7225','7226','7227','7228','7229','7230','7231','7232','7233','7234','7235','7236','7237','7238','7239','7240','7241','7242','7243','7244','7245','7246','7247','7248','7249','7250','7251','7252','7253','7254','7255','7256','7257','7258','7259','7260','7261','7262','7263','7264','7265','7266','7267','7268','7269','7270','7271','7272','7273','7274','7275','7276','7277','7278','7279','7280','7281','7282','7283','7284','7285','7286','7287','7288','7289','7290','7291','7292','7293','7294','7295','7296','7297','7298','7299',
        '7300','7301','7302','7303','7304','7305','7306','7307','7308','7309','7310','7311','7312','7313','7314','7315','7316','7317','7318','7319','7320','7321','7322','7323','7324','7325','7326','7327','7328','7329','7330','7331','7332','7333','7334','7335','7336','7337','7338','7339','7340','7341','7342','7343','7344','7345','7346','7347','7348','7349','7350','7351','7352','7353','7354','7355','7356','7357','7358','7359','7360','7361','7362','7363','7364','7365','7366','7367','7368','7369','7370','7371','7372','7373','7374','7375','7376','7377','7378','7379','7380','7381','7382','7383','7384','7385','7386','7387','7388','7389','7390','7391','7392','7393','7394','7395','7396','7397','7398','7399',
        '7400','7401','7402','7403','7404','7405','7406','7407','7408','7409','7410','7411','7412','7413','7414','7415','7416','7417','7418','7419','7420','7421','7422','7423','7424','7425','7426','7427','7428','7429','7430','7431','7432','7433','7434','7435','7436','7437','7438','7439','7440','7441','7442','7443','7444','7445','7446','7447','7448','7449','7450','7451','7452','7453','7454','7455','7456','7457','7458','7459','7460','7461','7462','7463','7464','7465','7466','7467','7468','7469','7470','7471','7472','7473','7474','7475','7476','7477','7478','7479','7480','7481','7482','7483','7484','7485','7486','7487','7488','7489','7490','7491','7492','7493','7494','7495','7496','7497','7498','7499',
        '7500','7501','7502','7503','7504','7505','7506','7507','7508','7509','7510','7511','7512','7513','7514','7515','7516','7517','7518','7519','7520','7521','7522','7523','7524','7525','7526','7527','7528','7529','7530','7531','7532','7533','7534','7535','7536','7537','7538','7539','7540','7541','7542','7543','7544','7545','7546','7547','7548','7549','7550','7551','7552','7553','7554','7555','7556','7557','7558','7559','7560','7561','7562','7563','7564','7565','7566','7567','7568','7569','7570','7571','7572','7573','7574','7575','7576','7577','7578','7579','7580','7581','7582','7583','7584','7585','7586','7587','7588','7589','7590','7591','7592','7593','7594','7595','7596','7597','7598','7599',
        '7600','7601','7602','7603','7604','7605','7606','7607','7608','7609','7610','7611','7612','7613','7614','7615','7616','7617','7618','7619','7620','7621','7622','7623','7624','7625','7626','7627','7628','7629','7630','7631','7632','7633','7634','7635','7636','7637','7638','7639','7640','7641','7642','7643','7644','7645','7646','7647','7648','7649','7650','7651','7652','7653','7654','7655','7656','7657','7658','7659','7660','7661','7662','7663','7664','7665','7666','7667','7668','7669','7670','7671','7672','7673','7674','7675','7676','7677','7678','7679','7680','7681','7682','7683','7684','7685','7686','7687','7688','7689','7690','7691','7692','7693','7694','7695','7696','7697','7698','7699',
        '7700','7701','7702','7703','7704','7705','7706','7707','7708','7709','7710','7711','7712','7713','7714','7715','7716','7717','7718','7719','7720','7721','7722','7723','7724','7725','7726','7727','7728','7729','7730','7731','7732','7733','7734','7735','7736','7737','7738','7739','7740','7741','7742','7743','7744','7745','7746','7747','7748','7749','7750','7751','7752','7753','7754','7755','7756','7757','7758','7759','7760','7761','7762','7763','7764','7765','7766','7767','7768','7769','7770','7771','7772','7773','7774','7775','7776','7777','7778','7779','7780','7781','7782','7783','7784','7785','7786','7787','7788','7789','7790','7791','7792','7793','7794','7795','7796','7797','7798','7799',
        '7800','7801','7802','7803','7804','7805','7806','7807','7808','7809','7810','7811','7812','7813','7814','7815','7816','7817','7818','7819','7820','7821','7822','7823','7824','7825','7826','7827','7828','7829','7830','7831','7832','7833','7834','7835','7836','7837','7838','7839','7840','7841','7842','7843','7844','7845','7846','7847','7848','7849','7850','7851','7852','7853','7854','7855','7856','7857','7858','7859','7860','7861','7862','7863','7864','7865','7866','7867','7868','7869','7870','7871','7872','7873','7874','7875','7876','7877','7878','7879','7880','7881','7882','7883','7884','7885','7886','7887','7888','7889','7890','7891','7892','7893','7894','7895','7896','7897','7898','7899',
        '7900','7901','7902','7903','7904','7905','7906','7907','7908','7909','7910','7911','7912','7913','7914','7915','7916','7917','7918','7919','7920','7921','7922','7923','7924','7925','7926','7927','7928','7929','7930','7931','7932','7933','7934','7935','7936','7937','7938','7939','7940','7941','7942','7943','7944','7945','7946','7947','7948','7949','7950','7951','7952','7953','7954','7955','7956','7957','7958','7959','7960','7961','7962','7963','7964','7965','7966','7967','7968','7969','7970','7971','7972','7973','7974','7975','7976','7977','7978','7979','7980','7981','7982','7983','7984','7985','7986','7987','7988','7989','7990','7991','7992','7993','7994','7995','7996','7997','7998','7999',
        '8000','8001','8002','8003','8004','8005','8006','8007','8008','8009','8010','8011','8012','8013','8014','8015','8016','8017','8018','8019','8020','8021','8022','8023','8024','8025','8026','8027','8028','8029','8030','8031','8032','8033','8034','8035','8036','8037','8038','8039','8040','8041','8042','8043','8044','8045','8046','8047','8048','8049','8050','8051','8052','8053','8054','8055','8056','8057','8058','8059','8060','8061','8062','8063','8064','8065','8066','8067','8068','8069','8070','8071','8072','8073','8074','8075','8076','8077','8078','8079','8080','8081','8082','8083','8084','8085','8086','8087','8088','8089','8090','8091','8092','8093','8094','8095','8096','8097','8098','8099',
        '8100','8101','8102','8103','8104','8105','8106','8107','8108','8109','8110','8111','8112','8113','8114','8115','8116','8117','8118','8119','8120','8121','8122','8123','8124','8125','8126','8127','8128','8129','8130','8131','8132','8133','8134','8135','8136','8137','8138','8139','8140','8141','8142','8143','8144','8145','8146','8147','8148','8149','8150','8151','8152','8153','8154','8155','8156','8157','8158','8159','8160','8161','8162','8163','8164','8165','8166','8167','8168','8169','8170','8171','8172','8173','8174','8175','8176','8177','8178','8179','8180','8181','8182','8183','8184','8185','8186','8187','8188','8189','8190','8191','8192','8193','8194','8195','8196','8197','8198','8199',
        '8200','8201','8202','8203','8204','8205','8206','8207','8208','8209','8210','8211','8212','8213','8214','8215','8216','8217','8218','8219','8220','8221','8222','8223','8224','8225','8226','8227','8228','8229','8230','8231','8232','8233','8234','8235','8236','8237','8238','8239','8240','8241','8242','8243','8244','8245','8246','8247','8248','8249','8250','8251','8252','8253','8254','8255','8256','8257','8258','8259','8260','8261','8262','8263','8264','8265','8266','8267','8268','8269','8270','8271','8272','8273','8274','8275','8276','8277','8278','8279','8280','8281','8282','8283','8284','8285','8286','8287','8288','8289','8290','8291','8292','8293','8294','8295','8296','8297','8298','8299',
        '8300','8301','8302','8303','8304','8305','8306','8307','8308','8309','8310','8311','8312','8313','8314','8315','8316','8317','8318','8319','8320','8321','8322','8323','8324','8325','8326','8327','8328','8329','8330','8331','8332','8333','8334','8335','8336','8337','8338','8339','8340','8341','8342','8343','8344','8345','8346','8347','8348','8349','8350','8351','8352','8353','8354','8355','8356','8357','8358','8359','8360','8361','8362','8363','8364','8365','8366','8367','8368','8369','8370','8371','8372','8373','8374','8375','8376','8377','8378','8379','8380','8381','8382','8383','8384','8385','8386','8387','8388','8389','8390','8391','8392','8393','8394','8395','8396','8397','8398','8399',
        '8400','8401','8402','8403','8404','8405','8406','8407','8408','8409','8410','8411','8412','8413','8414','8415','8416','8417','8418','8419','8420','8421','8422','8423','8424','8425','8426','8427','8428','8429','8430','8431','8432','8433','8434','8435','8436','8437','8438','8439','8440','8441','8442','8443','8444','8445','8446','8447','8448','8449','8450','8451','8452','8453','8454','8455','8456','8457','8458','8459','8460','8461','8462','8463','8464','8465','8466','8467','8468','8469','8470','8471','8472','8473','8474','8475','8476','8477','8478','8479','8480','8481','8482','8483','8484','8485','8486','8487','8488','8489','8490','8491','8492','8493','8494','8495','8496','8497','8498','8499',
        '8500','8501','8502','8503','8504','8505','8506','8507','8508','8509','8510','8511','8512','8513','8514','8515','8516','8517','8518','8519','8520','8521','8522','8523','8524','8525','8526','8527','8528','8529','8530','8531','8532','8533','8534','8535','8536','8537','8538','8539','8540','8541','8542','8543','8544','8545','8546','8547','8548','8549','8550','8551','8552','8553','8554','8555','8556','8557','8558','8559','8560','8561','8562','8563','8564','8565','8566','8567','8568','8569','8570','8571','8572','8573','8574','8575','8576','8577','8578','8579','8580','8581','8582','8583','8584','8585','8586','8587','8588','8589','8590','8591','8592','8593','8594','8595','8596','8597','8598','8599',
        '8600','8601','8602','8603','8604','8605','8606','8607','8608','8609','8610','8611','8612','8613','8614','8615','8616','8617','8618','8619','8620','8621','8622','8623','8624','8625','8626','8627','8628','8629','8630','8631','8632','8633','8634','8635','8636','8637','8638','8639','8640','8641','8642','8643','8644','8645','8646','8647','8648','8649','8650','8651','8652','8653','8654','8655','8656','8657','8658','8659','8660','8661','8662','8663','8664','8665','8666','8667','8668','8669','8670','8671','8672','8673','8674','8675','8676','8677','8678','8679','8680','8681','8682','8683','8684','8685','8686','8687','8688','8689','8690','8691','8692','8693','8694','8695','8696','8697','8698','8699',
        '8700','8701','8702','8703','8704','8705','8706','8707','8708','8709','8710','8711','8712','8713','8714','8715','8716','8717','8718','8719','8720','8721','8722','8723','8724','8725','8726','8727','8728','8729','8730','8731','8732','8733','8734','8735','8736','8737','8738','8739','8740','8741','8742','8743','8744','8745','8746','8747','8748','8749','8750','8751','8752','8753','8754','8755','8756','8757','8758','8759','8760','8761','8762','8763','8764','8765','8766','8767','8768','8769','8770','8771','8772','8773','8774','8775','8776','8777','8778','8779','8780','8781','8782','8783','8784','8785','8786','8787','8788','8789','8790','8791','8792','8793','8794','8795','8796','8797','8798','8799',
        '8800','8801','8802','8803','8804','8805','8806','8807','8808','8809','8810','8811','8812','8813','8814','8815','8816','8817','8818','8819','8820','8821','8822','8823','8824','8825','8826','8827','8828','8829','8830','8831','8832','8833','8834','8835','8836','8837','8838','8839','8840','8841','8842','8843','8844','8845','8846','8847','8848','8849','8850','8851','8852','8853','8854','8855','8856','8857','8858','8859','8860','8861','8862','8863','8864','8865','8866','8867','8868','8869','8870','8871','8872','8873','8874','8875','8876','8877','8878','8879','8880','8881','8882','8883','8884','8885','8886','8887','8888','8889','8890','8891','8892','8893','8894','8895','8896','8897','8898','8899',
        '8900','8901','8902','8903','8904','8905','8906','8907','8908','8909','8910','8911','8912','8913','8914','8915','8916','8917','8918','8919','8920','8921','8922','8923','8924','8925','8926','8927','8928','8929','8930','8931','8932','8933','8934','8935','8936','8937','8938','8939','8940','8941','8942','8943','8944','8945','8946','8947','8948','8949','8950','8951','8952','8953','8954','8955','8956','8957','8958','8959','8960','8961','8962','8963','8964','8965','8966','8967','8968','8969','8970','8971','8972','8973','8974','8975','8976','8977','8978','8979','8980','8981','8982','8983','8984','8985','8986','8987','8988','8989','8990','8991','8992','8993','8994','8995','8996','8997','8998','8999',
        '9000','9001','9002','9003','9004','9005','9006','9007','9008','9009','9010','9011','9012','9013','9014','9015','9016','9017','9018','9019','9020','9021','9022','9023','9024','9025','9026','9027','9028','9029','9030','9031','9032','9033','9034','9035','9036','9037','9038','9039','9040','9041','9042','9043','9044','9045','9046','9047','9048','9049','9050','9051','9052','9053','9054','9055','9056','9057','9058','9059','9060','9061','9062','9063','9064','9065','9066','9067','9068','9069','9070','9071','9072','9073','9074','9075','9076','9077','9078','9079','9080','9081','9082','9083','9084','9085','9086','9087','9088','9089','9090','9091','9092','9093','9094','9095','9096','9097','9098','9099',
        '9100','9101','9102','9103','9104','9105','9106','9107','9108','9109','9110','9111','9112','9113','9114','9115','9116','9117','9118','9119','9120','9121','9122','9123','9124','9125','9126','9127','9128','9129','9130','9131','9132','9133','9134','9135','9136','9137','9138','9139','9140','9141','9142','9143','9144','9145','9146','9147','9148','9149','9150','9151','9152','9153','9154','9155','9156','9157','9158','9159','9160','9161','9162','9163','9164','9165','9166','9167','9168','9169','9170','9171','9172','9173','9174','9175','9176','9177','9178','9179','9180','9181','9182','9183','9184','9185','9186','9187','9188','9189','9190','9191','9192','9193','9194','9195','9196','9197','9198','9199',
        '9200','9201','9202','9203','9204','9205','9206','9207','9208','9209','9210','9211','9212','9213','9214','9215','9216','9217','9218','9219','9220','9221','9222','9223','9224','9225','9226','9227','9228','9229','9230','9231','9232','9233','9234','9235','9236','9237','9238','9239','9240','9241','9242','9243','9244','9245','9246','9247','9248','9249','9250','9251','9252','9253','9254','9255','9256','9257','9258','9259','9260','9261','9262','9263','9264','9265','9266','9267','9268','9269','9270','9271','9272','9273','9274','9275','9276','9277','9278','9279','9280','9281','9282','9283','9284','9285','9286','9287','9288','9289','9290','9291','9292','9293','9294','9295','9296','9297','9298','9299',
        '9300','9301','9302','9303','9304','9305','9306','9307','9308','9309','9310','9311','9312','9313','9314','9315','9316','9317','9318','9319','9320','9321','9322','9323','9324','9325','9326','9327','9328','9329','9330','9331','9332','9333','9334','9335','9336','9337','9338','9339','9340','9341','9342','9343','9344','9345','9346','9347','9348','9349','9350','9351','9352','9353','9354','9355','9356','9357','9358','9359','9360','9361','9362','9363','9364','9365','9366','9367','9368','9369','9370','9371','9372','9373','9374','9375','9376','9377','9378','9379','9380','9381','9382','9383','9384','9385','9386','9387','9388','9389','9390','9391','9392','9393','9394','9395','9396','9397','9398','9399',
        '9400','9401','9402','9403','9404','9405','9406','9407','9408','9409','9410','9411','9412','9413','9414','9415','9416','9417','9418','9419','9420','9421','9422','9423','9424','9425','9426','9427','9428','9429','9430','9431','9432','9433','9434','9435','9436','9437','9438','9439','9440','9441','9442','9443','9444','9445','9446','9447','9448','9449','9450','9451','9452','9453','9454','9455','9456','9457','9458','9459','9460','9461','9462','9463','9464','9465','9466','9467','9468','9469','9470','9471','9472','9473','9474','9475','9476','9477','9478','9479','9480','9481','9482','9483','9484','9485','9486','9487','9488','9489','9490','9491','9492','9493','9494','9495','9496','9497','9498','9499',
        '9500','9501','9502','9503','9504','9505','9506','9507','9508','9509','9510','9511','9512','9513','9514','9515','9516','9517','9518','9519','9520','9521','9522','9523','9524','9525','9526','9527','9528','9529','9530','9531','9532','9533','9534','9535','9536','9537','9538','9539','9540','9541','9542','9543','9544','9545','9546','9547','9548','9549','9550','9551','9552','9553','9554','9555','9556','9557','9558','9559','9560','9561','9562','9563','9564','9565','9566','9567','9568','9569','9570','9571','9572','9573','9574','9575','9576','9577','9578','9579','9580','9581','9582','9583','9584','9585','9586','9587','9588','9589','9590','9591','9592','9593','9594','9595','9596','9597','9598','9599',
        '9600','9601','9602','9603','9604','9605','9606','9607','9608','9609','9610','9611','9612','9613','9614','9615','9616','9617','9618','9619','9620','9621','9622','9623','9624','9625','9626','9627','9628','9629','9630','9631','9632','9633','9634','9635','9636','9637','9638','9639','9640','9641','9642','9643','9644','9645','9646','9647','9648','9649','9650','9651','9652','9653','9654','9655','9656','9657','9658','9659','9660','9661','9662','9663','9664','9665','9666','9667','9668','9669','9670','9671','9672','9673','9674','9675','9676','9677','9678','9679','9680','9681','9682','9683','9684','9685','9686','9687','9688','9689','9690','9691','9692','9693','9694','9695','9696','9697','9698','9699',
        '9700','9701','9702','9703','9704','9705','9706','9707','9708','9709','9710','9711','9712','9713','9714','9715','9716','9717','9718','9719','9720','9721','9722','9723','9724','9725','9726','9727','9728','9729','9730','9731','9732','9733','9734','9735','9736','9737','9738','9739','9740','9741','9742','9743','9744','9745','9746','9747','9748','9749','9750','9751','9752','9753','9754','9755','9756','9757','9758','9759','9760','9761','9762','9763','9764','9765','9766','9767','9768','9769','9770','9771','9772','9773','9774','9775','9776','9777','9778','9779','9780','9781','9782','9783','9784','9785','9786','9787','9788','9789','9790','9791','9792','9793','9794','9795','9796','9797','9798','9799',
        '9800','9801','9802','9803','9804','9805','9806','9807','9808','9809','9810','9811','9812','9813','9814','9815','9816','9817','9818','9819','9820','9821','9822','9823','9824','9825','9826','9827','9828','9829','9830','9831','9832','9833','9834','9835','9836','9837','9838','9839','9840','9841','9842','9843','9844','9845','9846','9847','9848','9849','9850','9851','9852','9853','9854','9855','9856','9857','9858','9859','9860','9861','9862','9863','9864','9865','9866','9867','9868','9869','9870','9871','9872','9873','9874','9875','9876','9877','9878','9879','9880','9881','9882','9883','9884','9885','9886','9887','9888','9889','9890','9891','9892','9893','9894','9895','9896','9897','9898','9899',
        '9900','9901','9902','9903','9904','9905','9906','9907','9908','9909','9910','9911','9912','9913','9914','9915','9916','9917','9918','9919','9920','9921','9922','9923','9924','9925','9926','9927','9928','9929','9930','9931','9932','9933','9934','9935','9936','9937','9938','9939','9940','9941','9942','9943','9944','9945','9946','9947','9948','9949','9950','9951','9952','9953','9954','9955','9956','9957','9958','9959','9960','9961','9962','9963','9964','9965','9966','9967','9968','9969','9970','9971','9972','9973','9974','9975','9976','9977','9978','9979','9980','9981','9982','9983','9984','9985','9986','9987','9988','9989','9990','9991','9992','9993','9994','9995','9996','9997','9998','9999'
    ],
    threefixed:[
        '000X','001X','002X','003X','004X','005X','006X','007X','008X','009X','010X','011X','012X','013X','014X','015X','016X','017X','018X','019X','020X','021X','022X','023X','024X','025X','026X','027X','028X','029X','030X','031X','032X','033X','034X','035X','036X','037X','038X','039X','040X','041X','042X','043X','044X','045X','046X','047X','048X','049X','050X','051X','052X','053X','054X','055X','056X','057X','058X','059X','060X','061X','062X','063X','064X','065X','066X','067X','068X','069X','070X','071X','072X','073X','074X','075X','076X','077X','078X','079X','080X','081X','082X','083X','084X','085X','086X','087X','088X','089X','090X','091X','092X','093X','094X','095X','096X','097X','098X','099X',
        '100X','101X','102X','103X','104X','105X','106X','107X','108X','109X','110X','111X','112X','113X','114X','115X','116X','117X','118X','119X','120X','121X','122X','123X','124X','125X','126X','127X','128X','129X','130X','131X','132X','133X','134X','135X','136X','137X','138X','139X','140X','141X','142X','143X','144X','145X','146X','147X','148X','149X','150X','151X','152X','153X','154X','155X','156X','157X','158X','159X','160X','161X','162X','163X','164X','165X','166X','167X','168X','169X','170X','171X','172X','173X','174X','175X','176X','177X','178X','179X','180X','181X','182X','183X','184X','185X','186X','187X','188X','189X','190X','191X','192X','193X','194X','195X','196X','197X','198X','199X',
        '200X','201X','202X','203X','204X','205X','206X','207X','208X','209X','210X','211X','212X','213X','214X','215X','216X','217X','218X','219X','220X','221X','222X','223X','224X','225X','226X','227X','228X','229X','230X','231X','232X','233X','234X','235X','236X','237X','238X','239X','240X','241X','242X','243X','244X','245X','246X','247X','248X','249X','250X','251X','252X','253X','254X','255X','256X','257X','258X','259X','260X','261X','262X','263X','264X','265X','266X','267X','268X','269X','270X','271X','272X','273X','274X','275X','276X','277X','278X','279X','280X','281X','282X','283X','284X','285X','286X','287X','288X','289X','290X','291X','292X','293X','294X','295X','296X','297X','298X','299X',
        '300X','301X','302X','303X','304X','305X','306X','307X','308X','309X','310X','311X','312X','313X','314X','315X','316X','317X','318X','319X','320X','321X','322X','323X','324X','325X','326X','327X','328X','329X','330X','331X','332X','333X','334X','335X','336X','337X','338X','339X','340X','341X','342X','343X','344X','345X','346X','347X','348X','349X','350X','351X','352X','353X','354X','355X','356X','357X','358X','359X','360X','361X','362X','363X','364X','365X','366X','367X','368X','369X','370X','371X','372X','373X','374X','375X','376X','377X','378X','379X','380X','381X','382X','383X','384X','385X','386X','387X','388X','389X','390X','391X','392X','393X','394X','395X','396X','397X','398X','399X',
        '400X','401X','402X','403X','404X','405X','406X','407X','408X','409X','410X','411X','412X','413X','414X','415X','416X','417X','418X','419X','420X','421X','422X','423X','424X','425X','426X','427X','428X','429X','430X','431X','432X','433X','434X','435X','436X','437X','438X','439X','440X','441X','442X','443X','444X','445X','446X','447X','448X','449X','450X','451X','452X','453X','454X','455X','456X','457X','458X','459X','460X','461X','462X','463X','464X','465X','466X','467X','468X','469X','470X','471X','472X','473X','474X','475X','476X','477X','478X','479X','480X','481X','482X','483X','484X','485X','486X','487X','488X','489X','490X','491X','492X','493X','494X','495X','496X','497X','498X','499X',
        '500X','501X','502X','503X','504X','505X','506X','507X','508X','509X','510X','511X','512X','513X','514X','515X','516X','517X','518X','519X','520X','521X','522X','523X','524X','525X','526X','527X','528X','529X','530X','531X','532X','533X','534X','535X','536X','537X','538X','539X','540X','541X','542X','543X','544X','545X','546X','547X','548X','549X','550X','551X','552X','553X','554X','555X','556X','557X','558X','559X','560X','561X','562X','563X','564X','565X','566X','567X','568X','569X','570X','571X','572X','573X','574X','575X','576X','577X','578X','579X','580X','581X','582X','583X','584X','585X','586X','587X','588X','589X','590X','591X','592X','593X','594X','595X','596X','597X','598X','599X',
        '600X','601X','602X','603X','604X','605X','606X','607X','608X','609X','610X','611X','612X','613X','614X','615X','616X','617X','618X','619X','620X','621X','622X','623X','624X','625X','626X','627X','628X','629X','630X','631X','632X','633X','634X','635X','636X','637X','638X','639X','640X','641X','642X','643X','644X','645X','646X','647X','648X','649X','650X','651X','652X','653X','654X','655X','656X','657X','658X','659X','660X','661X','662X','663X','664X','665X','666X','667X','668X','669X','670X','671X','672X','673X','674X','675X','676X','677X','678X','679X','680X','681X','682X','683X','684X','685X','686X','687X','688X','689X','690X','691X','692X','693X','694X','695X','696X','697X','698X','699X',
        '700X','701X','702X','703X','704X','705X','706X','707X','708X','709X','710X','711X','712X','713X','714X','715X','716X','717X','718X','719X','720X','721X','722X','723X','724X','725X','726X','727X','728X','729X','730X','731X','732X','733X','734X','735X','736X','737X','738X','739X','740X','741X','742X','743X','744X','745X','746X','747X','748X','749X','750X','751X','752X','753X','754X','755X','756X','757X','758X','759X','760X','761X','762X','763X','764X','765X','766X','767X','768X','769X','770X','771X','772X','773X','774X','775X','776X','777X','778X','779X','780X','781X','782X','783X','784X','785X','786X','787X','788X','789X','790X','791X','792X','793X','794X','795X','796X','797X','798X','799X',
        '800X','801X','802X','803X','804X','805X','806X','807X','808X','809X','810X','811X','812X','813X','814X','815X','816X','817X','818X','819X','820X','821X','822X','823X','824X','825X','826X','827X','828X','829X','830X','831X','832X','833X','834X','835X','836X','837X','838X','839X','840X','841X','842X','843X','844X','845X','846X','847X','848X','849X','850X','851X','852X','853X','854X','855X','856X','857X','858X','859X','860X','861X','862X','863X','864X','865X','866X','867X','868X','869X','870X','871X','872X','873X','874X','875X','876X','877X','878X','879X','880X','881X','882X','883X','884X','885X','886X','887X','888X','889X','890X','891X','892X','893X','894X','895X','896X','897X','898X','899X',
        '900X','901X','902X','903X','904X','905X','906X','907X','908X','909X','910X','911X','912X','913X','914X','915X','916X','917X','918X','919X','920X','921X','922X','923X','924X','925X','926X','927X','928X','929X','930X','931X','932X','933X','934X','935X','936X','937X','938X','939X','940X','941X','942X','943X','944X','945X','946X','947X','948X','949X','950X','951X','952X','953X','954X','955X','956X','957X','958X','959X','960X','961X','962X','963X','964X','965X','966X','967X','968X','969X','970X','971X','972X','973X','974X','975X','976X','977X','978X','979X','980X','981X','982X','983X','984X','985X','986X','987X','988X','989X','990X','991X','992X','993X','994X','995X','996X','997X','998X','999X',
        '00X0','00X1','00X2','00X3','00X4','00X5','00X6','00X7','00X8','00X9','01X0','01X1','01X2','01X3','01X4','01X5','01X6','01X7','01X8','01X9','02X0','02X1','02X2','02X3','02X4','02X5','02X6','02X7','02X8','02X9','03X0','03X1','03X2','03X3','03X4','03X5','03X6','03X7','03X8','03X9','04X0','04X1','04X2','04X3','04X4','04X5','04X6','04X7','04X8','04X9','05X0','05X1','05X2','05X3','05X4','05X5','05X6','05X7','05X8','05X9','06X0','06X1','06X2','06X3','06X4','06X5','06X6','06X7','06X8','06X9','07X0','07X1','07X2','07X3','07X4','07X5','07X6','07X7','07X8','07X9','08X0','08X1','08X2','08X3','08X4','08X5','08X6','08X7','08X8','08X9','09X0','09X1','09X2','09X3','09X4','09X5','09X6','09X7','09X8','09X9','10X0','10X1','10X2','10X3','10X4','10X5','10X6','10X7','10X8','10X9',
        '11X0','11X1','11X2','11X3','11X4','11X5','11X6','11X7','11X8','11X9','12X0','12X1','12X2','12X3','12X4','12X5','12X6','12X7','12X8','12X9','13X0','13X1','13X2','13X3','13X4','13X5','13X6','13X7','13X8','13X9','14X0','14X1','14X2','14X3','14X4','14X5','14X6','14X7','14X8','14X9','15X0','15X1','15X2','15X3','15X4','15X5','15X6','15X7','15X8','15X9','16X0','16X1','16X2','16X3','16X4','16X5','16X6','16X7','16X8','16X9','17X0','17X1','17X2','17X3','17X4','17X5','17X6','17X7','17X8','17X9','18X0','18X1','18X2','18X3','18X4','18X5','18X6','18X7','18X8','18X9','19X0','19X1','19X2','19X3','19X4','19X5','19X6','19X7','19X8','19X9','20X0','20X1','20X2','20X3','20X4','20X5','20X6','20X7','20X8','20X9',
        '21X0','21X1','21X2','21X3','21X4','21X5','21X6','21X7','21X8','21X9','22X0','22X1','22X2','22X3','22X4','22X5','22X6','22X7','22X8','22X9','23X0','23X1','23X2','23X3','23X4','23X5','23X6','23X7','23X8','23X9','24X0','24X1','24X2','24X3','24X4','24X5','24X6','24X7','24X8','24X9','25X0','25X1','25X2','25X3','25X4','25X5','25X6','25X7','25X8','25X9','26X0','26X1','26X2','26X3','26X4','26X5','26X6','26X7','26X8','26X9','27X0','27X1','27X2','27X3','27X4','27X5','27X6','27X7','27X8','27X9','28X0','28X1','28X2','28X3','28X4','28X5','28X6','28X7','28X8','28X9','29X0','29X1','29X2','29X3','29X4','29X5','29X6','29X7','29X8','29X9','30X0','30X1','30X2','30X3','30X4','30X5','30X6','30X7','30X8','30X9',
        '31X0','31X1','31X2','31X3','31X4','31X5','31X6','31X7','31X8','31X9','32X0','32X1','32X2','32X3','32X4','32X5','32X6','32X7','32X8','32X9','33X0','33X1','33X2','33X3','33X4','33X5','33X6','33X7','33X8','33X9','34X0','34X1','34X2','34X3','34X4','34X5','34X6','34X7','34X8','34X9','35X0','35X1','35X2','35X3','35X4','35X5','35X6','35X7','35X8','35X9','36X0','36X1','36X2','36X3','36X4','36X5','36X6','36X7','36X8','36X9','37X0','37X1','37X2','37X3','37X4','37X5','37X6','37X7','37X8','37X9','38X0','38X1','38X2','38X3','38X4','38X5','38X6','38X7','38X8','38X9','39X0','39X1','39X2','39X3','39X4','39X5','39X6','39X7','39X8','39X9','40X0','40X1','40X2','40X3','40X4','40X5','40X6','40X7','40X8','40X9',
        '41X0','41X1','41X2','41X3','41X4','41X5','41X6','41X7','41X8','41X9','42X0','42X1','42X2','42X3','42X4','42X5','42X6','42X7','42X8','42X9','43X0','43X1','43X2','43X3','43X4','43X5','43X6','43X7','43X8','43X9','44X0','44X1','44X2','44X3','44X4','44X5','44X6','44X7','44X8','44X9','45X0','45X1','45X2','45X3','45X4','45X5','45X6','45X7','45X8','45X9','46X0','46X1','46X2','46X3','46X4','46X5','46X6','46X7','46X8','46X9','47X0','47X1','47X2','47X3','47X4','47X5','47X6','47X7','47X8','47X9','48X0','48X1','48X2','48X3','48X4','48X5','48X6','48X7','48X8','48X9','49X0','49X1','49X2','49X3','49X4','49X5','49X6','49X7','49X8','49X9','50X0','50X1','50X2','50X3','50X4','50X5','50X6','50X7','50X8','50X9',
        '51X0','51X1','51X2','51X3','51X4','51X5','51X6','51X7','51X8','51X9','52X0','52X1','52X2','52X3','52X4','52X5','52X6','52X7','52X8','52X9','53X0','53X1','53X2','53X3','53X4','53X5','53X6','53X7','53X8','53X9','54X0','54X1','54X2','54X3','54X4','54X5','54X6','54X7','54X8','54X9','55X0','55X1','55X2','55X3','55X4','55X5','55X6','55X7','55X8','55X9','56X0','56X1','56X2','56X3','56X4','56X5','56X6','56X7','56X8','56X9','57X0','57X1','57X2','57X3','57X4','57X5','57X6','57X7','57X8','57X9','58X0','58X1','58X2','58X3','58X4','58X5','58X6','58X7','58X8','58X9','59X0','59X1','59X2','59X3','59X4','59X5','59X6','59X7','59X8','59X9','60X0','60X1','60X2','60X3','60X4','60X5','60X6','60X7','60X8','60X9',
        '61X0','61X1','61X2','61X3','61X4','61X5','61X6','61X7','61X8','61X9','62X0','62X1','62X2','62X3','62X4','62X5','62X6','62X7','62X8','62X9','63X0','63X1','63X2','63X3','63X4','63X5','63X6','63X7','63X8','63X9','64X0','64X1','64X2','64X3','64X4','64X5','64X6','64X7','64X8','64X9','65X0','65X1','65X2','65X3','65X4','65X5','65X6','65X7','65X8','65X9','66X0','66X1','66X2','66X3','66X4','66X5','66X6','66X7','66X8','66X9','67X0','67X1','67X2','67X3','67X4','67X5','67X6','67X7','67X8','67X9','68X0','68X1','68X2','68X3','68X4','68X5','68X6','68X7','68X8','68X9','69X0','69X1','69X2','69X3','69X4','69X5','69X6','69X7','69X8','69X9','70X0','70X1','70X2','70X3','70X4','70X5','70X6','70X7','70X8','70X9',
        '71X0','71X1','71X2','71X3','71X4','71X5','71X6','71X7','71X8','71X9','72X0','72X1','72X2','72X3','72X4','72X5','72X6','72X7','72X8','72X9','73X0','73X1','73X2','73X3','73X4','73X5','73X6','73X7','73X8','73X9','74X0','74X1','74X2','74X3','74X4','74X5','74X6','74X7','74X8','74X9','75X0','75X1','75X2','75X3','75X4','75X5','75X6','75X7','75X8','75X9','76X0','76X1','76X2','76X3','76X4','76X5','76X6','76X7','76X8','76X9','77X0','77X1','77X2','77X3','77X4','77X5','77X6','77X7','77X8','77X9','78X0','78X1','78X2','78X3','78X4','78X5','78X6','78X7','78X8','78X9','79X0','79X1','79X2','79X3','79X4','79X5','79X6','79X7','79X8','79X9','80X0','80X1','80X2','80X3','80X4','80X5','80X6','80X7','80X8','80X9',
        '81X0','81X1','81X2','81X3','81X4','81X5','81X6','81X7','81X8','81X9','82X0','82X1','82X2','82X3','82X4','82X5','82X6','82X7','82X8','82X9','83X0','83X1','83X2','83X3','83X4','83X5','83X6','83X7','83X8','83X9','84X0','84X1','84X2','84X3','84X4','84X5','84X6','84X7','84X8','84X9','85X0','85X1','85X2','85X3','85X4','85X5','85X6','85X7','85X8','85X9','86X0','86X1','86X2','86X3','86X4','86X5','86X6','86X7','86X8','86X9','87X0','87X1','87X2','87X3','87X4','87X5','87X6','87X7','87X8','87X9','88X0','88X1','88X2','88X3','88X4','88X5','88X6','88X7','88X8','88X9','89X0','89X1','89X2','89X3','89X4','89X5','89X6','89X7','89X8','89X9','90X0','90X1','90X2','90X3','90X4','90X5','90X6','90X7','90X8','90X9',
        '91X0','91X1','91X2','91X3','91X4','91X5','91X6','91X7','91X8','91X9','92X0','92X1','92X2','92X3','92X4','92X5','92X6','92X7','92X8','92X9','93X0','93X1','93X2','93X3','93X4','93X5','93X6','93X7','93X8','93X9','94X0','94X1','94X2','94X3','94X4','94X5','94X6','94X7','94X8','94X9','95X0','95X1','95X2','95X3','95X4','95X5','95X6','95X7','95X8','95X9','96X0','96X1','96X2','96X3','96X4','96X5','96X6','96X7','96X8','96X9','97X0','97X1','97X2','97X3','97X4','97X5','97X6','97X7','97X8','97X9','98X0','98X1','98X2','98X3','98X4','98X5','98X6','98X7','98X8','98X9','99X0','99X1','99X2','99X3','99X4','99X5','99X6','99X7','99X8','99X9','0X00','0X01','0X02','0X03','0X04','0X05','0X06','0X07','0X08','0X09','0X10','0X11','0X12','0X13','0X14','0X15','0X16','0X17','0X18','0X19','0X20','0X21','0X22','0X23','0X24','0X25','0X26','0X27','0X28','0X29','0X30','0X31','0X32','0X33','0X34','0X35','0X36','0X37','0X38','0X39','0X40','0X41','0X42','0X43','0X44','0X45','0X46','0X47','0X48','0X49','0X50','0X51','0X52','0X53','0X54','0X55','0X56','0X57','0X58','0X59','0X60','0X61','0X62','0X63','0X64','0X65','0X66','0X67','0X68','0X69','0X70','0X71','0X72','0X73','0X74','0X75','0X76','0X77','0X78','0X79','0X80','0X81','0X82','0X83','0X84','0X85','0X86','0X87','0X88','0X89','0X90','0X91','0X92','0X93','0X94','0X95','0X96','0X97','0X98','0X99',
        '1X00','1X01','1X02','1X03','1X04','1X05','1X06','1X07','1X08','1X09','1X10','1X11','1X12','1X13','1X14','1X15','1X16','1X17','1X18','1X19','1X20','1X21','1X22','1X23','1X24','1X25','1X26','1X27','1X28','1X29','1X30','1X31','1X32','1X33','1X34','1X35','1X36','1X37','1X38','1X39','1X40','1X41','1X42','1X43','1X44','1X45','1X46','1X47','1X48','1X49','1X50','1X51','1X52','1X53','1X54','1X55','1X56','1X57','1X58','1X59','1X60','1X61','1X62','1X63','1X64','1X65','1X66','1X67','1X68','1X69','1X70','1X71','1X72','1X73','1X74','1X75','1X76','1X77','1X78','1X79','1X80','1X81','1X82','1X83','1X84','1X85','1X86','1X87','1X88','1X89','1X90','1X91','1X92','1X93','1X94','1X95','1X96','1X97','1X98','1X99',
        '2X00','2X01','2X02','2X03','2X04','2X05','2X06','2X07','2X08','2X09','2X10','2X11','2X12','2X13','2X14','2X15','2X16','2X17','2X18','2X19','2X20','2X21','2X22','2X23','2X24','2X25','2X26','2X27','2X28','2X29','2X30','2X31','2X32','2X33','2X34','2X35','2X36','2X37','2X38','2X39','2X40','2X41','2X42','2X43','2X44','2X45','2X46','2X47','2X48','2X49','2X50','2X51','2X52','2X53','2X54','2X55','2X56','2X57','2X58','2X59','2X60','2X61','2X62','2X63','2X64','2X65','2X66','2X67','2X68','2X69','2X70','2X71','2X72','2X73','2X74','2X75','2X76','2X77','2X78','2X79','2X80','2X81','2X82','2X83','2X84','2X85','2X86','2X87','2X88','2X89','2X90','2X91','2X92','2X93','2X94','2X95','2X96','2X97','2X98','2X99',
        '3X00','3X01','3X02','3X03','3X04','3X05','3X06','3X07','3X08','3X09','3X10','3X11','3X12','3X13','3X14','3X15','3X16','3X17','3X18','3X19','3X20','3X21','3X22','3X23','3X24','3X25','3X26','3X27','3X28','3X29','3X30','3X31','3X32','3X33','3X34','3X35','3X36','3X37','3X38','3X39','3X40','3X41','3X42','3X43','3X44','3X45','3X46','3X47','3X48','3X49','3X50','3X51','3X52','3X53','3X54','3X55','3X56','3X57','3X58','3X59','3X60','3X61','3X62','3X63','3X64','3X65','3X66','3X67','3X68','3X69','3X70','3X71','3X72','3X73','3X74','3X75','3X76','3X77','3X78','3X79','3X80','3X81','3X82','3X83','3X84','3X85','3X86','3X87','3X88','3X89','3X90','3X91','3X92','3X93','3X94','3X95','3X96','3X97','3X98','3X99',
        '4X00','4X01','4X02','4X03','4X04','4X05','4X06','4X07','4X08','4X09','4X10','4X11','4X12','4X13','4X14','4X15','4X16','4X17','4X18','4X19','4X20','4X21','4X22','4X23','4X24','4X25','4X26','4X27','4X28','4X29','4X30','4X31','4X32','4X33','4X34','4X35','4X36','4X37','4X38','4X39','4X40','4X41','4X42','4X43','4X44','4X45','4X46','4X47','4X48','4X49','4X50','4X51','4X52','4X53','4X54','4X55','4X56','4X57','4X58','4X59','4X60','4X61','4X62','4X63','4X64','4X65','4X66','4X67','4X68','4X69','4X70','4X71','4X72','4X73','4X74','4X75','4X76','4X77','4X78','4X79','4X80','4X81','4X82','4X83','4X84','4X85','4X86','4X87','4X88','4X89','4X90','4X91','4X92','4X93','4X94','4X95','4X96','4X97','4X98','4X99',
        '5X00','5X01','5X02','5X03','5X04','5X05','5X06','5X07','5X08','5X09','5X10','5X11','5X12','5X13','5X14','5X15','5X16','5X17','5X18','5X19','5X20','5X21','5X22','5X23','5X24','5X25','5X26','5X27','5X28','5X29','5X30','5X31','5X32','5X33','5X34','5X35','5X36','5X37','5X38','5X39','5X40','5X41','5X42','5X43','5X44','5X45','5X46','5X47','5X48','5X49','5X50','5X51','5X52','5X53','5X54','5X55','5X56','5X57','5X58','5X59','5X60','5X61','5X62','5X63','5X64','5X65','5X66','5X67','5X68','5X69','5X70','5X71','5X72','5X73','5X74','5X75','5X76','5X77','5X78','5X79','5X80','5X81','5X82','5X83','5X84','5X85','5X86','5X87','5X88','5X89','5X90','5X91','5X92','5X93','5X94','5X95','5X96','5X97','5X98','5X99',
        '6X00','6X01','6X02','6X03','6X04','6X05','6X06','6X07','6X08','6X09','6X10','6X11','6X12','6X13','6X14','6X15','6X16','6X17','6X18','6X19','6X20','6X21','6X22','6X23','6X24','6X25','6X26','6X27','6X28','6X29','6X30','6X31','6X32','6X33','6X34','6X35','6X36','6X37','6X38','6X39','6X40','6X41','6X42','6X43','6X44','6X45','6X46','6X47','6X48','6X49','6X50','6X51','6X52','6X53','6X54','6X55','6X56','6X57','6X58','6X59','6X60','6X61','6X62','6X63','6X64','6X65','6X66','6X67','6X68','6X69','6X70','6X71','6X72','6X73','6X74','6X75','6X76','6X77','6X78','6X79','6X80','6X81','6X82','6X83','6X84','6X85','6X86','6X87','6X88','6X89','6X90','6X91','6X92','6X93','6X94','6X95','6X96','6X97','6X98','6X99',
        '7X00','7X01','7X02','7X03','7X04','7X05','7X06','7X07','7X08','7X09','7X10','7X11','7X12','7X13','7X14','7X15','7X16','7X17','7X18','7X19','7X20','7X21','7X22','7X23','7X24','7X25','7X26','7X27','7X28','7X29','7X30','7X31','7X32','7X33','7X34','7X35','7X36','7X37','7X38','7X39','7X40','7X41','7X42','7X43','7X44','7X45','7X46','7X47','7X48','7X49','7X50','7X51','7X52','7X53','7X54','7X55','7X56','7X57','7X58','7X59','7X60','7X61','7X62','7X63','7X64','7X65','7X66','7X67','7X68','7X69','7X70','7X71','7X72','7X73','7X74','7X75','7X76','7X77','7X78','7X79','7X80','7X81','7X82','7X83','7X84','7X85','7X86','7X87','7X88','7X89','7X90','7X91','7X92','7X93','7X94','7X95','7X96','7X97','7X98','7X99',
        '8X00','8X01','8X02','8X03','8X04','8X05','8X06','8X07','8X08','8X09','8X10','8X11','8X12','8X13','8X14','8X15','8X16','8X17','8X18','8X19','8X20','8X21','8X22','8X23','8X24','8X25','8X26','8X27','8X28','8X29','8X30','8X31','8X32','8X33','8X34','8X35','8X36','8X37','8X38','8X39','8X40','8X41','8X42','8X43','8X44','8X45','8X46','8X47','8X48','8X49','8X50','8X51','8X52','8X53','8X54','8X55','8X56','8X57','8X58','8X59','8X60','8X61','8X62','8X63','8X64','8X65','8X66','8X67','8X68','8X69','8X70','8X71','8X72','8X73','8X74','8X75','8X76','8X77','8X78','8X79','8X80','8X81','8X82','8X83','8X84','8X85','8X86','8X87','8X88','8X89','8X90','8X91','8X92','8X93','8X94','8X95','8X96','8X97','8X98','8X99',
        '9X00','9X01','9X02','9X03','9X04','9X05','9X06','9X07','9X08','9X09','9X10','9X11','9X12','9X13','9X14','9X15','9X16','9X17','9X18','9X19','9X20','9X21','9X22','9X23','9X24','9X25','9X26','9X27','9X28','9X29','9X30','9X31','9X32','9X33','9X34','9X35','9X36','9X37','9X38','9X39','9X40','9X41','9X42','9X43','9X44','9X45','9X46','9X47','9X48','9X49','9X50','9X51','9X52','9X53','9X54','9X55','9X56','9X57','9X58','9X59','9X60','9X61','9X62','9X63','9X64','9X65','9X66','9X67','9X68','9X69','9X70','9X71','9X72','9X73','9X74','9X75','9X76','9X77','9X78','9X79','9X80','9X81','9X82','9X83','9X84','9X85','9X86','9X87','9X88','9X89','9X90','9X91','9X92','9X93','9X94','9X95','9X96','9X97','9X98','9X99',
        'X000','X001','X002','X003','X004','X005','X006','X007','X008','X009','X010','X011','X012','X013','X014','X015','X016','X017','X018','X019','X020','X021','X022','X023','X024','X025','X026','X027','X028','X029','X030','X031','X032','X033','X034','X035','X036','X037','X038','X039','X040','X041','X042','X043','X044','X045','X046','X047','X048','X049','X050','X051','X052','X053','X054','X055','X056','X057','X058','X059','X060','X061','X062','X063','X064','X065','X066','X067','X068','X069','X070','X071','X072','X073','X074','X075','X076','X077','X078','X079','X080','X081','X082','X083','X084','X085','X086','X087','X088','X089','X090','X091','X092','X093','X094','X095','X096','X097','X098','X099',
        'X100', 'X101', 'X102', 'X103', 'X104', 'X105', 'X106', 'X107', 'X108', 'X109', 'X110', 'X111', 'X112', 'X113', 'X114', 'X115', 'X116', 'X117', 'X118', 'X119', 'X120', 'X121', 'X122', 'X123', 'X124', 'X125', 'X126', 'X127', 'X128', 'X129', 'X130', 'X131', 'X132', 'X133', 'X134', 'X135', 'X136', 'X137', 'X138', 'X139', 'X140', 'X141', 'X142', 'X143', 'X144', 'X145', 'X146', 'X147', 'X148', 'X149', 'X150', 'X151', 'X152', 'X153', 'X154', 'X155', 'X156', 'X157', 'X158', 'X159', 'X160', 'X161', 'X162', 'X163', 'X164', 'X165', 'X166', 'X167', 'X168', 'X169', 'X170', 'X171', 'X172', 'X173', 'X174', 'X175', 'X176', 'X177', 'X178', 'X179', 'X180', 'X181', 'X182', 'X183', 'X184', 'X185', 'X186', 'X187', 'X188', 'X189', 'X190', 'X191', 'X192', 'X193', 'X194', 'X195', 'X196', 'X197', 'X198', 'X199', 
        'X200', 'X201', 'X202', 'X203', 'X204', 'X205', 'X206', 'X207', 'X208', 'X209', 'X210', 'X211', 'X212', 'X213', 'X214', 'X215', 'X216', 'X217', 'X218', 'X219', 'X220', 'X221', 'X222', 'X223', 'X224', 'X225', 'X226', 'X227', 'X228', 'X229', 'X230', 'X231', 'X232', 'X233', 'X234', 'X235', 'X236', 'X237', 'X238', 'X239', 'X240', 'X241', 'X242', 'X243', 'X244', 'X245', 'X246', 'X247', 'X248', 'X249', 'X250', 'X251', 'X252', 'X253', 'X254', 'X255', 'X256', 'X257', 'X258', 'X259', 'X260', 'X261', 'X262', 'X263', 'X264', 'X265', 'X266', 'X267', 'X268', 'X269', 'X270', 'X271', 'X272', 'X273', 'X274', 'X275', 'X276', 'X277', 'X278', 'X279', 'X280', 'X281', 'X282', 'X283', 'X284', 'X285', 'X286', 'X287', 'X288', 'X289', 'X290', 'X291', 'X292', 'X293', 'X294', 'X295', 'X296', 'X297', 'X298', 'X299', 
        'X300', 'X301', 'X302', 'X303', 'X304', 'X305', 'X306', 'X307', 'X308', 'X309', 'X310', 'X311', 'X312', 'X313', 'X314', 'X315', 'X316', 'X317', 'X318', 'X319', 'X320', 'X321', 'X322', 'X323', 'X324', 'X325', 'X326', 'X327', 'X328', 'X329', 'X330', 'X331', 'X332', 'X333', 'X334', 'X335', 'X336', 'X337', 'X338', 'X339', 'X340', 'X341', 'X342', 'X343', 'X344', 'X345', 'X346', 'X347', 'X348', 'X349', 'X350', 'X351', 'X352', 'X353', 'X354', 'X355', 'X356', 'X357', 'X358', 'X359', 'X360', 'X361', 'X362', 'X363', 'X364', 'X365', 'X366', 'X367', 'X368', 'X369', 'X370', 'X371', 'X372', 'X373', 'X374', 'X375', 'X376', 'X377', 'X378', 'X379', 'X380', 'X381', 'X382', 'X383', 'X384', 'X385', 'X386', 'X387', 'X388', 'X389', 'X390', 'X391', 'X392', 'X393', 'X394', 'X395', 'X396', 'X397', 'X398', 'X399', 
        'X400', 'X401', 'X402', 'X403', 'X404', 'X405', 'X406', 'X407', 'X408', 'X409', 'X410', 'X411', 'X412', 'X413', 'X414', 'X415', 'X416', 'X417', 'X418', 'X419', 'X420', 'X421', 'X422', 'X423', 'X424', 'X425', 'X426', 'X427', 'X428', 'X429', 'X430', 'X431', 'X432', 'X433', 'X434', 'X435', 'X436', 'X437', 'X438', 'X439', 'X440', 'X441', 'X442', 'X443', 'X444', 'X445', 'X446', 'X447', 'X448', 'X449', 'X450', 'X451', 'X452', 'X453', 'X454', 'X455', 'X456', 'X457', 'X458', 'X459', 'X460', 'X461', 'X462', 'X463', 'X464', 'X465', 'X466', 'X467', 'X468', 'X469', 'X470', 'X471', 'X472', 'X473', 'X474', 'X475', 'X476', 'X477', 'X478', 'X479', 'X480', 'X481', 'X482', 'X483', 'X484', 'X485', 'X486', 'X487', 'X488', 'X489', 'X490', 'X491', 'X492', 'X493', 'X494', 'X495', 'X496', 'X497', 'X498', 'X499', 
        'X500', 'X501', 'X502', 'X503', 'X504', 'X505', 'X506', 'X507', 'X508', 'X509', 'X510', 'X511', 'X512', 'X513', 'X514', 'X515', 'X516', 'X517', 'X518', 'X519', 'X520', 'X521', 'X522', 'X523', 'X524', 'X525', 'X526', 'X527', 'X528', 'X529', 'X530', 'X531', 'X532', 'X533', 'X534', 'X535', 'X536', 'X537', 'X538', 'X539', 'X540', 'X541', 'X542', 'X543', 'X544', 'X545', 'X546', 'X547', 'X548', 'X549', 'X550', 'X551', 'X552', 'X553', 'X554', 'X555', 'X556', 'X557', 'X558', 'X559', 'X560', 'X561', 'X562', 'X563', 'X564', 'X565', 'X566', 'X567', 'X568', 'X569', 'X570', 'X571', 'X572', 'X573', 'X574', 'X575', 'X576', 'X577', 'X578', 'X579', 'X580', 'X581', 'X582', 'X583', 'X584', 'X585', 'X586', 'X587', 'X588', 'X589', 'X590', 'X591', 'X592', 'X593', 'X594', 'X595', 'X596', 'X597', 'X598', 'X599', 
        'X600', 'X601', 'X602', 'X603', 'X604', 'X605', 'X606', 'X607', 'X608', 'X609', 'X610', 'X611', 'X612', 'X613', 'X614', 'X615', 'X616', 'X617', 'X618', 'X619', 'X620', 'X621', 'X622', 'X623', 'X624', 'X625', 'X626', 'X627', 'X628', 'X629', 'X630', 'X631', 'X632', 'X633', 'X634', 'X635', 'X636', 'X637', 'X638', 'X639', 'X640', 'X641', 'X642', 'X643', 'X644', 'X645', 'X646', 'X647', 'X648', 'X649', 'X650', 'X651', 'X652', 'X653', 'X654', 'X655', 'X656', 'X657', 'X658', 'X659', 'X660', 'X661', 'X662', 'X663', 'X664', 'X665', 'X666', 'X667', 'X668', 'X669', 'X670', 'X671', 'X672', 'X673', 'X674', 'X675', 'X676', 'X677', 'X678', 'X679', 'X680', 'X681', 'X682', 'X683', 'X684', 'X685', 'X686', 'X687', 'X688', 'X689', 'X690', 'X691', 'X692', 'X693', 'X694', 'X695', 'X696', 'X697', 'X698', 'X699', 
        'X700', 'X701', 'X702', 'X703', 'X704', 'X705', 'X706', 'X707', 'X708', 'X709', 'X710', 'X711', 'X712', 'X713', 'X714', 'X715', 'X716', 'X717', 'X718', 'X719', 'X720', 'X721', 'X722', 'X723', 'X724', 'X725', 'X726', 'X727', 'X728', 'X729', 'X730', 'X731', 'X732', 'X733', 'X734', 'X735', 'X736', 'X737', 'X738', 'X739', 'X740', 'X741', 'X742', 'X743', 'X744', 'X745', 'X746', 'X747', 'X748', 'X749', 'X750', 'X751', 'X752', 'X753', 'X754', 'X755', 'X756', 'X757', 'X758', 'X759', 'X760', 'X761', 'X762', 'X763', 'X764', 'X765', 'X766', 'X767', 'X768', 'X769', 'X770', 'X771', 'X772', 'X773', 'X774', 'X775', 'X776', 'X777', 'X778', 'X779', 'X780', 'X781', 'X782', 'X783', 'X784', 'X785', 'X786', 'X787', 'X788', 'X789', 'X790', 'X791', 'X792', 'X793', 'X794', 'X795', 'X796', 'X797', 'X798', 'X799', 
        'X800', 'X801', 'X802', 'X803', 'X804', 'X805', 'X806', 'X807', 'X808', 'X809', 'X810', 'X811', 'X812', 'X813', 'X814', 'X815', 'X816', 'X817', 'X818', 'X819', 'X820', 'X821', 'X822', 'X823', 'X824', 'X825', 'X826', 'X827', 'X828', 'X829', 'X830', 'X831', 'X832', 'X833', 'X834', 'X835', 'X836', 'X837', 'X838', 'X839', 'X840', 'X841', 'X842', 'X843', 'X844', 'X845', 'X846', 'X847', 'X848', 'X849', 'X850', 'X851', 'X852', 'X853', 'X854', 'X855', 'X856', 'X857', 'X858', 'X859', 'X860', 'X861', 'X862', 'X863', 'X864', 'X865', 'X866', 'X867', 'X868', 'X869', 'X870', 'X871', 'X872', 'X873', 'X874', 'X875', 'X876', 'X877', 'X878', 'X879', 'X880', 'X881', 'X882', 'X883', 'X884', 'X885', 'X886', 'X887', 'X888', 'X889', 'X890', 'X891', 'X892', 'X893', 'X894', 'X895', 'X896', 'X897', 'X898', 'X899', 
        'X900', 'X901', 'X902', 'X903', 'X904', 'X905', 'X906', 'X907', 'X908', 'X909', 'X910', 'X911', 'X912', 'X913', 'X914', 'X915', 'X916', 'X917', 'X918', 'X919', 'X920', 'X921', 'X922', 'X923', 'X924', 'X925', 'X926', 'X927', 'X928', 'X929', 'X930', 'X931', 'X932', 'X933', 'X934', 'X935', 'X936', 'X937', 'X938', 'X939', 'X940', 'X941', 'X942', 'X943', 'X944', 'X945', 'X946', 'X947', 'X948', 'X949', 'X950', 'X951', 'X952', 'X953', 'X954', 'X955', 'X956', 'X957', 'X958', 'X959', 'X960', 'X961', 'X962', 'X963', 'X964', 'X965', 'X966', 'X967', 'X968', 'X969', 'X970', 'X971', 'X972', 'X973', 'X974', 'X975', 'X976', 'X977', 'X978', 'X979', 'X980', 'X981', 'X982', 'X983', 'X984', 'X985', 'X986', 'X987', 'X988', 'X989', 'X990', 'X991', 'X992', 'X993', 'X994', 'X995', 'X996', 'X997', 'X998', 'X999'
    ],
    twofixed:[
        'XX00','XX01','XX02','XX03','XX04','XX05','XX06','XX07','XX08','XX09',
        'XX10','XX11','XX12','XX13','XX14','XX15','XX16','XX17','XX18','XX19',
        'XX20','XX21','XX22','XX23','XX24','XX25','XX26','XX27','XX28','XX29',
        'XX30','XX31','XX32','XX33','XX34','XX35','XX36','XX37','XX38','XX39',
        'XX40','XX41','XX42','XX43','XX44','XX45','XX46','XX47','XX48','XX49',
        'XX50','XX51','XX52','XX53','XX54','XX55','XX56','XX57','XX58','XX59',
        'XX60','XX61','XX62','XX63','XX64','XX65','XX66','XX67','XX68','XX69',
        'XX70','XX71','XX72','XX73','XX74','XX75','XX76','XX77','XX78','XX79',
        'XX80','XX81','XX82','XX83','XX84','XX85','XX86','XX87','XX88','XX89',
        'XX90','XX91','XX92','XX93','XX94','XX95','XX96','XX97','XX98','XX99',
        'X0X0','X0X1','X0X2','X0X3','X0X4','X0X5','X0X6','X0X7','X0X8','X0X9',
        'X1X0','X1X1','X1X2','X1X3','X1X4','X1X5','X1X6','X1X7','X1X8','X1X9',
        'X2X0','X2X1','X2X2','X2X3','X2X4','X2X5','X2X6','X2X7','X2X8','X2X9',
        'X3X0','X3X1','X3X2','X3X3','X3X4','X3X5','X3X6','X3X7','X3X8','X3X9',
        'X4X0','X4X1','X4X2','X4X3','X4X4','X4X5','X4X6','X4X7','X4X8','X4X9',
        'X5X0','X5X1','X5X2','X5X3','X5X4','X5X5','X5X6','X5X7','X5X8','X5X9',
        'X6X0','X6X1','X6X2','X6X3','X6X4','X6X5','X6X6','X6X7','X6X8','X6X9',
        'X7X0','X7X1','X7X2','X7X3','X7X4','X7X5','X7X6','X7X7','X7X8','X7X9',
        'X8X0','X8X1','X8X2','X8X3','X8X4','X8X5','X8X6','X8X7','X8X8','X8X9',
        'X9X0','X9X1','X9X2','X9X3','X9X4','X9X5','X9X6','X9X7','X9X8','X9X9',
        'X00X','X01X','X02X','X03X','X04X','X05X','X06X','X07X','X08X','X09X',
        'X10X','X11X','X12X','X13X','X14X','X15X','X16X','X17X','X18X','X19X',
        'X20X','X21X','X22X','X23X','X24X','X25X','X26X','X27X','X28X','X29X',
        'X30X','X31X','X32X','X33X','X34X','X35X','X36X','X37X','X38X','X39X',
        'X40X','X41X','X42X','X43X','X44X','X45X','X46X','X47X','X48X','X49X',
        'X50X','X51X','X52X','X53X','X54X','X55X','X56X','X57X','X58X','X59X',
        'X60X','X61X','X62X','X63X','X64X','X65X','X66X','X67X','X68X','X69X',
        'X70X','X71X','X72X','X73X','X74X','X75X','X76X','X77X','X78X','X79X',
        'X80X','X81X','X82X','X83X','X84X','X85X','X86X','X87X','X88X','X89X',
        'X90X','X91X','X92X','X93X','X94X','X95X','X96X','X97X','X98X','X99X',
        '0XX0','0XX1','0XX2','0XX3','0XX4','0XX5','0XX6','0XX7','0XX8','0XX9',
        '1XX0','1XX1','1XX2','1XX3','1XX4','1XX5','1XX6','1XX7','1XX8','1XX9',
        '2XX0','2XX1','2XX2','2XX3','2XX4','2XX5','2XX6','2XX7','2XX8','2XX9',
        '3XX0','3XX1','3XX2','3XX3','3XX4','3XX5','3XX6','3XX7','3XX8','3XX9',
        '4XX0','4XX1','4XX2','4XX3','4XX4','4XX5','4XX6','4XX7','4XX8','4XX9',
        '5XX0','5XX1','5XX2','5XX3','5XX4','5XX5','5XX6','5XX7','5XX8','5XX9',
        '6XX0','6XX1','6XX2','6XX3','6XX4','6XX5','6XX6','6XX7','6XX8','6XX9',
        '7XX0','7XX1','7XX2','7XX3','7XX4','7XX5','7XX6','7XX7','7XX8','7XX9',
        '8XX0','8XX1','8XX2','8XX3','8XX4','8XX5','8XX6','8XX7','8XX8','8XX9',
        '9XX0','9XX1','9XX2','9XX3','9XX4','9XX5','9XX6','9XX7','9XX8','9XX9',
        '0X0X','0X1X','0X2X','0X3X','0X4X','0X5X','0X6X','0X7X','0X8X','0X9X',
        '1X0X','1X1X','1X2X','1X3X','1X4X','1X5X','1X6X','1X7X','1X8X','1X9X',
        '2X0X','2X1X','2X2X','2X3X','2X4X','2X5X','2X6X','2X7X','2X8X','2X9X',
        '3X0X','3X1X','3X2X','3X3X','3X4X','3X5X','3X6X','3X7X','3X8X','3X9X',
        '4X0X','4X1X','4X2X','4X3X','4X4X','4X5X','4X6X','4X7X','4X8X','4X9X',
        '5X0X','5X1X','5X2X','5X3X','5X4X','5X5X','5X6X','5X7X','5X8X','5X9X',
        '6X0X','6X1X','6X2X','6X3X','6X4X','6X5X','6X6X','6X7X','6X8X','6X9X',
        '7X0X','7X1X','7X2X','7X3X','7X4X','7X5X','7X6X','7X7X','7X8X','7X9X',
        '8X0X','8X1X','8X2X','8X3X','8X4X','8X5X','8X6X','8X7X','8X8X','8X9X',
        '9X0X','9X1X','9X2X','9X3X','9X4X','9X5X','9X6X','9X7X','9X8X','9X9X',
        '00XX','01XX','02XX','03XX','04XX','05XX','06XX','07XX','08XX','09XX',
        '10XX','11XX','12XX','13XX','14XX','15XX','16XX','17XX','18XX','19XX',
        '20XX','21XX','22XX','23XX','24XX','25XX','26XX','27XX','28XX','29XX',
        '30XX','31XX','32XX','33XX','34XX','35XX','36XX','37XX','38XX','39XX',
        '40XX','41XX','42XX','43XX','44XX','45XX','46XX','47XX','48XX','49XX',
        '50XX','51XX','52XX','53XX','54XX','55XX','56XX','57XX','58XX','59XX',
        '60XX','61XX','62XX','63XX','64XX','65XX','66XX','67XX','68XX','69XX',
        '70XX','71XX','72XX','73XX','74XX','75XX','76XX','77XX','78XX','79XX',
        '80XX','81XX','82XX','83XX','84XX','85XX','86XX','87XX','88XX','89XX',
        '90XX','91XX','92XX','93XX','94XX','95XX','96XX','97XX','98XX','99XX'
    ],
    fourshow:[
        '0000','0001','0002','0003','0004','0005','0006','0007','0008','0009','0011','0012','0013','0014','0015','0016','0017','0018','0019','0022','0023','0024','0025','0026','0027','0028','0029','0033','0034','0035','0036','0037','0038','0039','0044','0045','0046','0047','0048','0049','0055','0056','0057','0058','0059','0066','0067','0068','0069','0077','0078','0079','0088','0089','0099','0111','0112','0113','0114','0115','0116','0117','0118','0119','0122','0123','0124','0125','0126','0127','0128','0129','0133','0134','0135','0136','0137','0138','0139','0144','0145','0146','0147','0148','0149','0155','0156','0157','0158','0159','0166','0167','0168','0169','0177','0178','0179','0188','0189','0199','0222','0223','0224','0225','0226','0227','0228','0229','0233','0234','0235','0236','0237','0238','0239','0244','0245','0246','0247','0248','0249','0255','0256','0257','0258','0259','0266','0267','0268','0269','0277','0278','0279','0288','0289','0299','0333','0334','0335','0336','0337','0338','0339','0344','0345','0346','0347','0348','0349','0355','0356','0357','0358','0359','0366','0367','0368','0369','0377','0378','0379','0388','0389','0399','0444','0445','0446','0447','0448','0449','0455','0456','0457','0458','0459','0466','0467','0468','0469','0477','0478','0479','0488','0489','0499','0555','0556','0557','0558','0559','0566','0567','0568','0569','0577','0578','0579','0588','0589','0599','0666','0667','0668','0669','0677','0678','0679','0688','0689','0699','0777','0778','0779','0788','0789','0799','0888','0889','0899','0999',
        '1111','1112','1113','1114','1115','1116','1117','1118','1119','1122','1123','1124','1125','1126','1127','1128','1129','1133','1134','1135','1136','1137','1138','1139','1144','1145','1146','1147','1148','1149','1155','1156','1157','1158','1159','1166','1167','1168','1169','1177','1178','1179','1188','1189','1199','1222','1223','1224','1225','1226','1227','1228','1229','1233','1234','1235','1236','1237','1238','1239','1244','1245','1246','1247','1248','1249','1255','1256','1257','1258','1259','1266','1267','1268','1269','1277','1278','1279','1288','1289','1299','1333','1334','1335','1336','1337','1338','1339','1344','1345','1346','1347','1348','1349','1355','1356','1357','1358','1359','1366','1367','1368','1369','1377','1378','1379','1388','1389','1399','1444','1445','1446','1447','1448','1449','1455','1456','1457','1458','1459','1466','1467','1468','1469','1477','1478','1479','1488','1489','1499','1555','1556','1557','1558','1559','1566','1567','1568','1569','1577','1578','1579','1588','1589','1599','1666','1667','1668','1669','1677','1678','1679','1688','1689','1699','1777','1778','1779','1788','1789','1799','1888','1889','1899','1999',
        '2222','2223','2224','2225','2226','2227','2228','2229','2233','2234','2235','2236','2237','2238','2239','2244','2245','2246','2247','2248','2249','2255','2256','2257','2258','2259','2266','2267','2268','2269','2277','2278','2279','2288','2289','2299','2333','2334','2335','2336','2337','2338','2339','2344','2345','2346','2347','2348','2349','2355','2356','2357','2358','2359','2366','2367','2368','2369','2377','2378','2379','2388','2389','2399','2444','2445','2446','2447','2448','2449','2455','2456','2457','2458','2459','2466','2467','2468','2469','2477','2478','2479','2488','2489','2499','2555','2556','2557','2558','2559','2566','2567','2568','2569','2577','2578','2579','2588','2589','2599','2666','2667','2668','2669','2677','2678','2679','2688','2689','2699','2777','2778','2779','2788','2789','2799','2888','2889','2899','2999',
        '3333','3334','3335','3336','3337','3338','3339','3344','3345','3346','3347','3348','3349','3355','3356','3357','3358','3359','3366','3367','3368','3369','3377','3378','3379','3388','3389','3399','3444','3445','3446','3447','3448','3449','3455','3456','3457','3458','3459','3466','3467','3468','3469','3477','3478','3479','3488','3489','3499','3555','3556','3557','3558','3559','3566','3567','3568','3569','3577','3578','3579','3588','3589','3599','3666','3667','3668','3669','3677','3678','3679','3688','3689','3699','3777','3778','3779','3788','3789','3799','3888','3889','3899','3999',
        '4444','4445','4446','4447','4448','4449','4455','4456','4457','4458','4459','4466','4467','4468','4469','4477','4478','4479','4488','4489','4499','4555','4556','4557','4558','4559','4566','4567','4568','4569','4577','4578','4579','4588','4589','4599','4666','4667','4668','4669','4677','4678','4679','4688','4689','4699','4777','4778','4779','4788','4789','4799','4888','4889','4899','4999',
        '5555','5556','5557','5558','5559','5566','5567','5568','5569','5577','5578','5579','5588','5589','5599','5666','5667','5668','5669','5677','5678','5679','5688','5689','5699','5777','5778','5779','5788','5789','5799','5888','5889','5899','5999',
        '6666','6667','6668','6669','6677','6678','6679','6688','6689','6699','6777','6778','6779','6788','6789','6799','6888','6889','6899','6999',
        '7777','7778','7779','7788','7789','7799','7888','7889','7899','7999',
        '8888','8889','8899','8999',
        '9999'
    ],
    threeshow:[
        '000','001','002','003','004','005','006','007','008','009','011','012','013','014','015','016','017','018','019','022','023','024','025','026','027','028','029','033','034','035','036','037','038','039','044','045','046','047','048','049','055','056','057','058','059','066','067','068','069','077','078','079','088','089','099',
        '111','112','113','114','115','116','117','118','119','122','123','124','125','126','127','128','129','133','134','135','136','137','138','139','144','145','146','147','148','149','155','156','157','158','159','166','167','168','169','177','178','179','188','189','199',
        '222','223','224','225','226','227','228','229','233','234','235','236','237','238','239','244','245','246','247','248','249','255','256','257','258','259','266','267','268','269','277','278','279','288','289','299',
        '333','334','335','336','337','338','339','344','345','346','347','348','349','355','356','357','358','359','366','367','368','369','377','378','379','388','389','399',
        '444','445','446','447','448','449','455','456','457','458','459','466','467','468','469','477','478','479','488','489','499',
        '555','556','557','558','559','566','567','568','569','577','578','579','588','589','599',
        '666','667','668','669','677','678','679','688','689','699',
        '777','778','779','788','789','799',
        '888','889','899',
        '999'
    ],
    twoshow:[
        '00','01','02','03','04','05','06','07','08','09',
        '11','12','13','14','15','16','17','18','19',
        '22','23','24','25','26','27','28','29',
        '33','34','35','36','37','38','39',
        '44','45','46','47','48','49',
        '55','56','57','58','59',
        '66','67','68','69',
        '77','78','79',
        '88','89',
        '99'
    ],
    //保存上次过滤大底的结果
    Cfourfixed:[],
    Cfourshow:[],
    Cthreefixed:[],
    Cthreeshow:[],
    Ctwofixed:[],
    Ctwoshow:[],


    //玩法类型，定义在state中避免筛选页面刷新后无参数不显示问题 1.四定 2.四现 3.三定 4.三现 5.二定 6.二现
    payType: 0,
    //菜单信息
    //四定
    menuFourDec:[
        {
            name: '热门',
            children:[
                {
                    name: '定位置',
                    mes: ['各个位置分别输入号码，然后生成可能的组合'],
                    btn:[
                        {
                            name: '批量选码'
                        },
                        {
                            name: '自由选码'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号，特别约定0与9相邻'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '减式',
                    mes: ['自定义减法等式。例如A+B-C=2'],
                    btn:[
                        {
                            name: '合值'
                        }
                    ]
                },
            ]
        },
        {
            name: '按位置选码',
            children: [
                {
                    name: '定位置',
                    mes: ['各个位置分别输入号码，然后生成可能的组合'],
                    btn: [
                        {
                            name: '批量选码'
                        },
                        {
                            name: '自由选码'
                        }
                    ]
                },
                {
                    name: '头尾有数',
                    mes: ['头尾两个位置中，至少有一个位置出现所选号码'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '中肚有数',
                    mes: ['百十两个位置中，至少有一个位置包含所选号码'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '有数',
                    mes: ['所选定的位置中，其中一个位置出现所选定的号码'],
                    btn: [
                        {
                            name: '2个位置'
                        },
                        {
                            name: '3个位置'
                        },
                        {
                            name: '4个位置'
                        }
                    ]
                },
                {
                    name: '凹凸升降',
                    mes: ['凹下型：中间2个数字均小于头尾','凸起型：中间2个数字均大于头尾','上升型：从左到右依次变大','下降型：从左到右依次变小','平行型：四个数字完全相等'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '合值筛选',
            children: [
                {
                    name: '两数合',
                    mes: ['两个数字相加所得之和的个位称为两数合'],
                    btn: [
                        {
                            name: '定位置'
                        },
                        {
                            name: '不定位置'
                        }
                    ]
                },
                {
                    name: '三数合',
                    mes: ['三个数字相加所得之和的个位称为三数合'],
                    btn: [
                        {
                            name: '定位置'
                        },
                        {
                            name: '不定位置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置',
                        }
                    ]
                }
            ]
        },
        {
            name: '号码分类',
            children: [
                {
                    name: '单双（奇偶）',
                    mes: ['将0-9分为两类：单 双','单：13579，双：02468'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大小',
                    mes: ['将0-9分为两类：大 小','大：56789，小：01234'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '质合',
                    mes: ['将0-9分为两类：质 合','质：12357，合：04689'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大中小',
                    mes: ['将0-9分为三类：大 中 小','大：789，中：3456，小：012'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '012路',
                    mes: ['将0-9分别除以3，根据余数的值，划分为三类：0路，1路，2路','0路：0369，1路：147，2路：258'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '对数',
                    mes: ['将0-9共10个数字围成环状，按照相对位置分为五类，0对5，1对6，2对7，3对8，4对9'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '不定位置选号',
            children: [
                {
                    name: '配号码',
                    mes: ['两组号码相互搭配，配出所有可能的组合'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '必出码（铁率）',
                    mes: ['所选的号码中，至少出现1个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数字多次出现，称为重数'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号。特别约定0与9相邻'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '入数个数',
                    mes: ['不相同数字数量之和。例如1258入数4个，0103入数3个，0006入数2个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '极值',
                    mes: ['最大或者最小的一个数称为极值'],
                    btn: [
                        {
                            name: '最大值'
                        },
                        {
                            name: '最小值'
                        }
                    ]
                },
                {
                    name: '跨度',
                    mes: ['最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '加减乘除运算',
            children: [
                {
                    name: '和值',
                    mes: ['所有数字相加所得之和'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之和的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值+跨度',
                    mes: ['合值与跨度之和','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '减式',
                    mes: ['自定义减法等式。例如A+B-C=2'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '合值-跨度',
                    mes: ['合值与跨度之差','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '两数乘积',
                    mes: ['所选两个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '三数乘积',
                    mes: ['所选三个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '四数乘积',
                    mes: ['所选四个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '位积',
                    mes: ['位置号码与对应位置序号的乘积之和','A*1 + B*2 + C*3 + D*4'],
                    btn: [
                        {
                            name: '和值'
                        },
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '反位积',
                    mes: ['位置号码与对应位置序号的乘积之和','A*4 + B*3 + C*2 + D*1'],
                    btn: [
                        {
                            name: '和值'
                        },
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '余数和',
                    mes: ['ABCD分别除以一个数，取余数之和'],
                    btn: [
                        {
                            name: '除以3'
                        }
                    ]
                }
            ]
        },
        {
            name: '其他',
            children: [
                {
                    name: '开奖历史',
                    mes: ['允许根据开奖历史来筛选号码'],
                    btn: [
                        {
                            name: '七星彩'
                        },
                        {
                            name: '排列五'
                        }
                    ]
                },
                {
                    name: '全转（全倒）',
                    mes: ['输入一串数字，将其转化为字定','可以将字现转化为字定'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: 'AC值',
                    mes: ['即号码复杂度','号码两两相减（大减小），去掉重复的差值，','最后剩余差值得个数，就是AC值'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        }
    ],
    //四现
    menuFourShow:[
        {
            name: '热门',
            children:[
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号，特别约定0与9相邻'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数多次出现，称为重数'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
            ]
        },
        {
            name: '合值筛选',
            children: [
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置',
                        }
                    ]
                }
            ]
        },
        {
            name: '号码分类',
            children: [
                {
                    name: '单双（奇偶）',
                    mes: ['将0-9分为两类：单 双','单：13579，双：02468'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大小',
                    mes: ['将0-9分为两类：大 小','大：56789，小：01234'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '质合',
                    mes: ['将0-9分为两类：质 合','质：12357，合：04689'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大中小',
                    mes: ['将0-9分为三类：大 中 小','大：789，中：3456，小：012'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '012路',
                    mes: ['将0-9分别除以3，根据余数的值，划分为三类：0路，1路，2路','0路：0369，1路：147，2路：258'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '对数',
                    mes: ['将0-9共10个数字围成环状，按照相对位置分为五类，0对5，1对6，2对7，3对8，4对9'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '不定位置选号',
            children: [
                {
                    name: '配号码',
                    mes: ['两组号码相互搭配，配出所有可能的组合'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '必出码（铁率）',
                    mes: ['所选的号码中，至少出现1个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数字多次出现，称为重数'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号。特别约定0与9相邻'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '入数个数',
                    mes: ['不相同数字数量之和。例如1258入数4个，0103入数3个，0006入数2个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '极值',
                    mes: ['最大或者最小的一个数称为极值'],
                    btn: [
                        {
                            name: '最大值'
                        },
                        {
                            name: '最小值'
                        }
                    ]
                },
                {
                    name: '跨度',
                    mes: ['最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '加减乘除运算',
            children: [
                {
                    name: '和值',
                    mes: ['所有数字相加所得之和'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之和的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值+跨度',
                    mes: ['合值与跨度之和','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值-跨度',
                    mes: ['合值与跨度之差','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '四数乘积',
                    mes: ['所选四个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '余数和',
                    mes: ['ABCD分别除以一个数，取余数之和'],
                    btn: [
                        {
                            name: '除以3'
                        }
                    ]
                }
            ]
        },
        {
            name: '其他',
            children: [
                {
                    name: 'AC值',
                    mes: ['即号码复杂度','号码两两相减（大减小），去掉重复的差值，','最后剩余差值得个数，就是AC值'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        }
    ],
    //三定
    menuThreeDec:[
        {
            name: '热门',
            children:[
                {
                    name: '定位置',
                    mes: ['各个位置分别输入号码，然后生成可能的组合'],
                    btn:[
                        {
                            name: '乘号位置'
                        },
                        {
                            name: '批量选码'
                        },
                        {
                            name: '自由选码'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号，特别约定0与9相邻'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
            ]
        },
        {
            name: '按位置选码',
            children: [
                {
                    name: '定位置',
                    mes: ['各个位置分别输入号码，然后生成可能的组合'],
                    btn: [
                        {
                            name: '乘号位置'
                        },
                        {
                            name: '批量选码'
                        },
                        {
                            name: '自由选码'
                        }
                    ]
                },
                {
                    name: '头尾有数',
                    mes: ['头尾两个位置中，至少有一个位置出现所选号码'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '中肚有数',
                    mes: ['百十两个位置中，至少有一个位置包含所选号码'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '有数',
                    mes: ['所选定的位置中，其中一个位置出现所选定的号码'],
                    btn: [
                        {
                            name: '2个位置'
                        },
                        {
                            name: '3个位置'
                        }
                    ]
                },
                {
                    name: '凹凸升降',
                    mes: ['凹下型：中间1个数字均小于头尾','凸起型：中间1个数字均大于头尾','上升型：从左到右依次变大','下降型：从左到右依次变小','平行型：三个数字完全相等'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '合值筛选',
            children: [
                {
                    name: '两数合',
                    mes: ['两个数字相加所得之和的个位称为两数合'],
                    btn: [
                        {
                            name: '定位置'
                        },
                        {
                            name: '不定位置'
                        }
                    ]
                },
                {
                    name: '三数合',
                    mes: ['三个数字相加所得之和的个位称为三数合'],
                    btn: [
                        {
                            name: '定位置'
                        },
                        {
                            name: '不定位置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置',
                        }
                    ]
                }
            ]
        },
        {
            name: '号码分类',
            children: [
                {
                    name: '单双（奇偶）',
                    mes: ['将0-9分为两类：单 双','单：13579，双：02468'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大小',
                    mes: ['将0-9分为两类：大 小','大：56789，小：01234'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '质合',
                    mes: ['将0-9分为两类：质 合','质：12357，合：04689'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大中小',
                    mes: ['将0-9分为三类：大 中 小','大：789，中：3456，小：012'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '012路',
                    mes: ['将0-9分别除以3，根据余数的值，划分为三类：0路，1路，2路','0路：0369，1路：147，2路：258'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '对数',
                    mes: ['将0-9共10个数字围成环状，按照相对位置分为五类，0对5，1对6，2对7，3对8，4对9'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '不定位置选号',
            children: [
                {
                    name: '配号码',
                    mes: ['三组号码相互搭配，配出所有可能的组合'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '必出码（铁率）',
                    mes: ['所选的号码中，至少出现1个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数字多次出现，称为重数'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号。特别约定0与9相邻'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '入数个数',
                    mes: ['不相同数字数量之和。例如1258入数4个，0103入数3个，0006入数2个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '极值',
                    mes: ['最大或者最小的一个数称为极值'],
                    btn: [
                        {
                            name: '最大值'
                        },
                        {
                            name: '最小值'
                        }
                    ]
                },
                {
                    name: '跨度',
                    mes: ['最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '加减乘除运算',
            children: [
                {
                    name: '和值',
                    mes: ['所有数字相加所得之和'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之和的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值+跨度',
                    mes: ['合值与跨度之和','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值-跨度',
                    mes: ['合值与跨度之差','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '两数乘积',
                    mes: ['所选两个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '三数乘积',
                    mes: ['所选三个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '位积',
                    mes: ['位置号码与对应位置序号的乘积之和','A*1 + B*2 + C*3 + D*4'],
                    btn: [
                        {
                            name: '和值'
                        },
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '反位积',
                    mes: ['位置号码与对应位置序号的乘积之和','A*4 + B*3 + C*2 + D*1'],
                    btn: [
                        {
                            name: '和值'
                        },
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '余数和',
                    mes: ['ABCD分别除以一个数，取余数之和'],
                    btn: [
                        {
                            name: '除以3'
                        }
                    ]
                }
            ]
        },
        {
            name: '其他',
            children: [
                {
                    name: '开奖历史',
                    mes: ['允许根据开奖历史来筛选号码'],
                    btn: [
                        {
                            name: '七星彩'
                        },
                        {
                            name: '排列五'
                        }
                    ]
                },
                {
                    name: '全转（全倒）',
                    mes: ['输入一串数字，将其转化为字定','可以将字现转化为字定'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: 'AC值',
                    mes: ['即号码复杂度','号码两两相减（大减小），去掉重复的差值，','最后剩余差值得个数，就是AC值'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        }
    ],
    //三现
    menuThreeShow:[
        {
            name: '热门',
            children:[
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号，特别约定0与9相邻'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数多次出现，称为重数'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
            ]
        },
        {
            name: '合值筛选',
            children: [
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置',
                        }
                    ]
                }
            ]
        },
        {
            name: '号码分类',
            children: [
                {
                    name: '单双（奇偶）',
                    mes: ['将0-9分为两类：单 双','单：13579，双：02468'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大小',
                    mes: ['将0-9分为两类：大 小','大：56789，小：01234'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '质合',
                    mes: ['将0-9分为两类：质 合','质：12357，合：04689'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大中小',
                    mes: ['将0-9分为三类：大 中 小','大：789，中：3456，小：012'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '012路',
                    mes: ['将0-9分别除以3，根据余数的值，划分为三类：0路，1路，2路','0路：0369，1路：147，2路：258'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '对数',
                    mes: ['将0-9共10个数字围成环状，按照相对位置分为五类，0对5，1对6，2对7，3对8，4对9'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '不定位置选号',
            children: [
                {
                    name: '配号码',
                    mes: ['两组号码相互搭配，配出所有可能的组合'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '必出码（铁率）',
                    mes: ['所选的号码中，至少出现1个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数字多次出现，称为重数'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号。特别约定0与9相邻'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '入数个数',
                    mes: ['不相同数字数量之和。例如1258入数4个，0103入数3个，0006入数2个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '极值',
                    mes: ['最大或者最小的一个数称为极值'],
                    btn: [
                        {
                            name: '最大值'
                        },
                        {
                            name: '最小值'
                        }
                    ]
                },
                {
                    name: '跨度',
                    mes: ['最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '加减乘除运算',
            children: [
                {
                    name: '和值',
                    mes: ['所有数字相加所得之和'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之和的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值+跨度',
                    mes: ['合值与跨度之和','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值-跨度',
                    mes: ['合值与跨度之差','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '三数乘积',
                    mes: ['所选三个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '余数和',
                    mes: ['ABCD分别除以一个数，取余数之和'],
                    btn: [
                        {
                            name: '除以3'
                        }
                    ]
                }
            ]
        },
        {
            name: '其他',
            children: [
                {
                    name: 'AC值',
                    mes: ['即号码复杂度','号码两两相减（大减小），去掉重复的差值，','最后剩余差值得个数，就是AC值'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        }
    ],
    //二定
    menuTwoDec:[
        {
            name: '热门',
            children:[
                {
                    name: '定位置',
                    mes: ['各个位置分别输入号码，然后生成可能的组合'],
                    btn:[
                        {
                            name: '乘号位置'
                        },
                        {
                            name: '批量选码'
                        },
                        {
                            name: '自由选码'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号，特别约定0与9相邻'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
            ]
        },
        {
            name: '按位置选码',
            children: [
                {
                    name: '定位置',
                    mes: ['各个位置分别输入号码，然后生成可能的组合'],
                    btn: [
                        {
                            name: '乘号位置'
                        },
                        {
                            name: '批量选码'
                        },
                        {
                            name: '自由选码'
                        }
                    ]
                },
                {
                    name: '头尾有数',
                    mes: ['头尾两个位置中，至少有一个位置出现所选号码'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '中肚有数',
                    mes: ['百十两个位置中，至少有一个位置包含所选号码'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '有数',
                    mes: ['所选定的位置中，其中一个位置出现所选定的号码'],
                    btn: [
                        {
                            name: '2个位置'
                        }
                    ]
                },
            ]
        },
        {
            name: '合值筛选',
            children: [
                {
                    name: '两数合',
                    mes: ['两个数字相加所得之和的个位称为两数合'],
                    btn: [
                        {
                            name: '定位置'
                        },
                        {
                            name: '不定位置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置',
                        }
                    ]
                }
            ]
        },
        {
            name: '号码分类',
            children: [
                {
                    name: '单双（奇偶）',
                    mes: ['将0-9分为两类：单 双','单：13579，双：02468'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大小',
                    mes: ['将0-9分为两类：大 小','大：56789，小：01234'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '质合',
                    mes: ['将0-9分为两类：质 合','质：12357，合：04689'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大中小',
                    mes: ['将0-9分为三类：大 中 小','大：789，中：3456，小：012'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                    ]
                },
                {
                    name: '012路',
                    mes: ['将0-9分别除以3，根据余数的值，划分为三类：0路，1路，2路','0路：0369，1路：147，2路：258'],
                    btn: [
                        {
                            name: '按位快选',
                        },
                        {
                            name: '按位任选'
                        },
                        {
                            name: '出现比例'
                        },
                    ]
                },
                {
                    name: '对数',
                    mes: ['将0-9共10个数字围成环状，按照相对位置分为五类，0对5，1对6，2对7，3对8，4对9'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '不定位置选号',
            children: [
                {
                    name: '配号码',
                    mes: ['两组号码相互搭配，配出所有可能的组合'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '必出码（铁率）',
                    mes: ['所选的号码中，至少出现1个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数字多次出现，称为重数'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号。特别约定0与9相邻'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '入数个数',
                    mes: ['不相同数字数量之和。例如1258入数4个，0103入数3个，0006入数2个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '极值',
                    mes: ['最大或者最小的一个数称为极值'],
                    btn: [
                        {
                            name: '最大值'
                        },
                        {
                            name: '最小值'
                        }
                    ]
                },
                {
                    name: '跨度',
                    mes: ['最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '加减乘除运算',
            children: [
                {
                    name: '和值',
                    mes: ['所有数字相加所得之和'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之和的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值+跨度',
                    mes: ['合值与跨度之和','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值-跨度',
                    mes: ['合值与跨度之差','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '两数乘积',
                    mes: ['所选两个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '位积',
                    mes: ['位置号码与对应位置序号的乘积之和','A*1 + B*2 + C*3 + D*4'],
                    btn: [
                        {
                            name: '和值'
                        },
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '反位积',
                    mes: ['位置号码与对应位置序号的乘积之和','A*4 + B*3 + C*2 + D*1'],
                    btn: [
                        {
                            name: '和值'
                        },
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '余数和',
                    mes: ['ABCD分别除以一个数，取余数之和'],
                    btn: [
                        {
                            name: '除以3'
                        }
                    ]
                }
            ]
        },
        {
            name: '其他',
            children: [
                {
                    name: '开奖历史',
                    mes: ['允许根据开奖历史来筛选号码'],
                    btn: [
                        {
                            name: '七星彩'
                        },
                        {
                            name: '排列五'
                        }
                    ]
                },
                {
                    name: '全转（全倒）',
                    mes: ['输入一串数字，将其转化为字定','可以将字现转化为字定'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        }
    ],
    //二现
    menuTwoShow:[
        {
            name: '热门',
            children:[
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号，特别约定0与9相邻'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数多次出现，称为重数'],
                    btn:[
                        {
                            name: '设置'
                        }
                    ]
                },
            ]
        },
        {
            name: '合值筛选',
            children: [
                {
                    name: '合值（合分）',
                    mes: ['所有数字之合的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置',
                        }
                    ]
                }
            ]
        },
        {
            name: '号码分类',
            children: [
                {
                    name: '单双（奇偶）',
                    mes: ['将0-9分为两类：单 双','单：13579，双：02468'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大小',
                    mes: ['将0-9分为两类：大 小','大：56789，小：01234'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '质合',
                    mes: ['将0-9分为两类：质 合','质：12357，合：04689'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                        {
                            name: '断组'
                        }
                    ]
                },
                {
                    name: '大中小',
                    mes: ['将0-9分为三类：大 中 小','大：789，中：3456，小：012'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                    ]
                },
                {
                    name: '012路',
                    mes: ['将0-9分别除以3，根据余数的值，划分为三类：0路，1路，2路','0路：0369，1路：147，2路：258'],
                    btn: [
                        {
                            name: '出现比例'
                        },
                    ]
                },
                {
                    name: '对数',
                    mes: ['将0-9共10个数字围成环状，按照相对位置分为五类，0对5，1对6，2对7，3对8，4对9'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '不定位置选号',
            children: [
                {
                    name: '配号码',
                    mes: ['两组号码相互搭配，配出所有可能的组合'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '排除码（死数）',
                    mes: ['所选号码必定不出现'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '必出码（铁率）',
                    mes: ['所选的号码中，至少出现1个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '重数',
                    mes: ['同一个数字多次出现，称为重数'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '连号（兄弟号）',
                    mes: ['多个相邻的号码称为连号，也称为兄弟号。特别约定0与9相邻'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '入数个数',
                    mes: ['不相同数字数量之和。例如1258入数4个，0103入数3个，0006入数2个'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '极值',
                    mes: ['最大或者最小的一个数称为极值'],
                    btn: [
                        {
                            name: '最大值'
                        },
                        {
                            name: '最小值'
                        }
                    ]
                },
                {
                    name: '跨度',
                    mes: ['最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                }
            ]
        },
        {
            name: '加减乘除运算',
            children: [
                {
                    name: '和值',
                    mes: ['所有数字相加所得之和'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值（合分）',
                    mes: ['所有数字之和的个位称为合值，也称合分'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值+跨度',
                    mes: ['合值与跨度之和','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '合值-跨度',
                    mes: ['合值与跨度之差','合值：所有数字相加所得之和的个位（和尾）','跨度：最大值与最小值之差称为跨度'],
                    btn: [
                        {
                            name: '设置'
                        }
                    ]
                },
                {
                    name: '两数乘积',
                    mes: ['所选两个位置号码的乘积'],
                    btn: [
                        {
                            name: '合值'
                        }
                    ]
                },
                {
                    name: '余数和',
                    mes: ['ABCD分别除以一个数，取余数之和'],
                    btn: [
                        {
                            name: '除以3'
                        }
                    ]
                }
            ]
        },
    ],
     //条件选择集合
    // 字段含义
    // isOverlap判断交并集 1.交集 2.并集
    // isTrue是否选中 1.选中 0.没选 2.待选
    // isSave是否保留 1.保留 0.排除
    // val 所选条件数据


    //四定
    condFourDec:[
        {
            isOverlap:1
        },
        {
            name: '定位置',
            isTrue: 0,
            btn:[
                {
                    name: '批量选码',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                       
                    ]
                },
                {
                    name: '自由选码',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                    group3: [false,false,false,false,false,false,false,false,false,false],
                    group4: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '头尾有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '中肚有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '有数',
            isTrue: 0,
            btn: [
                {
                    name: '4个位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
                {
                    name: '3个位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
                {
                    name: '2个位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                }
            ]
        },
        {
            name: '凹凸升降',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '两数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '三数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '单双（奇偶）',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                    group3: [false,false,false,false,false,false,false,false,false,false],
                    group4: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,  
            btn: [
                {
                    name: '设置',
                    isSave: 0, 
                    isTrue: 0,
                    msg:'',  
                    val: [false,false,false,false,false,false,false,false,false,false]

                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[],
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val37: [false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false]
                },
            ]
        },
        
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    positiveval19: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false]
                },
            ]
        },
        {
            name: '减式',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    positionfront:[],
                    positionafter:[],
                    val: [false,false,false,false,false,false,false,false,false,false]

                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    nagetiveval18: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false]
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                }
            ]
        },
        {
            name: '三数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
            ]
        },
        {
            name: '四数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
            ]
        },
        {
            name: '位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val91: [
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false
                    
                    ],
                }
            ]
        },
        {
            name: '反位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val91: [
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false
                    
                    ],
                }
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val9: [false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '开奖历史',
            isTrue: 0,
            btn: [
                {
                    name: '七星彩',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    buttonval:0
                    
                },
                {
                    name: '排列五',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    buttonval2:0
                },
            ]
        },
        {
            name: '全转（全倒）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val6: [false,false,false,false,false,false]
                },
            ]
        },
    ],
       //四现
    condFourShow:[
        {
            isOverlap:1
        },
        {
            name:'合值（合分）',
            isTrue: 0,
            btn:[
                {
                    name:'设置',
                    isTrue:0,
                    isSave: 1,
                    msg:'',
                    val:[false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name:'单双（奇偶）',
            isTrue: 0,
            btn:[
                
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn:[
                
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn:[
                
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn:[
                
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn:[
                
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                    group3: [false,false,false,false,false,false,false,false,false,false],
                    group4: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 0,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[],
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val37: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false]
                },
            ]
        },
       
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    positiveval19: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false]
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    nagetiveval18: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false]
                },
            ]
        },
        {
            name: '四数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val9: [false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val6: [false,false,false,false,false,false]
                },
            ]
        }
    ],
        //三定
    condThreeDec:[
        {
            isOverlap:1
        },
        {
            name: '定位置',
            isTrue: 0,
            btn:[
                {
                    name: '乘号位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    position:[]
                },
                {
                    name: '批量选码',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                        
                       
                    ]
                },
                {
                    name: '自由选码',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                    group3: [false,false,false,false,false,false,false,false,false,false],
                    group4: [false,false,false,false,false,false,false,false,false,false],
                }
            ],
           
        },
        {
            name: '头尾有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '中肚有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '有数',
            isTrue: 0,
            btn: [
                {
                    name: '3个位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
                {
                    name: '2个位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                }
            ]
        },
        {
            name: '凹凸升降',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '两数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '三数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '单双（奇偶）',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                    group3: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 0,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[],
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val28: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false]
                },
            ]
        },
        
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    positiveval19: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false]
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    nagetiveval19: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false]
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                }
            ]
        },
        {
            name: '三数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    abc: false,
                    checkboxval:[]
                },
            ]
        },
        {
            name: '位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val82: [
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false
                    ],
                }
            ]
        },
        {
            name: '反位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val82: [
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false
                    ],
                }
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val7: [false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '开奖历史',
            isTrue: 0,
            btn: [
                {
                    name: '七星彩',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    buttonval:0
                },
                {
                    name: '排列五',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    buttonval2:0
                },
            ]
        },
        {
            name: '全转（全倒）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val3: [false,false,false]
                },
            ]
        },
    ],
    //三现
    condThreeShow:[
        {
            isOverlap:1
        },
        {
            name:'合值（合分）',
            isTrue: 0,
            btn:[
                {
                    name:'设置',
                    isTrue:0,
                    isSave: 1,
                    msg:'',
                    val:[false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name:'单双（奇偶）',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                    group3: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 0,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[],
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val28: [false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false]
                },
            ]
        },
        
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    positiveval19: [false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false]
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    nagetiveval19: [false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false]
                },
            ]
        },
        {
            name: '三数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val7: [false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val3: [false,false,false]
                },
            ]
        }
    ],
    //二定
    condTwoDec:[
        {
            isOverlap:1
        },
        {
            name: '定位置',
            isTrue: 0,
            btn:[
                {
                    name: '乘号位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    position:[]
                },
                {
                    name: '批量选码',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                       
                    ]
                },
                {
                    name: '自由选码',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                    group3: [false,false,false,false,false,false,false,false,false,false],
                    group4: [false,false,false,false,false,false,false,false,false,false],
                    
                }
            ],
        },
        {
            name: '头尾有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '中肚有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '有数',
            isTrue: 0,
            btn: [
                {
                    name: '2个位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                }
            ]
        },
        {
            name: '两数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '单双（奇偶）',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 0,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[],
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val19: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    positiveval10: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    nagetiveval9: [false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                }
            ]
        },
        {
            name: '位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val64: [
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false
                    ],
                }
            ]
        },
        {
            name: '反位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val64: [
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false
                    ],
                }
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val5: [false,false,false,false,false]
                },
            ]
        },
        {
            name: '开奖历史',
            isTrue: 0,
            btn: [
                {
                    name: '七星彩',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    buttonval:0
                },
                {
                    name: '排列五',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    buttonval2:0
                },
            ]
        },
        {
            name: '全转（全倒）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
            ]
        },
    ],
    //二现
    condTwoShow:[
        {
            isOverlap:1
        },
        {
            name:'合值（合分）',
            isTrue: 0,
            btn:[
                {
                    name:'设置',
                    isTrue:0,
                    isSave: 1,
                    msg:'',
                    val:[false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name:'单双（奇偶）',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 0,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[],
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val19: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    positiveval10: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    nagetiveval9: [false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]

                },
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val5: [false,false,false,false,false]
                },
            ]
        },
    ],
    //初始化，不改变这里
    //四定
    initcondFourDec:[
        {
            isOverlap:1
        },
        {
            name: '定位置',
            isTrue: 0,
            btn:[
                {
                    name: '批量选码',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                       
                    ]
                },
                {
                    name: '自由选码',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                    group3: [false,false,false,false,false,false,false,false,false,false],
                    group4: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '头尾有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '中肚有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '有数',
            isTrue: 0,
            btn: [
                {
                    name: '4个位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
                {
                    name: '3个位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
                {
                    name: '2个位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                }
            ]
        },
        {
            name: '凹凸升降',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '两数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '三数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '单双（奇偶）',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                    group3: [false,false,false,false,false,false,false,false,false,false],
                    group4: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,  
            btn: [
                {
                    name: '设置',
                    isSave: 0, 
                    isTrue: 0,
                    msg:'',  
                    val: [false,false,false,false,false,false,false,false,false,false]

                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[],
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val37: [false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false]
                },
            ]
        },
        
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    positiveval19: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false]
                },
            ]
        },
        {
            name: '减式',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    positionfront:[],
                    positionafter:[],
                    val: [false,false,false,false,false,false,false,false,false,false]

                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    nagetiveval18: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false]
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                }
            ]
        },
        {
            name: '三数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
            ]
        },
        {
            name: '四数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
            ]
        },
        {
            name: '位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val91: [
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false
                    
                    ],
                }
            ]
        },
        {
            name: '反位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val91: [
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false
                    
                    ],
                }
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val9: [false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '开奖历史',
            isTrue: 0,
            btn: [
                {
                    name: '七星彩',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    buttonval:0
                },
                {
                    name: '排列五',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    buttonval2:0
                },
            ]
        },
        {
            name: '全转（全倒）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val6: [false,false,false,false,false,false]
                },
            ]
        },
    ],
       //四现
    initcondFourShow:[
        {
            isOverlap:1
        },
        {
            name:'合值（合分）',
            isTrue: 0,
            btn:[
                {
                    name:'设置',
                    isTrue:0,
                    isSave: 1,
                    msg:'',
                    val:[false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name:'单双（奇偶）',
            isTrue: 0,
            btn:[
                
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn:[
                
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn:[
                
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn:[
                
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn:[
                
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                    group3: [false,false,false,false,false,false,false,false,false,false],
                    group4: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 0,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[],
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val37: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false]
                },
            ]
        },
       
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    positiveval19: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false]
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    nagetiveval18: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false]
                },
            ]
        },
        {
            name: '四数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val9: [false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val6: [false,false,false,false,false,false]
                },
            ]
        }
    ],
        //三定
    initcondThreeDec:[
        {
            isOverlap:1
        },
        {
            name: '定位置',
            isTrue: 0,
            btn:[
                {
                    name: '乘号位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    position:[]
                },
                {
                    name: '批量选码',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                        
                       
                    ]
                },
                {
                    name: '自由选码',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                    group3: [false,false,false,false,false,false,false,false,false,false],
                    group4: [false,false,false,false,false,false,false,false,false,false],
                }
            ],
           
        },
        {
            name: '头尾有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '中肚有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '有数',
            isTrue: 0,
            btn: [
                {
                    name: '3个位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
                {
                    name: '2个位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                }
            ]
        },
        {
            name: '凹凸升降',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '两数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '三数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '单双（奇偶）',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                    group3: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 0,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[],
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val28: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false]
                },
            ]
        },
        
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    positiveval19: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false]
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    nagetiveval19: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false]
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                }
            ]
        },
        {
            name: '三数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    abc: false,
                    checkboxval:[]
                },
            ]
        },
        {
            name: '位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val82: [
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false
                    ],
                }
            ]
        },
        {
            name: '反位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val82: [
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false
                    ],
                }
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val7: [false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '开奖历史',
            isTrue: 0,
            btn: [
                {
                    name: '七星彩',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    buttonval:0
                },
                {
                    name: '排列五',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    buttonval2:0
                },
            ]
        },
        {
            name: '全转（全倒）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val3: [false,false,false]
                },
            ]
        },
    ],
    //三现
    initcondThreeShow:[
        {
            isOverlap:1
        },
        {
            name:'合值（合分）',
            isTrue: 0,
            btn:[
                {
                    name:'设置',
                    isTrue:0,
                    isSave: 1,
                    msg:'',
                    val:[false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name:'单双（奇偶）',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                    group3: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 0,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[],
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val28: [false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false]
                },
            ]
        },
        
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    positiveval19: [false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false]
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    nagetiveval19: [false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false]
                },
            ]
        },
        {
            name: '三数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val7: [false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: 'AC值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val3: [false,false,false]
                },
            ]
        }
    ],
    //二定
    initcondTwoDec:[
        {
            isOverlap:1
        },
        {
            name: '定位置',
            isTrue: 0,
            btn:[
                {
                    name: '乘号位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    position:[]
                },
                {
                    name: '批量选码',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                        {
                            name:'',
                            dan:'', //单或双 1为单 0为双
                            da:'', //大或小 1为大 0为小
                            lu:'' //012路
                        },
                       
                    ]
                },
                {
                    name: '自由选码',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                    group3: [false,false,false,false,false,false,false,false,false,false],
                    group4: [false,false,false,false,false,false,false,false,false,false],
                    
                }
            ],
        },
        {
            name: '头尾有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '中肚有数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '有数',
            isTrue: 0,
            btn: [
                {
                    name: '2个位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                }
            ]
        },
        {
            name: '两数合',
            isTrue: 0,
            btn: [
                {
                    name: '定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                },
                {
                    name: '不定位置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '合值（合分）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '单双（奇偶）',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn: [
                {
                    name: '按位快选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    batch:[
                        {
                            name:'',
                            code:''
                        },
                        {
                            name:'',
                            code:''
                        },
                        
                    ]
                },
                {
                    name: '按位任选',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 0,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[],
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val19: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    positiveval10: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    nagetiveval9: [false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]
                }
            ]
        },
        {
            name: '位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val64: [
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false
                    ],
                }
            ]
        },
        {
            name: '反位积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                },
                {
                    name: '和值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val64: [
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false
                    ],
                }
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val5: [false,false,false,false,false]
                },
            ]
        },
        {
            name: '开奖历史',
            isTrue: 0,
            btn: [
                {
                    name: '七星彩',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    buttonval:0
                },
                {
                    name: '排列五',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    buttonval2:0
                },
            ]
        },
        {
            name: '全转（全倒）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
            ]
        },
    ],
    //二现
    initcondTwoShow:[
        {
            isOverlap:1
        },
        {
            name:'合值（合分）',
            isTrue: 0,
            btn:[
                {
                    name:'设置',
                    isTrue:0,
                    isSave: 1,
                    msg:'',
                    val:[false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name:'单双（奇偶）',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大小',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '质合',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                {
                    name: '断组',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    flag:false
                }
            ]
        },
        {
            name: '大中小',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                
            ]
        },
        {
            name: '012路',
            isTrue: 0,
            btn:[
                {
                    name: '出现比例',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                },
                
            ]
        },
        {
            name: '对数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '配号码',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    group1: [false,false,false,false,false,false,false,false,false,false],
                    group2: [false,false,false,false,false,false,false,false,false,false],
                }
            ]
        },
        {
            name: '排除码（死数）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 0,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '必出码（铁率）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '重数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '连号（兄弟号）',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[],
                }
            ]
        },
        {
            name: '入数个数',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    checkboxval:[]
                }
            ]
        },
        {
            name: '极值',
            isTrue: 0,
            btn: [
                {
                    name: '最大值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
                {
                    name: '最小值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                }
            ]
        },
        {
            name: '跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '和值',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val19: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        
        {
            name: '合值+跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    positiveval10: [false,false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '合值-跨度',
            isTrue: 0,
            btn: [
                {
                    name: '设置',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    nagetiveval9: [false,false,false,false,false,false,false,false,false]
                },
            ]
        },
        {
            name: '两数乘积',
            isTrue: 0,
            btn: [
                {
                    name: '合值',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val: [false,false,false,false,false,false,false,false,false,false],
                    checkboxval:[]

                },
            ]
        },
        {
            name: '余数和',
            isTrue: 0,
            btn: [
                {
                    name: '除以3',
                    isSave: 1,
                    isTrue: 0,
                    msg:'',
                    val5: [false,false,false,false,false]
                },
            ]
        },
    ],

}
export default state