export default {
  get: (ins, url, param, data, loading) => {
    if (loading) {
      ins.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true });
    }
    return new Promise((resolve) => {
      ins.$axios({
        url: url,
        method: 'get',
        params: param,
        ...data
      }).then(res => {
        ins.$toast.clear()
        // console.log("get请求返回的信息",res)
        if (res.status === 200 && (res.data.statusCode === 200 || res.data.statusCode === 309)) {
          // console.log('请求访问成功')
          resolve(res.data)
        } else {
          // console.log('请求访问失败')
          ins.$toast(res.data.responseDescription || '请求失败！');
        }
      }).catch(function (error) {
        ins.$toast.clear()
        console.log(error);
      });
    })
  },
  post: (ins, url, param, data, loading) => {
    if (loading) {
      ins.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true });
    }
    return new Promise((resolve) => {
      ins.$axios({
        url: ins.$API[url],
        method: 'post',
        data: param,
        ...data,
      }).then(res => {
        // console.log("post请求返回的信息",res)
        ins.$toast.clear()
        if (res.status === 200 && res.data.statusCode === 200) {
          resolve(res.data)
        } else {
          ins.$toast(res.data.responseDescription || '请求失败！');
        }
      }).catch(function (error) {
        ins.$toast.clear()
        console.log(error);
      });
    })
  },
  put: (ins, url, param, data, loading) => {
    if (loading) {
      ins.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true });
    }
    return new Promise((resolve) => {
      ins.$axios({
        url: ins.$API[url],
        method: 'put',
        data: param,
        ...data,
      }).then(res => {
        // console.log("put请求返回的信息",res)
        ins.$toast.clear()
        if (res.status === 200 && res.data.statusCode === 200) {
          resolve(res.data)
        } else {
          ins.$toast(res.data.responseDescription || '请求失败！');
        }
      }).catch(function (error) {
        ins.$toast.clear()
        console.log(error);
      });
    })
  },

  postLogin: (ins, url, param, data, loading) => {
    if (loading) {
      ins.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true });
    }
    return new Promise((resolve) => {
      ins.$axios({
        url: ins.$API[url],
        method: 'post',
        data: param,
        ...data,
      }).then(res => {
        ins.$toast.clear()
        if (res.status === 200 && res.data.statusCode === 200) {
          resolve(res.data)
        } else {
          resolve(res.data)
          ins.$toast(res.data.responseDescription || '请求失败！');
        }
      }).catch(function (error) {
        ins.$toast.clear()
        console.log(error);
      });
    })
  }
}