const state = () => ({
  footerActive: 0,
  lotteryKind: '5', // 5:排列5 7:七彩星
  loginInfo: {
    access_token: '',
    refresh_token: '',
    userId: ''
  },
  userInfo: {},
  goodInfo:{
    artId:'',
    date:'',
    price:'',
    title:'',
    type:''
  },
  forecast_active:0,
  rangIndex_active:2,
  rangPeriod:'',
  // fileSource:'QINIU'
})

export default state
